import React, {useState, useEffect} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText,
    TableContainer,
    TableCell,
    Table,
    TableRow,
    TableHead,
    TableBody
} from '@material-ui/core';

const DISPLAY_OPTIONS = ["HIDE", "SHOW"];
const ARISING_OUT_OF = ["ARISING_OUT_OF","IN", "WITH"]; 

const ReferenceCase = ({classes, formData, handleInputChange, searchResponse, setFormData}) => {
    const [errors, setErrors] = useState({
        petitionerAdvocate: '',
        respondentAdvocate: '',
    });
    const [localReferenceCases, setLocalReferenceCases] = useState([]);

    useEffect(() => {
        if (searchResponse?.reference_cases) {
            // Map searchResponse's reference cases to the local state, setting default display_in_causelist and arisingOutOf values
            const initializedReferenceCases = searchResponse.reference_cases.map(refCase => ({
                reference_case: refCase.id, 
                arisingOutOf: "IN", // default value or you can map it from refCase if available
                display_in_causelist: true // default value or map it from refCase if available
            }));
            setLocalReferenceCases(initializedReferenceCases);
        }
    }, [searchResponse]);

    // Function to handle change in reference case data
      const handleReferenceCaseChange = (index, field, value) => {
        const updatedReferenceCases = [...localReferenceCases];
        // Handle the "display_in_causelist" logic
        if (field === "display_in_causelist") {
            updatedReferenceCases[index][field] = value === "SHOW"; // true if SHOW, false if HIDE
        } else {
            updatedReferenceCases[index][field] = value;
        }

        setLocalReferenceCases(updatedReferenceCases);

        // Update formData with the modified reference cases
        setFormData({
            ...formData,
            reference_cases: updatedReferenceCases
        });
    };

    console.log(localReferenceCases,'reference')
    
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.noBordersTitle}>Display in CauseList</TableCell>
                                    <TableCell className={classes.noBordersTitle}>Reference Cases</TableCell>
                                    <TableCell className={classes.noBordersTitle}>Display</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {localReferenceCases?.length > 0 ? (
                                    localReferenceCases.map((row, index) => (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell className={classes.noBorders}>
                                            <FormControl fullWidth margin="none" variant="outlined">
                                                <Typography className={classes.label}>Arising out of</Typography>
                                                <Select
                                                    name="arisingOutOf"
                                                    value={row.arisingOutOf}
                                                    onChange={(e) =>
                                                        handleReferenceCaseChange(index, "arisingOutOf", e.target.value)
                                                    }
                                                >
                                                    {ARISING_OUT_OF.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className={classes.noBorders}>
                                            {searchResponse?.reference_cases?.[index]?.name} - {searchResponse?.reference_cases?.[index]?.diary_number}
                                        </TableCell>
                                        <TableCell className={classes.noBorders}>
                                            <FormControl fullWidth margin="none" variant="outlined">
                                                <Typography className={classes.label}>Display</Typography>
                                                <Select
                                                    name="display"
                                                    value={row.display_in_causelist ? "SHOW" : "HIDE"}
                                                    onChange={(e) =>
                                                        handleReferenceCaseChange(index, "display_in_causelist", e.target.value === "SHOW")
                                                    }
                                                >
                                                    {DISPLAY_OPTIONS.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                    <TableCell colSpan={4} className={classes.noBorders} align="center">
                                        No Reference Case
                                    </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ReferenceCase;