import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { CLAIMANT, STATES } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import { introFormContext } from "features/intro/IntroContainer";
import { useFormik } from "formik";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import debounce from "lodash/debounce";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  isCDR,
  isDev,
  isDigipub,
  isHotStar,
  isNbf,
  isNSE,
  isViacom,
  isVikalp,
  isWebnyay,
  isStar,
  isMuthoot,
} from "Routes/Routes";
import { DisputeSchema, HotstarDisputeSchema } from "schemas/DisputeSchema";
import { createCase } from "slices/casesSlice";
import { getCompanyProducts } from "slices/companySlice";
import { createDispute } from "slices/disputeSlice";


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    padding: "2rem",
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

const IntroForm = ({ profile, user, companies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);

  const { isVendor, setFormStep, setClaimantFormValues } = useContext(introFormContext);
  const [phoneError, setPhoneError] = useState();
  const [companyProducts, setCompanyProducts] = useState([]);

  const grievanceTypes = [
    {
      id: 'grievance',
      value: 'Grievance',
    },
    {
      id: 'suggestion',
      value: 'Suggestion',
    },
  ]

  useEffect(() => {
    isVendor &&
      getCompanyProducts(profile.company).then((response) => {
        setCompanyProducts(response);
      });
    validatePhone(profile.phone_number);
  }, []);

  const onPhoneChange = (e) => {
    setPhoneError();
    validatePhone(e.target.value);
    formik.setFieldValue("phone", e.target.value);
  };

  const validatePhone = (v) => {
    let ph = v;
    if (ph != null && ph.trim() != "" && ph.length != 10) {
      setPhoneError("Invalid phone number");
    }
  };

  // debounced submit handler for general user as claimant
  const debouncedOnSubmit = debounce((values) => {
    let company = companies.find((element) => element.id === values.company);
    let name = "";
    if (isHotStar || isStar || isMuthoot)
      name = user.first_name + " " + user.last_name + " & " + company.name;
    else name = user.first_name + " " + user.last_name + " vs " + company.name;
    let description = name;
    let disputeBody;
    if (isNbf) {
      disputeBody = {
        name: name,
        company_product: values.company_product,
        description: description,
        level_of_dispute: "one",
        claimant_status: "new",
        platform_type:
          isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub
            ? "grievance"
            : isCDR || isVikalp
            ? "cdr"
            : isNbf
            ? "nbf"
            : "rv",
      };
    } else {
      disputeBody = {
        name: name,
        company_product: values.company_product,
        description: description,
        grievance_type: values.type,
        platform_type:
          isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub || isNSE
            ? "grievance"
            : isCDR || isVikalp
            ? "cdr"
            : isNbf
            ? "nbf"
            : "rv",
      };
    }

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      type: CLAIMANT,
    };

    dispatch(createDispute(disputeBody))
      .then((response) => {
        createCaseBody.dispute = response;
        return dispatch(createCase(createCaseBody));
      })
      .then((response) => {
        let respondentCaseBody = {
          summary: createCaseBody.summary,
          description: createCaseBody.description,
          type: "respondent",
          dispute: createCaseBody.dispute,
          region: values.region,
        };

        return dispatch(createCase(respondentCaseBody));
      })
      .then((response) => {
        history.push(`/disputes/${response.dispute}`);
      })

      .catch((err) => {
        console.log(err);
      });
  }, 1000);

  // handler for vendor as claimant
  const onProceed = (values) => {
    setClaimantFormValues({ ...values });
    setFormStep(2);
  };

  const formik = useFormik({
    initialValues: {
      email: user.email,
      phone: profile.phone_number,
      birth_date: new Date(profile.birth_date),
      company: isVendor ? profile.company : null,
      company_product_type: null,
      company_product: null,
      region: null,
      type: null,
    },
    onSubmit: isVendor ? onProceed : debouncedOnSubmit,
    validationSchema: (isHotStar || isStar || isMuthoot) ? HotstarDisputeSchema : DisputeSchema,
  });

  const location = useLocation();
  const [showGuide, setShowGuide] = useState(
    location.pathname.split("/")[1] == "intro" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const steps = [
    {
      element: ".introstep1",
      intro: "Select the company you wish to file a grievance against.",
    },
    // {
    //   element: ".introstep2",
    //   intro: "Fill some answers.",
    // },
    // {
    //   element: ".introstep3",
    //   intro: "Upload any necessary documents.",
    // },
    // {
    //   element: ".introstep4",
    //   intro: "Review your grievance details",
    // },
    // {
    //   element: ".introstep5",
    //   intro: "Download your automatically generated grievance here!",
    // },
  ];

  return (
    // <Box p="2rem 0" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",border:"1px solid"}}>
    <Grid
      container
      style={{ padding: "2rem 0", display: "flex", justifyContent: "center" }}
    >
      <Grid item xs={10} sm={10}>
        <Steps
          enabled={showGuide}
          steps={steps}
          initialStep={0}
          onExit={exit}
          options={{
            hideNext: false,
            hidePrev: true,
            keyboardNavigation: true,
            exitOnEsc: true,
            exitOnOverlayClick: false,
            showBullets: false,
            showStepNumbers: false,
            disableInteraction: true,
            doneLabel: "Select here",
          }}
        />
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper className={classes.formContainer}>
              {" "}
              <Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  // onClick={testFunc}
                >
                  <FormattedMessage id="pages.intro.introText" />
                </Typography>
                <Box height="1rem"></Box>
                <Box p="0rem 2rem">
                  <form onSubmit={formik.handleSubmit} data-testid="intro-form">
                    <TextField
                      required
                      id="email"
                      name="email"
                      label={<FormattedMessage id="user.email" />}
                      variant="outlined"
                      fullWidth={true}
                      className={classes.item}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <Alert severity="error">{formik.errors.email}</Alert>
                    ) : null}
                    <Box height="1rem"></Box>
                    {(isHotStar || isStar || isMuthoot) && (
                      <TextField
                        required={isMuthoot}
                        name="phone"
                        id="phone"
                        label={<FormattedMessage id="user.phoneNumber" />}
                        variant="outlined"
                        type="phone"
                        fullWidth={true}
                        className={classes.item}
                        onChange={onPhoneChange}
                        value={formik.values.phone}
                      />
                    )}

                    {/* {formik.errors.phone ? (
                      <Alert severity="error">{phoneError}</Alert>
                    ) : null} */}

                    {phoneError && <Alert severity="error">{phoneError}</Alert>}

                    {!isHotStar && !isStar && !isMuthoot && (
                      <TextField
                        required
                        name="phone"
                        id="phone"
                        label={<FormattedMessage id="user.phoneNumber" />}
                        variant="outlined"
                        type="phone"
                        fullWidth={true}
                        className={classes.item}
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                    )}

                    {formik.errors.phone && formik.touched.phone ? (
                      <Alert severity="error">{formik.errors.phone}</Alert>
                    ) : null}

                    {isMuthoot && (
                      <>
                        <Box height="1rem"></Box>
                        <FormControl
                          fullWidth={true}
                          variant="outlined"
                          data-testid="region-select"
                        >
                          <InputLabel id="against">
                            <FormattedMessage id="user.region" /> 
                          </InputLabel>
                          <Select
                            required
                            id="region"
                            name="region"
                            value={formik.values.region}
                            onChange={formik.handleChange}
                            labelId="against"
                            label="Region"
                          >
                            {STATES.map((state, index) => (
                              <MenuItem key={index+100} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}

                    <Box height="1rem"></Box>

                    {!isVendor && (
                      <Fragment>
                        <FormControl
                          fullWidth={true}
                          variant="outlined"
                          data-testid="respondent-select"
                          className="introstep1"
                        >
                          <InputLabel id="against">
                            {(isHotStar || isStar || isMuthoot) ? "Platform" : "Respondent"}
                          </InputLabel>
                          <Select
                            id="company"
                            name="company"
                            value={formik.values.company}
                            onChange={(e) => {
                              formik.setFieldValue("company_product", null);
                              formik.setFieldValue(
                                "company_product_type",
                                null
                              );
                              formik.handleChange(e);
                              setCompanyProducts([]);
                              getCompanyProducts(e.target.value).then(
                                (response) => {
                                  setCompanyProducts(response);
                                }
                              );
                            }}
                            labelId="against"
                            label="Respondent"
                          >
                            {companies.map((company) => {
                              if (!company.deleted)
                                return (
                                  <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                  </MenuItem>
                                );
                              return null
                            })}
                          </Select>
                        </FormControl>
                        {formik.errors.company && formik.touched.company ? (
                          <Alert severity="error">
                            {formik.errors.company}
                          </Alert>
                        ) : null}

                        <Box height="1rem"></Box>
                      </Fragment>
                    )}

                    {isMuthoot && (
                      <>
                        <FormControl
                          fullWidth={true}
                          variant="outlined"
                          data-testid="type-select"
                        >
                          <InputLabel id="type">
                            Type 
                          </InputLabel>
                          <Select
                            required
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            labelId="type"
                            label="Type"
                          >
                            {grievanceTypes.map((type, index) => (
                              <MenuItem key={index} value={type.id}>
                                {type.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}

                    <Box height="1rem"></Box>

                    <FormControl
                      fullWidth={true}
                      variant="outlined"
                      disabled={companyProducts.length === 0}
                      data-testid="company-product-select"
                    >
                      <InputLabel id="against">
                        {" "}
                        {isMuthoot ? 'Complaint Category' : <FormattedMessage id="dispute.nature" />} 
                      </InputLabel>
                      <Select
                        id="company_product_type"
                        name="company_product_type"
                        value={formik.values.company_product_type}
                        onChange={(e) => {
                          formik.setFieldValue("company_product", null);
                          formik.handleChange(e);
                        }}
                        labelId="company_product_type"
                        label={isMuthoot ? 'Complaint Category' : 'Company Product Type' }
                      >
                        {companyProducts
                          .map((item) => item.type)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                          .map((type, index) => (
                            <MenuItem key={index} value={type}>
                              {type.replaceAll('_',' ')}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.errors.company_product_type &&
                    formik.touched.company_product_type ? (
                      <Alert severity="error">
                        {formik.errors.company_product_type}
                      </Alert>
                    ) : null}

                    <Box height="1rem"></Box>

                    <FormControl
                      fullWidth={true}
                      variant="outlined"
                      disabled={companyProducts.length === 0}
                    >
                      <InputLabel id="against">
                        {(isHotStar || isStar) ? (
                          <FormattedMessage id="dispute.format" />
                        ) : (
                          isMuthoot ? 'Sub-catergory 1' : <FormattedMessage id="dispute.category" />
                        )}
                      </InputLabel>
                      <Select
                        id="company_product"
                        name="company_product"
                        value={formik.values.company_product}
                        onChange={formik.handleChange}
                        labelId="company_product"
                        label={isMuthoot ? 'Sub-catergory 1' : 'Company Product'}
                        data-testid="company-product-type-select"
                      >
                        {companyProducts
                          .filter(
                            (product) =>
                              product.type ===
                              formik.values.company_product_type
                          )
                          .map((product, index) => (
                            <MenuItem
                              key={product.id}
                              value={product.id}
                              data-testid={`company-product-type-item-${index}`}
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {product.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.errors.company_product &&
                    formik.touched.company_product ? (
                      <Alert severity="error">
                        {formik.errors.company_product}
                      </Alert>
                    ) : null}

                    <Box height="1rem"></Box>
                    <Box display="flex" justifyContent="center">
                      {caseLoading || disputeLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          data-testid="submit-intro-claimant"
                          disabled={caseLoading || disputeLoading}
                          className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
                        >
                          {isVendor ? (
                            <FormattedMessage id="buttons.proceed" />
                          ) : (
                            <FormattedMessage id="buttons.submit" />
                          )}
                        </Button>
                      )}
                    </Box>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={2} sm={0} xs={0} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntroForm;
