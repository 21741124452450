import React, {useState} from 'react';
import {
    Button,
    FormControl,
    Typography,
    Grid,
    Paper,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    FormHelperText,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { CloudUpload, Delete, InfoOutlined } from '@material-ui/icons';
import instance from 'api/globalaxios';

export const INFO_UPLOAD = `
  1. Maximum size of the e-file is fixed at 50 MB.
  2. Formatting: Paper size: A-4 (29.7 cm x 21 cm) 
      - Top Margin: 2 cm, Bottom Margin: 2 cm
      - Left Margin: 4 cm, Right Margin: 4 cm
      - Alignment: Justified
      - Font: Times New Roman, Font size: 14
      - Line spacing: 1.5
  3. The document should be converted into Optical Character Recognition (OCR) searchable Portable Document Format (PDF) or PDF/A using any PDF converter or in-built PDF conversion plug-in provided in the software. PDF/A is the preferred format.
  4. Document to be bookmarked at the relevant sections of the file.
`;

const TooltipComponent = () => {
  return (
    <Tooltip
      title={
        <span>
          {INFO_UPLOAD.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      }
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

const UploadFileTdsat = ({classes, formData, handleInputChange, setFormData, applicationResponse, uploadType, errors, isAgreeNeeded = true}) => {
    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleTermCondition = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value === 'I Agree' ? true : false,
        }));        
    }

    const handleTermConditionMainCase = (e) => {
      const { name, value } = e.target;
      setFormData(name, true)
    }

    const uploadDocument = async (fileUploaded, applicationId) => {
        if (!fileUploaded) {
          console.error("No file selected");
          return;
        }
    
        const formData = new FormData();
        formData.append('content_type', uploadType);
        formData.append('file', fileUploaded);
        formData.append('object_id', applicationId);
    
        setIsUploading(true);

        try {
          const response = await instance.post(`/tdsat/files/upload/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log(response,'response')
          setUploadResponse(response.data);
          setFile(fileUploaded);
        } catch (error) {
          console.error("There was an error uploading the file!", error);
        }finally {
          setIsUploading(false);
        }
    };


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadDocument(file, applicationResponse?.id);
    }
  };

  const handleRemoveFile = async () => {
    if (!uploadResponse || !uploadResponse.file.id) {
      console.error("No file to remove");
      return;
    }

    try {
      await instance.delete(`/tdsat/files/delete/${uploadResponse.file.id}/`);
      console.log("File deleted successfully");
      setFile(null);
      setUploadResponse(null);
    } catch (error) {
      console.error("There was an error deleting the file!", error);
    }
  };


    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start',flexDirection: 'column', marginTop: 18 }}>
                    <Typography className={classes.labelTitle}>Upload Complete File:</Typography>
                    <Grid style={{display: 'flex'}}>
                      <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                          id="file-upload"
                          disabled={isUploading || file !== null || !applicationResponse?.id}
                      />
                      <label htmlFor="file-upload">
                          <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className={classes.buttonPay}
                              disabled={isUploading || file !== null || !applicationResponse?.id}
                              startIcon={isUploading ? <CircularProgress size={24} /> : <CloudUpload />}
                              style={{width: '100%', padding:'15px'}}
                          >
                            {isUploading ? 'Uploading...' : 'Upload Complete PDF File'}
                          </Button>
                      </label>
                      <TooltipComponent/>
                    </Grid>
                    <Typography className={classes.labelSubtitle}>Upload Document (Please upload .pdf files only. Max. size allowed 50 MB.)</Typography>
                </Grid>
                {uploadResponse && (
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
                    <Typography variant="body2">File Name: {uploadResponse.file.file_name}</Typography>
                    <Delete style={{cursor: 'pointer', marginLeft: '10px'}} onClick={handleRemoveFile}/>
                  </Grid>
                )}
            </Grid>
            {isAgreeNeeded && 
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" style={{textAlign: 'left'}}>It is certified that all the annexures are true copies of their respective original documents.</FormLabel>
                      <RadioGroup row>
                          {['I Agree'].map((option, index) => (
                          <FormControlLabel key={index} name="agree" value={option} control={<Radio />} label={option} onChange={uploadType === 'MainCase'? handleTermConditionMainCase : handleTermCondition} />
                          ))}
                      </RadioGroup>
                      {errors.agree && <FormHelperText style={{color: 'red'}}>{errors.agree}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>}
      </Paper>
    )
}

export default UploadFileTdsat;