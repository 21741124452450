import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Paper, IconButton, TablePagination,makeStyles, Grid, Typography
} from '@material-ui/core';
import { Print, Search } from '@material-ui/icons';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import { formatDate } from 'utils';
import DateRangePickerTDSAT from '../DateRangePickerTDSAT';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import Swal from 'sweetalert2';
import fileDownload from 'js-file-download';
import { viewScrutinyReport } from '../API/TdsatApi';
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold'
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '30px'
  },
  noBordersName: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    border: 'none',
    fontSize: '15px',
  }
}));

const AdminScrutinyReport = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countPagination, setCountPagination] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false); 
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchScrutinyReportData();
  },[])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchScrutinyReportData(searchTerm, startDate, endDate, newPage + 1); // Update data on page change
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrintReport = async () => {
    if (!startDate && !endDate) {
      Swal.fire({
        title: "Error",
        text: "Please select start date and end date and then Submit.",
        icon: "error"
      });
      return;
    }
    try {
      const response = await instance.get(`tdsat/scrutiny_report/generate?type=main_cases&start_date=${format(startDate, 'dd-MM-yyyy')}&end_date=${format(endDate, 'dd-MM-yyyy')}` ,{responseType: "blob"});
      fileDownload(
        response.data,
        `ScrutinyReport.pdf`
      );
      Swal.fire({
          title: "Print Slip",
          text: "Report Downloaded Successfully",
          icon: "success"
        });
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmit = () => {
    fetchScrutinyReportData(searchTerm, startDate, endDate); // Fetch data on Submit button click
  };

  const fetchScrutinyReportData = async (searchText = '', startDate = null, endDate = null) => {
    setLoading(true); 
    try {
      const params = {
        type: 'main_cases',
        dashboard_type: 'registered_cases',
        search_text: searchText || '',
        start_date: startDate ? format(startDate, 'dd-MM-yyyy') : '',
        end_date: endDate ? format(endDate, 'dd-MM-yyyy') : '',
        page: page > 1 ? page : 1,
      };
      const response = await instance.get('/tdsat/dashboard-search/', { params });
      setFilteredData(response?.data?.results || []);
      setCountPagination(response?.data?.count);
    } catch (error) {
      console.error('There was an error fetching the case data!', error);
    }
    setLoading(false);
  };

  const handleViewScrutinyReport = async (caseName) => {
		try {
			const response = await viewScrutinyReport(caseName);
      console.log(response,'response')
			const link = response.data?.pre_signed_url;
			if (link) {
				const newTab = window.open();
				newTab.location.href = link;
			} else {
        showSnackbar(response?.data?.error, 'error');
				console.error("Link not found in response");
			}
		} catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
    }
			console.error("Failed to fetch the notice link", error);
		}
	};

  return (
    <div className={classes.root}>
        <Grid item>
          <Typography className={classes.titleTop}>CHECK SCRUTINY REPORT (DEFECT FREE)</Typography>
        </Grid>
      <div className={classes.searchContainer}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <TextField
              className={classes.searchField}
              label="Search (by parties name/ diary no./ case no.)"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSubmit}>
                    <Search />
                  </IconButton>
                ),
              }}
            />
        </div>

        {/* Date Range Picker */}
        <div className={classes.datePickers}>
          <DateRangePickerTDSAT
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>

        {/* Submit Button */}
        <Button onClick={handleSubmit} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          Submit
        </Button>

        {/* Print Button */}
        <Button onClick={handlePrintReport} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          <Print /> Print Report
        </Button>
      </div>
      
      {loading ? (
        <div className={classes.loader}>
          <CircularCenteredLoader />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.noBordersTitle}>Sr. No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Diary No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Case No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Party Name</TableCell>
                <TableCell className={classes.noBordersTitle}>Petitioner Advocate</TableCell>
                <TableCell className={classes.noBordersTitle}>Defect</TableCell>
                <TableCell className={classes.noBordersTitle}>Date of Filing</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.noBorders}>{(page) * rowsPerPage +(index + 1)}</TableCell>
                  <TableCell className={classes.noBorders}>{row.diary_number}</TableCell>
                  <TableCell style={{color: '#4a3bff', cursor: 'pointer'}} className={classes.noBorders}  onClick={()=>handleViewScrutinyReport(row?.case_number)}>{row.case_number}</TableCell>
                  <TableCell className={classes.noBordersName}>{row.name}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.petitioner_advocate?.join(' \n') || '-'}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.defects?.length === 0 ? 'No' : 'Yes'}</TableCell>
                  <TableCell className={classes.noBorders}>{formatDate(row.case_creation_date_time)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[]} 
            className={classes.pagination}
          />
        </>
      )}
    </div>
  );
};

export default AdminScrutinyReport;
