import { Box, Button, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import Tabs from "features/Tabs/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import FDR from "./FDR";
import FDRReport from "./FDRReport";
import CaseWeeding from "./CaseWeeding";
import WeedingOutReport from "./WeedingOutReport";
import CaseRevoke from "./CaseRevoke";
import CaseTransfer from "./CaseTransfer";
import DailyCaseProceeding from "./DailyCaseProceeding";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
		padding: '5px 10px',
  },
	tabsContainer: {
		width:'100%',
		flexWrap: 'nowrap',
		[theme.breakpoints.down("sm")]: {
			flexWrap: 'wrap',
		},
	},
	selectedTab: {
		padding: "0.8rem 1.5rem",
		cursor: 'pointer',
		fontSize: '1.2rem',
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		color: theme.palette.primary.main,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
	unselectedTab: {
		fontSize: '1.2rem',
		padding: "0.8rem 1.5rem",
		cursor: "pointer",
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		borderBottom: '2px solid black',
	},

}));

export default function CourtUpdateContainer({handleClickOnNavItem}){
	const classes = useStyles();
  const [activeTab, setActiveTab] = useState('court_update');
	const [subScreen, setSubScreen] = useState(null);

  useEffect(()=>{

  },[])

	const RenderTabs = ({selected}) => {
		switch(selected){
			case 'court_update': 
				return <DailyCaseProceeding/>
			case 'case_transfer':
				return <CaseTransfer handleClickOnNavItem={handleClickOnNavItem}/>
			case 'case_revoke':
				return <CaseRevoke/>
			case 'weeding_out':
				return <CaseWeeding handleClickGenerateReport={handleChangeSubScreen}/>
			case 'FDR':
				return <FDR handleClickFRDModify={handleChangeSubScreen}/>
			case 'fdr_report':
				return <FDRReport handleGoBack={handleTabChange}/>
			case 'weeding_out_report':
				return <WeedingOutReport handleGoBack={handleTabChange}/>
			default:
				return <h1>Invalid Choice</h1>
		}
	};

	const tabs = [
		{
			id: "court_update",
			label: "Court Update",
		},
		{
			id: "case_transfer",
			label: "Case Transfer",
		},
		{
			id: "case_revoke",
			label: "Case Revoke",
		},
		{
			id: "weeding_out",
			label: "Weeding Out",
		},
		{
			id: "FDR",
			label: "FDR",
		},
	];

	const handleTabChange = (newValue) => {
		setSubScreen(null);
		setActiveTab(newValue);
	};

	const handleChangeSubScreen = (newValue) => {
		setSubScreen(newValue);
	}

    return (
			<Box className={classes.root}>
				<Tabs
					tabs={tabs}
					handleClickTab={handleTabChange}
					choice={activeTab}
					tabsClasses={{
						selected: classes.selectedTab,
						unselected: classes.unselectedTab,
					}}
					tabsContainerClass={classes.tabsContainer}
				/>
        <Box style={{height:'1.5rem'}}/>
				<RenderTabs selected={subScreen || activeTab}/>
			</Box>
    )
}
