import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import GeneralSebiActivities from "Admin/Sebi/GeneralSebiActivities";
import instance from "api/globalaxios";
import { format } from "date-fns";
import { saveTempDetailsClaimantLawyer } from "features/TempTiacForm/ClaimantLawyerPage/questions";
import { saveTempDetailsClaimant } from "features/TempTiacForm/ClaimantPage/questions";
import FileImage from "images/file.svg";
import FolderImage from "images/folder.svg";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  isArb,
  isArbitrationConference,
  isDifc,
  isKilumaya,
  isMSME,
  isSebi,
  isTiac,
} from "Routes/Routes";
import SidebarDisputes from "./SidebarDisputes";
import TiacCard from "./TiacCard";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    padding: "2rem",
    borderRight: "1px solid #BDBDBD",
    minHeight: "92vh",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      minHeight: 0,
      borderRight: 0,
      borderBottom: "1px solid #BDBDBD",
    },
  },
  activity: {
    padding: "2rem 4rem 2rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  welcome: {
    fontSize: "28px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  filenew: {
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    textTransform: "none",
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#1F2937",
  },
  activityPaper: {
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    padding: "2rem 2rem 1.3rem 2rem",
  },
  activityUsername: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
  },
  activityheading: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityTime: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityfileFolderName: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    wordBreak: "break-word",
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
}));

export default function DisputesRvTiac({
  profile,
  disputesActiveClaimant,
  disputesResolvedClaimant,
  disputesActiveRespondent,
  disputesResolvedRespondent,
  disputesActiveArbitrator,
  disputesResolvedArbitrator,
  allActivities,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [claimantDisputeType, setClaimantDisputeType] = useState("active");
  const [respondentDisputeType, setRespondentDisputeType] = useState("active");
  const [arbitratorDisputeType, setArbitratorDisputeType] = useState("active");
  const [activities, setActivities] = useState([]);

  async function createDispute() {
    return await instance.post("/tiaccreateprofileafterlogin/");
  }

  const onClick = (e) => {
    // for file a new case
    if (profile.profile_type == "general_rv") {
      createDispute()
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: profile.user.email,
            full_name: profile.user.first_name + " " + profile.user.last_name,
            nationality: profile.nationality,
            address: profile.address,
            city: profile.city,
            region: profile.region,
            postal_code: profile.postal_code,
            country: profile.country,
            phone_number: profile.phone_number,
            fax: profile.fax,
          };
          saveTempDetailsClaimant(tempData).then(() => {
            localStorage.setItem("email", profile.user.email);
            localStorage.setItem("dispute_id", res.data.id);
            localStorage.setItem(
              "full_name",
              profile.user.first_name + " " + profile.user.last_name
            );
            localStorage.setItem("phone_number", profile.phone_number);
            // history.push("/tiac_claimant_lawyer");
            history.push("/resumerfa?find_active_step=true");
          });
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    } else if (profile.profile_type == "lawyer_rv") {
      createDispute()
        .then((res) => {
          let tempData = {
            dispute_id: res.data.id,
            email: profile.user.email,
            full_name: profile.user.first_name + " " + profile.user.last_name,
            nationality: profile.nationality,
            address: profile.address,
            city: profile.city,
            region: profile.region,
            postal_code: profile.postal_code,
            country: profile.country,
            phone_number: profile.phone_number,
            fax: profile.fax,
          };
          saveTempDetailsClaimantLawyer(tempData).then(() => {
            localStorage.setItem("email", profile.user.email);
            localStorage.setItem("dispute_id", res.data.id);
            localStorage.setItem(
              "full_name",
              profile.user.first_name + " " + profile.user.last_name
            );
            localStorage.setItem("phone_number", profile.phone_number);
            history.push("/resumerfa?find_active_step=true");

            // history.push("/tiac_claimant");
          });
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }
  };

  return (
    <Grid container>
      {/* sidebar */}
      <Grid
        item
        md={4}
        sm={5}
        xs={12}
        className={classes.sidebar}
        style={
          isSebi || isMSME || isTiac || isDifc || isArb || isKilumaya
            ? { backgroundColor: "white", borderRight: "1px solid #E5E5E5" }
            : null
        }
      >
        {isSebi || isMSME || isTiac || isDifc || isArb || isKilumaya ? (
          <SidebarDisputes
            profile={profile}
            disputesActiveClaimant={disputesActiveClaimant}
            disputesResolvedClaimant={disputesResolvedClaimant}
            disputesActiveRespondent={disputesActiveRespondent}
            disputesResolvedRespondent={disputesResolvedRespondent}
            disputesActiveArbitrator={disputesActiveArbitrator}
            disputesResolvedArbitrator={disputesResolvedArbitrator}
            history={history}
          />
        ) : (
          <>
            {!isTiac && (
              <>
                <Typography
                  className={classes.welcome}
                >{`Hi, ${profile.user.first_name}`}</Typography>
                <Box height="5rem" />
              </>
            )}
            {profile.profile_type == "arbitrator_rv" ||
            profile.profile_type == "mediator" ||
            isArbitrationConference ? (
              <>
                <TiacCard
                  disputes={
                    arbitratorDisputeType == "active"
                      ? disputesActiveArbitrator
                      : disputesResolvedArbitrator
                  }
                  role={
                    profile.profile_type == "mediator"
                      ? "Conciliator"
                      : "Arbitrator"
                  }
                  disputesType={arbitratorDisputeType}
                  setRole={setArbitratorDisputeType}
                  history={history}
                />
                <Box height="4rem" />
              </>
            ) : (
              <>
                <TiacCard
                  disputes={
                    claimantDisputeType == "active"
                      ? disputesActiveClaimant
                      : disputesResolvedClaimant
                  }
                  role="Claimant"
                  disputesType={claimantDisputeType}
                  setRole={setClaimantDisputeType}
                  history={history}
                />
                <Box height="4rem" />
                <TiacCard
                  disputes={
                    respondentDisputeType == "active"
                      ? disputesActiveRespondent
                      : disputesResolvedRespondent
                  }
                  role="Respondent"
                  disputesType={respondentDisputeType}
                  setRole={setRespondentDisputeType}
                  history={history}
                />
                <Box height="3rem" />
              </>
            )}
          </>
        )}
      </Grid>
      {/* activity */}
      <Grid item md={8} sm={7} xs={12} className={classes.activity}>
        {profile.profile_type != "arbitrator_rv" &&
          !isKilumaya &&
          !isSebi &&
          !isMSME &&
          !isDifc &&
          !isArbitrationConference && (
            <>
              <Button variant="outlined" color="primary" onClick={onClick}>
                + File a new case
              </Button>
              <Box height="2rem" />
            </>
          )}
        {/* {isDifc && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "224px" }}
              onClick={() => {
                history.push("/halls");
              }}
            >
              Join Halls
            </Button>
            <Box height="2rem" />
          </>
        )} */}

        <GeneralSebiActivities
          activities={activities}
          setActivities={setActivities}
          activityTitle="Account Activity"
        />
        {/* ) : (
          <>
            <Box height="2rem" />
            <Grid container>
              <Grid
                item
                style={{ padding: "0.4rem 2rem", background: "#E0E0E0" }}
              >
                <Typography className={classes.activityText}>
                  Activity
                </Typography>
              </Grid>
            </Grid>
            <Box height="2rem" />
            {allActivities?.map((item) => {
              let isFolder = !item.is_for_file;
              return (
                <React.Fragment key={item.id}>
                  <Paper className={classes.activityPaper}>
                    <Typography
                      className={classes.activityUsername}
                      display="inline"
                    >
                      {`${item.uploaded_by.first_name} ${item.uploaded_by.last_name} `}
                    </Typography>
                    <Typography
                      className={classes.activityheading}
                      display="inline"
                    >
                      {item.activity_name}
                    </Typography>
                    <Typography className={classes.activityTime}>
                      {format(new Date(item.created), "d MMMM y, h:mm a")} -{" "}
                      {item.dispute.name}
                    </Typography>
                    <Box height="1rem" />
                    <Grid container spacing={1}>
                      <Grid item>
                        <img
                          src={isFolder ? FolderImage : FileImage}
                          alt={isFolder ? "folder image" : "file image"}
                          style={{ marginTop: "4px", height: "40px" }}
                        />
                      </Grid>
                      <Grid item sm={10} xs={9}>
                        <Typography className={classes.activityfileFolderName}>
                          {item.file_or_folder_name}
                        </Typography>
                        <Typography className={classes.activityTime}>
                          {bytesToSize(item.size)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Box height="1rem" />
                </React.Fragment>
              );
            })}
          </>
        )} */}
      </Grid>
    </Grid>
  );
}
