import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import theme from "theme";
import SearchCaseForm from "../components/SearchCaseForm";
import { caseRevoke, getSearchFormDetailsMainCase, updateCourt } from "../API/TdsatApi";
import DatePickerTDSAT from "../components/DatePickerTDSAT";
import { useSnackbar } from "contexts/SnackbarContext";
import MessageBox from "../components/MessageBox";
import validateDateAndFormat from "utils/validateDateAndFormat";
import CourtNumberInput from "../components/CourtNumberInput";
import { ACTION_TYPES, DISPOSAL_NATURES, HEAD_OF_NEXT_HEARING_CHOICES, HEADS } from "app/constants";
import { Add } from "@material-ui/icons";
import HeadOfCauseListInput from "../components/HeadOfCauseListInput";

const useStyles = makeStyles((theme)=>({
  entryForm: {
    width: '75%',
    margin: 'auto',
  },
  paper: {
    padding: `3rem 6rem`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    borderRadius: '0.625rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
}))

export default function DailyCaseProceeding(){
  const classes = useStyles();

  const [searchFormData, setSearchFormData] = useState({
    selectType: 'caseNumber',
    case_number: '',
    case_type: '',
    year: '',
  });
  const [formData, setFormData] = useState({
    status: 'Pending',
    action_type: '',
    head_of_next_hearing: '',
    criteria: 'Fixed Date',
    list_it_on: null,
    courtNumber: '',
    disposal_nature: '',
    disposal_date: null,
    amicus_curiae: [],
    bench_nature: 'single_bench',
    remark: '', 
  })
  const [amicusCuriaeData, setAmicusCuriaeData] = useState({
    name: '',
    date: null,
  })
  const [searchResponse, setSearchResponse] = useState(null);

  const { showSnackbar } = useSnackbar();

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputDateOfListItOn = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'list_it_on': value,
    }));
  }

  const handleInputDateOfDisposalDate = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'disposal_date': value,
    }));
  }

  const handleInputDateAmicusCuriae = (value) => {
    setAmicusCuriaeData((prevData) => ({
      ...prevData,
      'date': value,
    }));
  }

  const handleInputChangeAmicusCuriae = (e) => {
    const { name, value } = e.target;
    setAmicusCuriaeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearch = async (searchParams) => {
    try {
      setSearchResponse(null);
      const caseNumber = searchParams?.case_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      const response = await getSearchFormDetailsMainCase(caseNumber, year, caseType, "daily_case_proceeding");
      console.log(response?.data);
      setSearchResponse(response?.data);
      setFormData({
        status: 'Pending',
        action_type: null,
        head_of_next_hearing: null,
        criteria: 'Fixed Date',
        list_it_on: null,
        court_number: '',
        disposal_nature: null,
        disposal_date: null,
        amicus_curiae: response?.data?.main_case?.amicus_curiae || [],
        bench_nature: 'single_bench',
        remark: '', 
      })
    } catch (error) {
      console.error("Error searching case:", error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      "main_case": searchResponse?.main_case?.id,
      "status": formData?.status,
      "action_type": formData?.action_type,
      "head_of_next_hearing": formData?.head_of_next_hearing,
      "criteria": formData?.criteria,
      "listing_date": validateDateAndFormat(formData?.list_it_on, 'yyyy-MM-dd') || null,
      "disposal_nature": formData?.disposal_nature,
      "disposal_date": validateDateAndFormat(formData?.disposal_date, 'yyyy-MM-dd') || null,
      "remarks": formData?.remark,
      "bench_nature": "single_bench",
      "amicus_curiae": formData?.amicus_curiae,
      "court_number": formData?.courtNumber,
    }
    updateCourt(data).then((res)=>{
      // console.log(res?.data);
      if(res?.data?.message || res?.data?.id){
        showSnackbar(res?.data?.message || 'Court updated successfully', "success");
        setFormData({
          status: 'Pending',
          criteria: 'Fixed Date',
        })
        setSearchResponse(null);
      }
    }).catch((error)=>{
      console.log('There is some glitch in getting Cause list data: ', error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    })
  }

  const handleAddAmicusCuriae = async () => {
    if(amicusCuriaeData?.name === ''){
      alert('Name of Amicus Curiae is required.');
      return;
    }
    await formData?.amicus_curiae.push(amicusCuriaeData);
    setFormData({
      ...formData
    });
    setAmicusCuriaeData({
      name:'',
      date:null,
    })
  }

  return (
    <Box>
      {/* <Box style={{display:'flex',gap:'1rem',justifyContent:'flex-end'}}>
        <Button
          size="small"
          style={{backgroundColor:'white',border:'1px solid #AAAFB9',margin:'0.5rem 0 2rem 0',padding:'0.5rem 1.5rem',borderRadius:'0.5rem'}}
          variant="contained"
          // disabled={(date === null || benchNature === '' || courtNumber === '')}
          // onClick={()=>console.log(formData)}
        >
          Update Display Board
        </Button>
        <Button
          size="small"
          style={{backgroundColor:'white',border:'1px solid #AAAFB9',margin:'0.5rem 0 2rem 0',padding:'0.5rem 1.5rem',borderRadius:'0.5rem'}}
          variant="contained"
          // disabled={(date === null || benchNature === '' || courtNumber === '')}
          // onClick={()=>console.log(formData)}
        >
          Set Court to - Not in Session
        </Button>
      </Box> */}
      <Box className={classes.entryForm}>
        <Box style={{display:'flex', gap:'1rem', justifyContent:'space-between'}}>
          <Typography style={{fontSize:'1.5rem', width:'100%'}}>
            DAILY CASE PROCEEDING
          </Typography>
        </Box>
        <Box style={{height:'1.5rem'}}/>
        <SearchCaseForm
          handleInputChange={handleInputChangeSearch}
          classes={classes}
          handleSearch={handleSearch}
          formData={searchFormData}
        />
        {searchResponse && (
          <>
            {searchResponse?.message && (
              <MessageBox
                title={'Case Number is already listed'}
                subTitle={searchResponse?.message}
              />
            )}
            <Box style={{height:'2rem'}}></Box>
            <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem', marginBottom:'2rem'}}>
              <Grid item xs={12}>
                <Typography className={classes.formHeading}>
                  {searchResponse?.main_case?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Petitioner's Advocate:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">{searchResponse?.petitioners_advocates?.join(', ')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Respondent's Advocate:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">{searchResponse?.respondents_advocates?.join(', ')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Subject Category:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">{searchResponse?.subject_category}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Amicus Curiae:</Typography>
              </Grid>
              <Grid item xs={6}>
                {formData?.amicus_curiae?.map((item,index)=>{
                  return (
                    <Typography key={index} align="left">{item?.name}</Typography>
                  )
                })}
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={5}>
                  <FormControl fullWidth margin="none" variant="outlined">
                    <Typography className={classes.label}>Amicus Curiae Name</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="name"
                      value={amicusCuriaeData?.name}
                      placeholder='Type'
                      onChange={handleInputChangeAmicusCuriae}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth margin="none" variant="outlined">
                    <Typography className={classes.label}>Date</Typography>
                    <DatePickerTDSAT
                      date={amicusCuriaeData?.date}
                      setDate={handleInputDateAmicusCuriae}
                      size='small'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    style={{marginTop:'1.5rem',padding:'0.6rem 1.5rem',backgroundColor:'#F4F8FF'}}
                    variant="contained"
                    onClick={handleAddAmicusCuriae}
                    endIcon={<Add/>}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <MessageBox
              title={`Case is ${searchResponse?.main_case?.open_status}`}
            />
            <Box style={{height:'2rem'}}></Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem'}}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" margin="normal" style={{textAlign:'left', width:'100%'}}>
                    <FormLabel component="legend">Today's Status</FormLabel>
                    <RadioGroup row onChange={handleInputChange} name='status' value={formData.status}>
                      <FormControlLabel value={'Pending'} control={<Radio color="primary" />} label={'Pending'} />
                      <FormControlLabel value={'Disposal'} control={<Radio color="primary" />} label={'Disposal'} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {formData.status === 'Pending' ? (
                  <>
                    <Grid item xs={6}>
                      <FormControl required fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>{`Today's Action Type*`}</Typography>
                        <Select
                          name="action_type"
                          value={formData.action_type}
                          onChange={handleInputChange}
                        >
                          {ACTION_TYPES.map((actionType,index)=>{
                            return (
                              <MenuItem key={index} value={actionType}>{actionType}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <HeadOfCauseListInput
                        headOfListing={formData?.head_of_next_hearing}
                        isRequired={true}
                        label={'Head of Next Hearing*'}
                        name="head_of_next_hearing"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={formData.criteria === 'Fixed Date' ? 6 : 12}>
                      <FormControl required fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>{`Criteria (if any) for Next Hearing*`}</Typography>
                        <Select
                          name="criteria"
                          value={formData.criteria}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={'Fixed Date'}>{'Fixed Date'}</MenuItem>
                          <MenuItem value={'Not Fixed'}>{'Not Fixed'}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {formData.criteria === 'Fixed Date' && (
                      <>
                        <Grid item xs={6}>
                          <FormControl required fullWidth margin="none" variant="outlined">
                            <Typography className={classes.label}>List it on*</Typography>
                            <DatePickerTDSAT
                              date={formData.list_it_on}
                              setDate={handleInputDateOfListItOn}
                              isRequired={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <CourtNumberInput
                            courtNumber={formData.courtNumber}
                            onChange={handleInputChange}
                            listingDate={formData.list_it_on}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                ):(
                  <>
                    <Grid item xs={6}>
                      <FormControl required fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>{`Disposal Nature*`}</Typography>
                        <Select
                          name="disposal_nature"
                          value={formData.disposal_nature}
                          onChange={handleInputChange}
                        >
                          {DISPOSAL_NATURES.map((disposalNature,index)=>{
                            return (
                              <MenuItem key={index} value={disposalNature}>{disposalNature}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl required fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>Disposal Date</Typography>
                        <DatePickerTDSAT
                          date={formData.disposal_date}
                          setDate={handleInputDateOfDisposalDate}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                
                <Grid item xs={12}>
                  <FormControl required fullWidth margin="none" variant="outlined">
                    <Typography className={classes.label}>Remark</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="remark"
                      value={formData.remark}
                      placeholder='Type'
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                // disabled={(date === null || benchNature === '' || courtNumber === '')}
                // onClick={()=>console.log(formData)}
              >
                Submit
              </Button>
              <Box style={{height:'2rem'}}></Box>
            </form>
          </>
        )}
        
      </Box>
    </Box>
  )
}