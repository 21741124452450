import { Box, Breadcrumbs, Button, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { KeyboardBackspace, NavigateNext, PrintOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DateRangePickerTDSAT from "../DateRangePickerTDSAT";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getAllFDR, printFDRList } from "../API/TdsatApi";
import { format } from "date-fns";
import validateDateAndFormat from "utils/validateDateAndFormat";
import FDRUpdateForm from "./FDRUpdateForm";
import fileDownload from "js-file-download";

const useStyles = makeStyles((theme)=>({
  filtersContainer: {
    padding: '1rem', 
    borderBottom: '1px solid black', 
    display: 'flex', 
    gap: '15px', 
    justifyContent:'center',
    // width: '80%',
    margin: 'auto',
  },
  buttonPrint: {
    borderRadius: '0.5rem',
    border: '1px solid #AAAFB9',
    background: '#fff',
    padding: '0.5rem 1rem',
  },
  tableHeadItem:{
		color: 'grey',
		fontFamily: 'Satoshi-Bold',
    wordBreak: 'break-word',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '0.9375rem',
    wordBreak: 'break-word',
	},
	valuePaper: {
		padding: "1.5rem 8px",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}))

export default function FDRReport({handleGoBack}) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [FDRList, setFDRList] = useState([]);
  const [updatingFdr, setUpdatingFdr] = useState(null);

  const spacing = [1,1,1,1,1,1,1,1,1,1,1,1];
  const tableHeadings = ['Sr. No.','Case No.','Party Name','Order Date','Amount','Deposit By','Period','Time Duration','DD No.','FDR No.','FRD/TDR Date','Action'];

  const processResponse = (data,index) => {
    const item = data;
    const arr = [
      index+1, 
      item?.case_number, 
      item?.party_name, 
      validateDateAndFormat(item?.order_date,'dd/MM/yy'), 
      item?.amount, 
      item?.deposit_by, 
      item?.initial_period, 
      validateDateAndFormat(item?.allowed_time_duration,'dd/MM/yy'), 
      item?.dd_number, 
      item?.id, validateDateAndFormat(item?.created_at,'dd/MM/yy'), 
      <Button
        style={{backgroundColor:'#F4F8FF',color:'#4B4E56'}}
        size="small"
        onClick={()=> handleClickAction(data)}
      >
        Pending
      </Button>
    ];
    return arr;
  }

  const reload = () => {
		setLoading(true);
		getAllFDR(validateDateAndFormat(startDate,'dd-MM-yyyy'),validateDateAndFormat(endDate,'dd-MM-yyyy'))
    .then((res)=>{
      const response = res?.data;
      setFDRList(response);
      // const processedResponse = processResponse(response);
      // let count = res?.data?.count;
      // let t = parseInt(count / PAGE_SIZE);
      // let r = count % PAGE_SIZE;
      // let total = t + (r > 0 ? 1 : 0);
      // setTotalPages(total);
      setLoading(false);
    })
  }

  useEffect(()=>{
    if((startDate && endDate) || (!startDate && !endDate)){
      reload();
    }
  },[startDate,endDate])

  const handleClickAction = (fdr) => {
    setUpdatingFdr(fdr);
  }

  const handleBackToFdrReport = () => {
    setUpdatingFdr(null);
    reload();
  }

  const handlePrintFDRReport = () => {
    printFDRList(validateDateAndFormat(startDate, 'dd-MM-yyyy'),validateDateAndFormat(endDate, 'dd-MM-yyyy')).then((res)=>{
      fileDownload(
        res.data,
        `FDR_Report_${validateDateAndFormat(startDate, 'dd-MM-yyyy')}_to_${validateDateAndFormat(endDate, 'dd-MM-yyyy')}.pdf`
      );
    }).catch((error)=>{
      console.log(error);
    })
  }

  return (
    <Box>
      <Box style={{display:'flex',paddingBottom:'1.5rem',gap:'10px'}}>
        <Typography style={{cursor:'pointer'}} onClick={updatingFdr ? handleBackToFdrReport : ()=>{handleGoBack('FDR')}}>
          <KeyboardBackspace/>
        </Typography>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" style={{cursor:'pointer'}} onClick={()=>{handleGoBack('FDR')}}>
            FDR
          </Link>
          {updatingFdr && (
            <Link color="inherit" style={{cursor:'pointer'}} onClick={handleBackToFdrReport}>
              FRD Modify/Update
            </Link>
          )}
          <Typography color="textPrimary">{updatingFdr ? updatingFdr?.case_number : 'FRD Modify/Update'}</Typography>
        </Breadcrumbs>
      </Box>
      {updatingFdr ? (
        <FDRUpdateForm 
          updatingFdr={updatingFdr}
        />
      ) : (
        <>
          <Typography style={{fontSize:'1.5rem', padding:'2rem'}}>
            FDR REPORT
          </Typography>
          <Box style={{backgroundColor:'white'}}>
            <Box style={{height:'1rem'}}></Box>
            <Box className={classes.filtersContainer} style={{justifyContent:'space-between'}}>
              <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
              <Button
                className={classes.buttonPrint}
                size="small"
                endIcon={<PrintOutlined/>}
                onClick={handlePrintFDRReport}
              >
                Print Report
              </Button>
            </Box>
            <Box>
              <Box style={{height: '2rem'}}/>
              <Grid container style={{ rowGap: "8px" }}>
                <Box style={{padding:'1.5rem 8px', width: '100%'}}>
                  <Grid container spacing={2} justifyContent="flex-start">
                    {tableHeadings.map((heading,index)=>{
                      return (
                        <Grid key={index} item xs sm={spacing[index]}>
                          <Typography className={classes.tableHeadItem}>
                            {heading}
                          </Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
                {loading ? (
                  <Box style={{margin:'auto',width:'100%',padding:'2rem 0'}}>
                    <CircularCenteredLoader/>
                  </Box>
                ) : (
                  FDRList.length !== 0 ? (
                    FDRList.map((dispute,index) => {
                      const processedDispute = processResponse(dispute,index);
                      return (
                        <Box key={index} className={classes.valuePaper}>
                          <Grid container spacing={2} justifyContent="flex-start">
                            {processedDispute.map((item,index)=>{
                              return (
                                <Grid key={index} item xs sm={spacing[index]}>
                                  <Typography className={classes.tableRowValue}>
                                    {item}
                                  </Typography>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Box>
                      );
                    })
                  ) : (
                    <Grid container justify="center">
                      <Grid item>
                        <Box textAlign="center">
                          <Typography
                            style={{
                              padding: '2rem 8px',
                              textAlign: 'center',
                              fontSize: '1.5rem'
                            }}
                          >
                            No case filed previously.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                )}
                {/* {FDRList.length !== 0 && (
                  <Box style={{padding:'16px'}}>
                    <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
                  </Box>
                )} */}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}