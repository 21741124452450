import { Box, Button } from "@material-ui/core";
import { instance, vchost } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isArbitrationConference, isDifc } from "Routes/Routes";
import DyteMeetingVC from "./DyteMeeting";
import { DyteSpinner } from "@dytesdk/react-ui-kit";

const VideoConferencing = ({
  roomName,
  user,
  profile,
  setShowVC,
  vcType,
  disputeId,
}) => {
  const [loading, setLoading] = useState(true);
  const [showZoom, setShowZoom] = useState(false);
  const [api, setApi] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const history = useHistory();
  const [dyteToken, setDyteToken] = useState("");

  const { activeGroup, groups } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  const currentGroup = groups.filter((group) => group._id === activeGroup)[0];

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const vcContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  async function startConferenceZoom() {
    try {
      if (
        (currentGroup.fname.split("_")[0] == "arbitration" ||
          currentGroup.fname.split("_")[0] == "devarbitration") &&
        (isDifc || isArbitrationConference)
      ) {
        history.push(`/zoom/start?disputeId=${disputeId}&roomName=${roomName}`);
      } else {
        setLoading(true);
        setErrorType(null);
        let response = await instance({
          method: "post",
          url: "/createzoommeeting",
          data: {
            disputeId: "" + disputeId + roomName,
          },
        });
        setLoading(false);
        setShowVC(false);
        if (response.data.user_key == profile.user.id) {
          setShowZoom(false);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = response.data.start_url;
          a.click();
          document.body.removeChild(a);
        } else {
          setShowZoom(false);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = response.data.join_url;
          a.click();
          document.body.removeChild(a);
        }
      }
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        console.log(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  async function startConferenceDyte() {
    try {
      setLoading(true);
      setErrorType(null);
      let response = await instance({
        method: "post",
        url: "/createdytemeeting",
        data: {
          disputeId: "" + disputeId + roomName,
          case_id : disputeId
        },
      });
      setLoading(false);
      setDyteToken(response.data.user.token);
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        console.log(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  async function closePreviousZoomMeetings() {
    try {
      setLoading(true);
      await instance({
        method: "post",
        url: "/closezoompreviousmeetings",
        data: {},
      });
      startConferenceZoom();
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  useEffect(() => {
    if (!isEmpty(api)) api.executeCommand("hangup");
    else {
      if (vcType === "zoom") {
        startConferenceZoom();
      } else if (vcType === "dyte") {
        startConferenceDyte();
      }
    }
  }, [roomName]);

  const renderErrorContainer = () => {
    switch (errorType) {
      case "not-started":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Meeting not started by host</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
          </Box>
        );
      case "not-found":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Meeting with the dispute id {disputeId} does not exist</h3>
          </Box>
        );
      case "in-meeting":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>You are already a host in some other meeting.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      case "meeting-close-error":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Error while closing meetings.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const removeSessionStorage = () => {
    sessionStorage.clear();
  };

  function handleEndVideoDyte(){
    removeSessionStorage();
    setShowVC(false);
    setApi(null);
    setDyteToken("");
  }

  return (
    <Box display="flex" justifyContent="center" flexBasis="50%" flexGrow={1}>
      <div style={containerStyle}>
        {loading && (
          <Box display="flex" justifyContent="center" pt="3rem">
            <CircularCenteredLoader />
          </Box>
        )}
        {errorType && renderErrorContainer()}
        {(vcType === "dyte" && dyteToken) ? (
          <DyteMeetingVC
            dyteToken={dyteToken}
            vcContainerStyle={vcContainerStyle}
            onEnd={handleEndVideoDyte}
          />
        ):
        (vcType === 'jitsi') ?
          (
            <div id="vc-container" style={vcContainerStyle} />
          )
        :
          (
            <DyteSpinner/>
          )
        }
      </div>
    </Box>
  );
};
export default VideoConferencing;
