import React, {useState} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';
import { CASE_TYPES_WITH_LABEL } from "../Notice/NoticeCaseForm";

const YEARS = ['Select', '2021', '2022', '2023', '2024'];
const CASE_TYPE = ["ADR", "AERA", "BA", "BP", "CA", "EA", "TA", "TP"]


const SearchCaseForm = ({classes, formData, handleInputChange, handleSearch, isConnectedCase = false}) => {
    const [errors, setErrors] = useState({
        case_number: '',
        year: '',
        diary_number: '', 
        case_type: ''
    });

    const handleGoClick = async () => {
        let hasError = false;
        let newErrors = { case_number: '', year: '', diary_number: '', case_type: '' };

        if (!formData.case_number && !formData.diary_number) {
            newErrors.case_number = 'Case Number is required';
            newErrors.diary_number = 'Diary Number is required';
            hasError = true;
        }

        if (!formData.year || formData.year === 'Select') {
            newErrors.year = 'Year is required';
            hasError = true;
        }

        if (!formData.case_type) {
            newErrors.case_type = 'Case Type is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (!hasError) {
            handleSearch(formData);
        }
    };
    
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                {isConnectedCase && <Typography style={{color: '#4A3BFF', fontSize: '22px', fontWeight: '700', marginBottom: '16px'}}>Case to be connected</Typography>}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <InputLabel>{'Select Type'}</InputLabel>
                        <Select
                            label={'Select Type'}
                            name="selectType"
                            value={formData.selectType}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="caseNumber">Case Number</MenuItem>
                            <MenuItem value="diaryNumber">Diary Number</MenuItem>
                        </Select>
                        {errors.selectType && <FormHelperText>{errors.selectType}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <InputLabel>{'Case Type'}</InputLabel>
                        <Select
                            label={'Case Type'}
                            name="case_type"
                            value={formData.case_type}
                            onChange={handleInputChange}
                        >
                            {CASE_TYPES_WITH_LABEL.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                            ))}
                        </Select>
                        {errors.case_type && <FormHelperText error>{errors.case_type}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {formData.selectType === 'caseNumber' ? (
                            <TextField
                                label={'Case Number'}
                                name="case_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={formData.case_number}
                                onChange={handleInputChange}
                                error={!!errors.case_number}
                                helperText={errors.case_number}
                            />
                            ) : (
                            <TextField
                                label={'Diary Number'}
                                name="diary_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={formData.diary_number}
                                onChange={handleInputChange}
                                error={!!errors.diary_number}
                                helperText={errors.diary_number}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <InputLabel>{'Year'}</InputLabel>
                        <Select
                            label={'Year'}
                            name="year"
                            value={formData.year}
                            onChange={handleInputChange}
                        >
                            {YEARS.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                  {option}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.year && <FormHelperText error>{errors.year}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={()=>handleGoClick(formData)}
            >
                Search
            </Button>
            </Grid>
        </Paper>
    )
}

export default SearchCaseForm;