import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
		display: 'flex',
		gap: '2rem',
		paddingRight: '1rem',
    borderRadius: '0.5rem',
		backgroundColor: 'white',
	},
  title: {
		fontSize: '1.375rem',
		fontFamily: 'Satoshi-Bold',
		color: theme.palette.primary.main,
		textAlign: 'left',
	},
	subTitle: {
		fontSize: '0.875rem',
		color: '#161819',
		textAlign: 'left',
	}
}));

export default function MessageBox({title,subTitle}){
  const classes = useStyles();

	return (
		<Box className={classes.root} >
			<Box style={{width:'0.87rem',backgroundColor:'#4A3BFF',borderRadius:'0.5rem 0 0 0.5rem'}}></Box>
			<Box style={{padding:'1rem 0',flex:1}}>
				{title && (
					<Typography className={classes.title}>
						{title}
					</Typography>
				)}
				{subTitle && (
					<Typography className={classes.subTitle}>
						{subTitle}
					</Typography>
				)}
			</Box>
		</Box>
	)
}