import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  makeStyles
} from '@material-ui/core';
import UploadFileTdsat from './components/UploadFileTdsat';
import PayBharatKosh from './components/PayBharatKosh';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: 12,
    fontSize: 22,
  },
  buttonPay: {
    padding: theme.spacing(1),
    borderRadius: 12,
    width: '50%',
    display: 'flex',
  },
  labelTitle: {
    display: 'flex',
    marginBottom: '8px',
    fontSize: '12px'
  },
  labelSubtitle: {
    display: 'flex',
    marginTop: '8px',
    fontSize: '11px',
    color: '#858D98',
    marginBottom: '32px'
  },
  amount: {
    fontSize: '18px',
  },
  titleCase: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 700,
    display: 'flex'
  },
  nameTitle: {
    fontSize: '18px',
    lineHeight: '31.32px',
    fontWeight: 400,
    display: 'flex'
  },
  containerCaseInfo: {
    marginBottom: '18px'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerUser: {
    marginTop: '18px'
  },
  titleUserInfo: {
    display: 'flex',
    color: '#000',
    fontSize: '16px'
  },
  partyName: {
    fontSize: '18px',
    fontWeight: '400'
  }
}));

const FileUploadScreen = ({mainCaseUpdate, updateMainCase, formDetails, applicationResponse, errors, selectedAmount, setERecieptData, eRecieptData}) => {
  const classes = useStyles();

  const handleEcourtFeesNumber = (event) => {
    updateMainCase('e_court_fee_receipt_number', event.target.value);
  };

  return (
    <Box component="form" sx={{ maxWidth: 600, mx: 'auto', p: 3 }} style={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
      <PayBharatKosh
        classes={classes}
        formData={formDetails}
        handleInputChange={handleEcourtFeesNumber}
        errors={errors}
        amount={selectedAmount}
        setERecieptData={setERecieptData}
        eReceiptData={eRecieptData}
        applicationResponse={mainCaseUpdate}
      />
      <UploadFileTdsat
        classes={classes} 
        formData={formDetails}
        handleInputChange={updateMainCase}
        setFormData={updateMainCase}
        applicationResponse={mainCaseUpdate}
        uploadType={'MainCase'}
        errors={errors}
      />
    </Box>
  );
};

export default FileUploadScreen;
