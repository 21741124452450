import React, {useState} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';

const YEARS = ['Select', '2021', '2022', '2023', '2024'];

const MainCaseDetails = ({classes, formData, handleInputChange, handleClaimantRespondentData, partyDetails}) => {
    const [errors, setErrors] = useState({
        case_number: '',
        year: '',
        diary_number: ''
    });

    const getPartyNames = (partyDetails) => {
        const appellants = partyDetails?.filter(item => item.type === "Appellant(s)" || item.type === "Petitioner(s)");
        const respondents = partyDetails?.filter(item => item.type === "Respondent(s)");
        return(
            <Typography className={classes.partyName}>{appellants?.[0]?.full_name}&nbsp; and others. vs {[respondents?.[0]?.full_name]}&nbsp; and others.</Typography>
        )
    }

    const handleGoClick = () => {
        let hasError = false;
        let newErrors = { case_number: '', year: '', diary_number: '' };

        if (!formData.case_number && !formData.diary_number) {
            newErrors.case_number = 'Case Number is required';
            newErrors.diary_number = 'Diary Number is required';
            hasError = true;
        }

        if (!formData.year || formData.year === 'Select') {
            newErrors.year = 'Year is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (!hasError) {
            handleClaimantRespondentData(formData);
        }
    };
    
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <InputLabel>{'Select Type'}</InputLabel>
                        <Select
                            label={'Select Type'}
                            name="selectType"
                            value={formData.selectType}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="caseNumber">Case Number</MenuItem>
                            <MenuItem value="diaryNumber">Diary Number</MenuItem>
                        </Select>
                        {errors.selectType && <FormHelperText>{errors.selectType}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {formData.selectType === 'caseNumber' ? (
                            <TextField
                                label={'Case Number'}
                                name="case_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={formData.case_number}
                                onChange={handleInputChange}
                                error={!!errors.case_number}
                                helperText={errors.case_number}
                            />
                            ) : (
                            <TextField
                                label={'Diary Number'}
                                name="diary_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={formData.diary_number}
                                onChange={handleInputChange}
                                error={!!errors.diary_number}
                                helperText={errors.diary_number}
                            />
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <InputLabel>{'Year'}</InputLabel>
                        <Select
                            label={'Year'}
                            name="year"
                            value={formData.year}
                            onChange={handleInputChange}
                        >
                            {YEARS.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                        </Select>
                        {errors.year && <FormHelperText>{errors.year}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={()=>handleGoClick(formData)}
            >
                GO
            </Button>
            </Grid>
           { partyDetails?.length > 0 && 
           <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 18 }}>
                    <Typography className={classes.partyName}>Parties’ Name:</Typography>
                   { getPartyNames(partyDetails)}
                </Grid>
            </Grid>}
        </Paper>
    )
}

export default MainCaseDetails;