import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  Tabs, 
  Tab, 
  InputLabel, 
  FormControl, 
  Paper, 
  Box ,
  makeStyles,
  Grid,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { getCaseTypes } from '../../ApplicationFillingForm';
import NoticeCaseForm from './NoticeCaseForm';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import BackBtnBreadcrumb from '../shared/BackBtnBreadcrumb';

const YEARS = ['Select', '2021', '2022', '2023', '2024'];

export async function createNotice(formData){
  return await instance({
    method: "post",
    url: `/tdsat/notices/`,
    data: formData
  })
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '900px',
    margin: '0 auto',
  },
  formControl: {
    marginBottom: '20px',
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: '10px',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  searchButton: {
    marginTop: '10px',
    display: 'block',
    width: '100%',
  },
  caseDetails: {
    marginTop: '10px',
    color: '#3f51b5',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
    borderRadius: '10px',
    marginTop:  theme.spacing(4),
  },
  tabsContainer: {
    marginBottom: '20px',
  },
  advocateInfo: {
    fontWeight: 500,
    marginBottom: '1.5rem',
  },
  advocateInfoTitle: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Satoshi-medium'
  },
  titleForHeadings: {
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Satoshi-medium'
  },
  titleHeader: {
    fontSize: '22px',
    color: '#4A3BFF',
    fontWeight: 700
  },
  label: {
    color: '#242529',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
}));

const NoticeForm = ({onBack}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [formData, setFormData] = useState({date: null,send_respondents: "false", send_applicant: "false", send_applicant_advocate: "false", send_respondents_advocate: "false" });
  const [searchMainCaseResponse, setSearchMainCaseResponse] = useState({});
  const [errors,setErrors] = useState({});
  const NOTICE_TYPES = [
    'notice',
    'dasti_notice',
    'ea/ma/other'
  ]
  const tabTypesButton = ['Fresh Notice', 'Dasti Notice', 'EA/MA/Other Notice'];

  const [noticeResponse, setNoticeResponse] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // setFormData({})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // This function will render the content based on the selected tab using switch-case
  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return renderNoticeTab();
      case 1:
        return renderDastiNoticeTab();
      case 2:
        return renderEA_MA_OtherTab();
      default:
        return null;
    }
  };

  // Notice Tab content
  const renderNoticeTab = () => (
    <>
      <NoticeCaseForm 
        classes={classes} 
        formData={formData} 
        handleInputChange={handleInputChange} 
        setSearchMainCaseResponse={setSearchMainCaseResponse} 
        searchMainCaseResponse={searchMainCaseResponse} 
        setFormData={setFormData}
        setErrors={setErrors}
        errors={errors}
        noticeType={NOTICE_TYPES[tabValue]}
      />
    </>
  );

  // Dasti Notice Tab content
  const renderDastiNoticeTab = () => (
    <>
      <NoticeCaseForm 
        classes={classes} 
        formData={formData} 
        handleInputChange={handleInputChange} 
        setSearchMainCaseResponse={setSearchMainCaseResponse}  
        searchMainCaseResponse={searchMainCaseResponse} 
        setFormData={setFormData}
        setErrors={setErrors}
        errors={errors}
        noticeType={NOTICE_TYPES[tabValue]}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2} >
                  <Typography className={classes.titleForHeadings}>Dasti send to Connected Cases Parties/ Others:</Typography>
                  <Grid item xs={12}>
                    <TextField
                      label={'Type'}
                      name="dasti_send_connencted_case_parties_or_others"
                      fullWidth
                      required
                      margin="none"
                      variant="outlined"
                      value={formData.dasti_send_connencted_case_parties_or_others}
                      onChange={handleInputChange}
                      error={!!errors.dasti_send_connencted_case_parties_or_others}
                      helperText={errors.dasti_send_connencted_case_parties_or_others}
                    />
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Paper>
    </>
  );

  // EA/MA/Other Tab content
  const renderEA_MA_OtherTab = () => (
    <>
      <NoticeCaseForm 
        classes={classes} 
        formData={formData} 
        handleInputChange={handleInputChange} 
        setSearchMainCaseResponse={setSearchMainCaseResponse}  
        searchMainCaseResponse={searchMainCaseResponse} 
        setFormData={setFormData}
        setErrors={setErrors}
        errors={errors}
        noticeType={NOTICE_TYPES[tabValue]}
      />
      <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                      <Grid item xs={6} className={classes.titleForHeadings}>
                        Send Order For Respondent's Advocate:
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                            <InputLabel>Main Advocate</InputLabel>
                            <Select
                                name="send_respondents_advocate"
                                value={formData.send_respondents_advocate}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                            </Select>
                        </FormControl> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>
  );

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.date) tempErrors.date = 'This field is required';
    if (!formData.fromTime) tempErrors.fromTime = 'This field is required';
    if (!formData.benchNature) tempErrors.benchNature = 'This field is required';
    if (!formData.headOfListing) tempErrors.headOfListing = 'This field is required';
    if (!formData.courtNumber) tempErrors.courtNumber = 'This field is required';
    return tempErrors;
  };

  const handleCreateNewNotice = async (formDetails) => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await createNotice({
          "type": NOTICE_TYPES[tabValue],
          "listing_date": format(formDetails?.date, 'yyyy-MM-dd'),
          "time": formDetails?.fromTime,
          "head_of_causelist":formDetails?.headOfListing,
          "bench_nature": formDetails?.benchNature,
          "court_number": formDetails?.courtRoomNumber,
          "case_number": formDetails?.case_number,
          "respondent_name": formDetails?.respondent_name,
          "send_respondents": formDetails?.send_respondents,
          "send_applicant": formDetails?.send_applicant,
          "send_applicant_advocate": formDetails?.send_applicant_advocate,
          "send_respondents_advocate": formDetails?.send_respondents_advocate,
          "dasti_send_connencted_case_parties_or_others": formDetails?.dasti_send_connencted_case_parties_or_others
        })
        setNoticeResponse(response?.data);
        Swal.fire({
          title: "Notice",
          text: "Notice Created Successfully",
          icon: "success"
        }).then(() => {
          // Refresh the page after success message
          window.location.reload();
        });
        setFormData({
          date: "",
          fromTime: "",
          headOfListing: "",
          benchNature: "",
          courtRoomNumber: "",
          case_number: "",
          respondent_name: "",
          send_respondents: "false",
          send_applicant: "false",
          send_applicant_advocate: "false",
          send_respondents_advocate: "false",
          dasti_send_connencted_case_parties_or_others: "false"
        });
      }catch(error){
        console.log(error,'error', error.response)
        // setErrorResponse(error.response.data);
      }
    }else {
      setErrors(formErrors);
    }
  }

  console.log(tabValue,'tabvalue')

  return (
    <div className={classes.container}>
      <BackBtnBreadcrumb
         onBackClick={() => onBack()}
         breadcrumbs={[
           { label: 'Notice', onClick: () => onBack() },
         ]}
         currentPath={tabTypesButton[tabValue]}
      />
      {/* Tabs at the top, outside the Paper component */}
      <div className={classes.tabsContainer}>
        <Typography className={classes.label}>{tabTypesButton[tabValue]}</Typography>
        <Tabs value={tabValue} onChange={handleTabChange} centered indicatorColor="primary" textColor="primary">
          <Tab label="Notice" />
          <Tab label="Dasti Notice" />
          <Tab label="EA/MA/Other" />
        </Tabs>
      </div>

      {/* Render content based on selected tab */}
      {renderTabContent()}

      {/* Submit Button (common for all tabs) */}
      <div className={classes.submitButton}>
        <Button variant="contained" color="primary" size="large" onClick={()=>handleCreateNewNotice(formData)}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default NoticeForm;
