import { Box, Button, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import theme from "theme";
import SearchCaseForm from "../components/SearchCaseForm";
import { caseRevoke, getSearchFormDetailsMainCase } from "../API/TdsatApi";
import DatePickerTDSAT from "../components/DatePickerTDSAT";
import { useSnackbar } from "contexts/SnackbarContext";
import MessageBox from "../components/MessageBox";
import validateDateAndFormat from "utils/validateDateAndFormat";

const useStyles = makeStyles((theme)=>({
  entryForm: {
    width: '75%',
    margin: 'auto',
  },
  paper: {
    padding: `3rem 6rem`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    borderRadius: '0.625rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
}))

export default function CaseRevoke(){
  const classes = useStyles();

  const [searchFormData, setSearchFormData] = useState({
    selectType: 'caseNumber',
    case_number: '',
    case_type: '',
    year: '',
  });
  const [formData, setFormData] = useState({
    date_of_revoke: null,
    reason: '',
  })
  const [searchResponse, setSearchResponse] = useState(null);

  const { showSnackbar } = useSnackbar();

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputDateOfRevoke = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date_of_revoke': value,
    }));
  }

  const handleSearch = async (searchParams) => {
    try {
      setSearchResponse(null);
      const caseNumber = searchParams?.case_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      const response = await getSearchFormDetailsMainCase(caseNumber, year, caseType, "case_revoke");
      console.log(response?.data);
      setSearchResponse(response?.data);
      setFormData({
        date_of_revoke: null,
        reason: '',
      })
    } catch (error) {
      console.error("Error searching case:", error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    const {date_of_revoke,reason} = formData
    if(date_of_revoke === null || reason === ''){
      alert('Please enter both fields.');
      return;
    }
    const data = {
      "case_id": searchResponse?.case_data?.id,
      "date_of_revoke": validateDateAndFormat(date_of_revoke, 'dd-MM-yyyy'),
      "reason_for_revoke": reason,
    }
    caseRevoke(data).then((res)=>{
      // console.log(res?.data);
      if(res?.data?.message){
        showSnackbar(res?.data?.message, "success");
        setFormData({
          date_of_revoke: null,
          reason: '',
        })
        setSearchResponse(null);
      }
    }).catch((error)=>{
      console.log('There is some glitch in getting Cause list data: ', error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    })
  }
  
  return (
    <Box>
      <Box className={classes.entryForm}>
        <Box style={{display:'flex', gap:'1rem', justifyContent:'space-between'}}>
          <Typography style={{fontSize:'1.5rem', width:'100%'}}>
            CASE REVOKE 
          </Typography>
        </Box>
        <Box style={{height:'1.5rem'}}/>
        <SearchCaseForm
          handleInputChange={handleInputChangeSearch}
          classes={classes}
          handleSearch={handleSearch}
          formData={searchFormData}
        />
        {searchResponse && (
          <>
            <MessageBox
              title={searchResponse?.message}
            />
            <Box style={{height:'2rem'}}></Box>
            <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem', marginBottom:'2rem'}}>
              <Grid item xs={12}>
                <Typography className={classes.formHeading}>
                  {searchResponse?.case_data?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Disposal Date:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">{validateDateAndFormat(searchResponse?.disposal_date, 'dd/MM/yyyy')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">Disposal Remarks:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left">{searchResponse?.remarks}</Typography>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem'}}>
                <Grid item xs={12}>
                  <FormControl required fullWidth margin="none" variant="outlined">
                    <Typography className={classes.label}>Date of Revoke*</Typography>
                    <DatePickerTDSAT
                      date={formData.date_of_revoke}
                      setDate={handleInputDateOfRevoke}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth margin="none" variant="outlined">
                    <Typography className={classes.label}>Reason For Revoke*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="reason"
                      value={formData.reason}
                      placeholder='Type'
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                // disabled={(date === null || benchNature === '' || courtNumber === '')}
                // onClick={()=>console.log(formData)}
              >
                Submit
              </Button>
              <Box style={{height:'2rem'}}></Box>
            </form>
          </>
        )}
        
      </Box>
    </Box>
  )
}