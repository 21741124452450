import React, { useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { DateRangeOutlined } from '@material-ui/icons'


export default function DatePickerTDSAT({date, setDate, isRequired=false, label, size='medium', minimumDate=new Date('1900-01-01'), maximumDate=new Date('2100-12-31')}) {
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        views={['year','month','date']}
        required={isRequired}
        inputVariant='outlined'
        size={size}
        fullWidth
        label={label}
        placeholder='DD/MM/YYYY'
        format="dd/MM/yyyy"
        value={date}
        onChange={setDate}
        minDate={minimumDate}
        maxDate={maximumDate}
        InputProps={{
          endAdornment: <DateRangeOutlined style={{color:'#858D98',cursor:'pointer'}}/>
        }}
        clearable={true}
        clearLabel={'Clear'}
      />   
    </MuiPickersUtilsProvider>
  )
}