import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  TextField,
  Box,
  Grid,
  Collapse,
  Select,
  MenuItem,
  Button,
  makeStyles,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { format } from 'date-fns';
import instance from 'api/globalaxios';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import { useSnackbar } from 'contexts/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  defectTableHeading: {
    fontFamily: 'Satoshi-Bold',
    fontSize: '1.15rem',
  },
  otherComment: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '80%',
    margin: 'auto',
    padding: '20px',
  },
  bold: {
    fontFamily: 'Satoshi-Bold',
  },
  row: {
    display: 'flex',
    gap: '1rem',
    padding: '8px 0',
    marginBottom: '10px',
    borderBottom: '1px solid #161819'
  }
}));

// const initialRows = [
//   { id: 1, defectCode: '01', description: 'Whether the case in prescribed proforma?', status: true, comment: '' },
//   { id: 2, defectCode: '02', description: 'Whether verification has been done according to prescribed proforma?', status: true, comment: '' },
//   { id: 3, defectCode: '03', description: 'Whether proper space provided for all MA(s)?', status: true, comment: '' },
//   { id: 4, defectCode: '04', description: 'Whether undertaking is given that any illegible documents will not be relied upon hearing in the court?', status: true, comment: '' },
//   { id: 5, defectCode: '05', description: 'Whether certified copy of annexure is filed?', status: true, comment: '' },
//   { id: 6, defectCode: '06', description: 'Whether annexure is filed on one side of paper?', status: true, comment: '' },
//   { id: 7, defectCode: '07', description: 'Whether brief description of Ann. is given in index?', status: true, comment: '' },
//   { id: 8, defectCode: '08', description: 'Whether underline and high-lightings are deleted?', status: true, comment: '' },
//   { id: 9, defectCode: '09', description: 'Whether blanks are filed?', status: true, comment: '' },
//   { id: 10, defectCode: '10', description: 'Whether affidavit is filed in support of Petition / Appeal / Misc. Application / pleadings including all annexure and it is attested?', status: true, comment: '' },
//   { id: 11, defectCode: '11', description: 'Whether typed copy of a dim document is enclosed with the Petition / Appeal /Misc. Application / Pleadings?', status: true, comment: '' },
//   { id: 12, defectCode: '12', description: 'Whether advocate has filed a duly executed vakalatnama?', status: true, comment: '' },
//   { id: 13, defectCode: '13', description: 'Whether parties / counsel have filed 1 + 3 copies of Petition / Appeal / Misc. Application and all other Pleadings?', status: true, comment: '' },
//   { id: 14, defectCode: '14', description: 'Whether service report of Petition / Appeal / Misc. Application / pleadings has been filed?', status: true, comment: '' },
//   { id: 15, defectCode: '15', description: 'Whether required fee for the Petition / Appeal / Misc. Application / pleadings has been deposited?', status: true, comment: '' },
//   { id: 16, defectCode: '16', description: 'Whether correct pagination of the pleadings has been done?', status: true, comment: '' },
//   { id: 17, defectCode: '17', description: 'Whether application for urgent listing if any contains justification for such listing?', status: true, comment: '' },
//   { id: 18, defectCode: '18', description: 'Whether proper indexing and memo of parties with complete address has been filed with the Petition / Appeal/ Misc. Application?', status: true, comment: '' },
//   { id: 19, defectCode: '19', description: 'Whether the Petition / Appeal / Misc. Application / pleading bear the signature with date of the counsel/party?', status: true, comment: '' },
//   { id: 20, defectCode: '20', description: 'Whether english translation of a document which is submitted in a language other than english has been filed?', status: true, comment: '' },
//   { id: 21, defectCode: '21', description: 'If the Petition / Appeal / Misc. Application / pleading are time barred whether application for condonation of delay clearly mentioning the number of days of delay with affidavit has been field?', status: true, comment: '' },
//   { id: 22, defectCode: '22', description: 'Whether certified copy of the impugned judgment has been filed and if certified copy is not available, whether an application for exemption from filing certified copy has been filed?', status: true, comment: '' },
//   { id: 23, defectCode: '23', description: 'Whether valid authorization (i.e extract of board resolution) from the company or partners as the case may be has been filed?', status: true, comment: '' },
// ];

export async function getFileDefects(id, type) {
  return await instance({
    method: "get",
    url: `tdsat/legal-check/objections/?${type}=${id}`,
  });
}

export async function editFileDefects(data) {
  return await instance({
    method: "put",
    url: `tdsat/legal-check/objections/edit/`,
    data,
  });
}

export async function sendToOtherAdmin(data){
  return await instance({
    method: "post",
    url: `tdsat/legal-check/send-to-other-admin/`,
    data,
  })
}

export async function sendToPartyAsDefective(data){
  return await instance({
    method: "post",
    url: `tdsat/legal-check/party-as-defective/`,
    data,
  })
}

const AdminDefectTable = ({activeCase,setActiveCase,handleRegisterCase}) => {
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [open, setOpen] = useState(true);
  const [additionalComment, setAdditionalComment] = useState('');
  const [defectCount, setDefectCount] = useState(0);
  const [sendTo, setSendTo] = useState('');
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);

  const { showSnackbar } = useSnackbar();

  useEffect(()=>{
    setLoading(true)
    getFileDefects(activeCase?.id, activeCase?.type)
    .then((res)=>{
      const data = res?.data
      console.log('defects',data);
      setRows(data);
      const numberOfDefectsAdded = data.filter(item => item.status === false)?.length;
      setDefectCount(numberOfDefectsAdded);
      setLoading(false);
    })
    .catch((err)=>{
      console.log('File objection list is not fetched.',err);
      setLoading(false);
    })
  },[])

  const handleClickView = () => {
    setOpen(!open);
  }

  const handleToggleChange = (id) => (event) => {
    setRows(rows.map(row => row.id === id ? { ...row, status: event.target.value } : row));
    setDefectCount(event.target.value === 'true' ? defectCount - 1 : defectCount + 1);
  };

  const handleCommentChange = (id) => (event) => {
    setRows(rows.map(row => row.id === id ? { ...row, comments: event.target.value } : row));
  };
  
  const handleAdditionalCommentChange = (event) => {
    setAdditionalComment(event.target.value);
  }

  const handleClickSubmit = (event) => {
    event.preventDefault();
    setSubmittingForm(true);
    if(sendTo === ''){
      alert('Please select Send To before submit!');
      return;
    }
    editFileDefects(rows)
    .then((res)=>{
      if(res?.status === 200){
        const data = {
          type: activeCase?.type,
          id: activeCase?.id,
          send_to: sendTo,
          comment: comment,
          other_comment: additionalComment,
        }
        sendToOtherAdmin(data)
        .then((res)=>{
          console.log('Sent',res?.data);
          if(res.data?.status === 'success'){
            setActiveCase({
              ...activeCase,
              current_step: sendTo,
            })
          }
        })
      }
    })
  }

  const handleSendToPartyAsDefective = () => {
    setSubmittingForm(true);
    const data = {
      type: activeCase?.type,
      id: activeCase?.id,
    }
    sendToPartyAsDefective(data).then((res)=>{
      console.log(res?.data);
      if(res?.data?.status === 'success'){
        showSnackbar('File defects sent to party.','info');
      }
    })
  }

  const getSendToOptions = (step) => {
    switch(step){
      case 'dealing_assistant':
        return [
          {label: 'Desk Officer', value: 'desk_officer'},
          {label: 'Deputy Registrar', value: 'deputy_registrar'},
          {label: 'Registrar', value: 'registrar'},
        ]
      case 'desk_officer':
        return [
          {label: 'Deputy Registrar', value: 'deputy_registrar'},
          {label: 'Registrar', value: 'registrar'},
          {label: 'Dealing Assistant', value: 'dealing_assistant'},
        ]
      case 'deputy_registrar':
        return [
          {label: 'Registrar', value: 'registrar'},
          {label: 'Dealing Assistant', value: 'dealing_assistant'},
        ]
      case 'registrar':
        return [
          {label: 'Dealing Assistant', value: 'dealing_assistant'},
        ]
    }
  }

  const sendToOptions = getSendToOptions(activeCase?.current_step);

  return (
    <Container style={{padding:0}}>
      {/* <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Defect Tracking Table
        </Typography>
      </Box> */}
      <Box style={{padding: '15px', backgroundColor:'white', borderBottom:'2px solid #4B4E56'}}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid item >
            <Typography className={classes.defectTableHeading}>
              Total Defects {rows?.length}
            </Typography>
          </Grid>
          <Grid item >
            <Typography className={classes.defectTableHeading}>
              No. of Defects Added {defectCount}
            </Typography>
          </Grid>
          <Grid item  style={{cursor:'pointer'}}>
            <Typography onClick={handleClickView} className={classes.defectTableHeading} style={{display:'flex',gap:'5px'}}>
              <span>{open ? 'Collapse' : 'View All'}</span>
              <span>{open ? <ExpandLess /> : <ExpandMore />}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer component={Paper} style={{borderBottom:'1px solid #dad6d6'}} >
          <Table style={{maxWidth: '95%'}}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Defect Code</strong></TableCell>
                <TableCell><strong>Defect Description</strong></TableCell>
                <TableCell align="center"><strong>Status</strong></TableCell>
                <TableCell><strong>Comments</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <CircularCenteredLoader/>
              ):(
                rows?.length > 0 ? (
                  rows.map((row,index) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{(index+1 < 10 ? '0' : '') + (index+1)}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell align="center">
                        <TextField
                          variant='outlined'
                          size='small'
                          select
                          id={row?.id}
                          value={row?.status}
                          onChange={handleToggleChange(row.id)}
                          SelectProps={{
                            native: true,
                          }}
                          style={{width: 'max-content'}}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Type your comment (optional)"
                          value={row.comments}
                          onChange={handleCommentChange(row.id)}
                          style={{width: 'max-content'}}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ):(
                  <Typography>There is some glitch in getting objections!</Typography>
                )
              )}
            </TableBody>
          </Table>
          <Box className={classes.otherComment}>
            <Typography>Any other comment?</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Type your comment (optional)"
              value={additionalComment}
              onChange={handleAdditionalCommentChange}
              style={{width: 'max-content',flex:1}}
            />
          </Box>
        </TableContainer>
      </Collapse>
      <Box style={{backgroundColor:'white', padding:'2rem 5rem 4rem'}}>
        <Box className={classes.row}>
          <Typography className={classes.bold}>Notification Date:</Typography>
          <Typography>{format(new Date(),'dd/MM/yyyy')}</Typography>
        </Box>
        <Box className={classes.row}>
          <Typography className={classes.bold}>Status:</Typography>
          <Typography>{defectCount ? 'Defective' : 'Defect Free'}</Typography>
        </Box>
        <Box style={{height:'2rem'}}></Box>
        {activeCase?.current_step === 'dealing_assistant_after_review' ? (
          <>
            <Box style={{display:'flex',gap:'2rem'}}>
              <Button variant="contained" color='primary' fullWidth onClick={handleSendToPartyAsDefective} disabled={(defectCount === 0) || submittingForm || activeCase.current_step === 'party_as_defective'}>
                Send to party as defective
              </Button>
              <Button variant="contained" color='primary' fullWidth onClick={handleRegisterCase} disabled={(defectCount > 0) || submittingForm || activeCase.is_approved}>
                Register
              </Button>
            </Box>
          </>
        ):(
          <>
            <form onSubmit={handleClickSubmit}>
              <Box style={{display:'flex',gap:'2rem'}}>
                <TextField
                  required
                  variant='outlined'
                  size='small'
                  select
                  label='Send To'
                  placeholder='Send to -Select-'
                  value={sendTo}
                  onChange={(event)=> setSendTo(event.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  style={{width: '50%'}}
                >
                  <option>Send to -Select-</option>
                  {sendToOptions.map((item,index)=>{
                    return (
                      <option key={index} value={item.value}>{item.label}</option>
                    )
                  })}
                </TextField>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  label='Comment'
                  placeholder="Type your comment (optional)"
                  value={comment}
                  onChange={(event)=> setComment(event.target.value)}
                  style={{width: '50%'}}
                />
              </Box>
              <Box style={{height:'2rem'}}></Box>
              <Button type='submit' variant="contained" color='primary' fullWidth disabled={submittingForm}>
                Submit
              </Button>
            </form>
            
          </>
        )}  
      </Box>
      <Box style={{height:'3rem'}}></Box>
    </Container>
  );
};

export default AdminDefectTable;