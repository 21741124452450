import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import instance from "api/globalaxios";
import { format } from "date-fns";
import Tabs from "features/Tabs/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DateRangePickerTDSAT from "./DateRangePickerTDSAT";
import debounce from "lodash.debounce";
import Axios from "axios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getTdsatCases, getTdsatCasesBySearch, getTdsatCounts } from "./API/TdsatApi";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem',
		width: '100%',
    	boxSizing: 'border-box',
	},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
		padding: '5px 10px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	searchbar: {
		width: "-webkit-fill-available",
		color: "#828282",
		fontSize: "14px",
		outline: "none",
		padding: "0rem 1.5rem",
		height: "42px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			height: "34px",
			padding: "0rem 0.7rem",
		},
	},
	tabsContainerMain: {
		width:'100%',
		flexWrap: 'nowrap',
		[theme.breakpoints.down("sm")]: {
			flexWrap: 'wrap',
		},
	},
	selectedMainTab: {
		padding: "0.8rem 1.5rem",
		cursor: 'pointer',
		fontSize: '1.2rem',
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		color: theme.palette.primary.main,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
	unselectedMainTab: {
		fontSize: '1.2rem',
		padding: "0.8rem 1.5rem",
		cursor: "pointer",
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		borderBottom: '2px solid black',
	},
	tableHeadItem:{
		color: 'grey',
		fontSize: '15px',
		fontFamily: 'Satoshi-Bold',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '14px',
	},
	valuePaper: {
		padding: "1.5rem 0",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
  filtersContainer: {
    padding: '8px', 
    borderBottom: '1px solid black', 
    display: 'flex', 
    gap: '15px', 
    justifyContent:'center',
    width: '80%',
    margin: 'auto',
  },
}));

export default function PreviouslyFiledCases(){
	const classes = useStyles();
  const dispatch = useDispatch();
	const [previouslyFiledCases, setPreviouslyFiledCases] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [casesBySearch, setCasesBySearch] = useState([]);
  const [cancelToken, setCancelToken] = useState();
	const [loading, setLoading] = useState(false);
	const [activeTabMain, setActiveTabMain] = useState('main_cases');
  const [counts, setCounts] = useState({ main_cases: '--', applications: '--', documents: '--', caveats: '--', court_fees: '--'});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const debouceSearch = useCallback(debounce(search, 500), [previouslyFiledCases]);

  const spacing = [1,1,2,2,2,2,2];
  const tableHeadings = ['Sr. No.','Diary No.','Case Type','Case No.','Parties Name','Date of Filing','Registration Date'];
  const DASHBOARD = 'registered_cases';
  const PAGE_SIZE = 10;

  async function getPreviouslyFiledCases() {
    return await instance({
      method: "get",
      url: `tdsat/user-dashboard-previous/`,
    });
  }

  const processResponse = (data) => {
    const response = data;
    // const allPreviouslyFiledCases = Object.values(response).flat();
    const tempArr = response.map((item,index) => {
      const arr = [index+1, item.diary_number, item.case_type_new, item.case_number, item.name, format(new Date(item.case_creation_date_time), "dd/MM/y"), format(new Date(item.registration_date_time), "dd/MM/y")];
      return arr;
    })
    return tempArr;
  }

  const reload = () => {
		setLoading(true);
		getTdsatCases(PAGE_SIZE,page,DASHBOARD,activeTabMain)
    .then((res)=>{
      const response = res?.data?.results;
      const processedResponse = processResponse(response);
      setPreviouslyFiledCases(processedResponse);
      setCasesBySearch(processedResponse);
      let count = res?.data?.count;
      let t = parseInt(count / PAGE_SIZE);
      let r = count % PAGE_SIZE;
      let total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setLoading(false);
    })
  }

  useEffect(()=>{
    getTdsatCounts(DASHBOARD).then((res)=>{
      const count = res.data;
      console.log(count);
      setCounts(count);
    })
    // reload();
  },[])

  useEffect(()=>{
    reload();
  },[activeTabMain,page])

  useEffect(()=>{
		search(searchText);
  },[startDate,endDate])

  function search(value) {
		setLoading(true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    let x = Axios.CancelToken.source();
    setCancelToken(x);
    if (value.trim() != "" || (startDate && endDate)) {
      const start = startDate ? format(startDate,'dd-MM-yyyy') : startDate;
      const end = endDate ? format(endDate,'dd-MM-yyyy') : endDate;
      getTdsatCasesBySearch(DASHBOARD, activeTabMain, 1, PAGE_SIZE, value.trim(), x, start, end)
      .then((res)=>{
        setCasesBySearch(processResponse(res?.data?.results));
        setLoading(false);
      })
      .catch((error)=>{
        console.log('error',error);
      })
    } else {
      setCasesBySearch(previouslyFiledCases);
			setLoading(false);
    }
  }

  const mainTabs = [
		{
			id: "main_cases",
			label: "Main Case " + counts?.main_cases,
		},
		{
			id: "applications",
			label: "Application " + counts?.applications,
		},
		{
			id: "documents",
			label: "Document " + counts?.documents,
		},
		{
			id: "caveats",
			label: "Caveat " + counts?.caveats,
		},
    {
			id: "court_fees",
			label: "Court Fees " + counts?.court_fees,
		},
	];

  const handleMainTabChange = (newValue) => {
		setActiveTabMain(newValue);
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setPage(1);
	};

  const handlePageChange = (event,value) => {
    if(page === value){
      return;
    }
    setPage(value);
  }

    return (
			<Box className={classes.root}>
        <Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
					PREVIOUSLY FILED CASES
				</Typography>
        <Tabs
					tabs={mainTabs}
					handleClickTab={handleMainTabChange}
					choice={activeTabMain}
					tabsClasses={{
						selected: classes.selectedMainTab,
						unselected: classes.unselectedMainTab,
					}}
					tabsContainerClass={classes.tabsContainerMain}
				/>
				<Box style={{height:'2rem'}}/>
				<Box className={classes.filtersContainer}>
          <TextField
            size="small"
            style={{flex: 1}}
            variant="outlined"
            placeholder="Search by Party Name, Dairy No., Case No."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                debouceSearch(e.target.value);
              }}
              onBlur={(e) => {
                e.target.placeholder =
                  "Search by Party Name, Dairy No., Case No."
              }}
          />
          <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
        </Box>
				<Box style={{height:'1rem'}}/>
				<Box>
					<Box style={{height: '2rem'}}/>
					<Grid container style={{ rowGap: "8px" }}>
						<Box style={{padding:'1.5rem 0', width: '100%'}}>
							<Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
							</Grid>
						</Box>
						{loading ? (
							<Box style={{margin:'auto',width:'100%'}}>
							<CircularCenteredLoader/>
						</Box>
						) : (
							casesBySearch.length !== 0 ? (
								casesBySearch.map((dispute,index) => {
									return (
										<Box key={index} className={classes.valuePaper}>
											<Grid container spacing={2} justifyContent="flex-start">
												{dispute.map((item,index)=>{
													return (
														<Grid key={index} item xs sm={spacing[index]}>
															<Typography className={classes.tableRowValue}>
																{item}
															</Typography>
														</Grid>
													)
												})}
											</Grid>
										</Box>
									);
								})
							) : (
								<Grid container justifyContent="center">
									<Grid item>
										<Box textAlign="center">
											<Typography
												style={{
													fontFmaily: '"Inter","sans-serif"',
													fontWeight: 500,
													fontSize: "initial",
												}}
											>
												No case filed previously.
											</Typography>
										</Box>
									</Grid>
								</Grid>
							)
						)}
						{casesBySearch.length !== 0 && (
              <Box style={{padding:'16px'}}>
                <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
              </Box>
            )}
					</Grid>
				</Box>
			</Box>
    )
}