import React, { useState } from 'react';
import { Paper, Grid, TextField, Button, MenuItem, Select,FormHelperText, InputLabel, FormControl, makeStyles, Typography } from '@material-ui/core';

const ConnectTwoCase = ({classes, searchConnectResponse, searchResponse, handleConnectCase }) => {
  
  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} item direction='column'>
        <Typography className={classes.titleHeader}>Case to be connected</Typography>

        <div className={classes.advocateInfo}>
            <p className={classes.advocateInfoTitle}>
                <div style={{fontSize: '16px', fontWeight: 700, fontFamily: 'Satoshi-medium', marginBottom: '8px'}}>Main Case Details</div>
                <div style={{fontSize: '16px', fontWeight: 400}}>{searchResponse?.name}</div>
            </p>
            v
            <p className={classes.advocateInfoTitle}>
              <div style={{fontSize: '16px', fontWeight: 700, fontFamily: 'Satoshi-medium', marginBottom: '8px'}}>Connected Case Details</div>
              <div style={{fontSize: '16px', fontWeight: 400}}>{searchConnectResponse?.name}</div>
            </p>
        </div>

        <div className={classes.button}>
            <Button variant="contained" color="primary" type="submit" className={classes.button} onClick={handleConnectCase}>
                Submit
            </Button>
        </div>
      </Grid>
    </Paper>
  );
};

export default ConnectTwoCase;
