import { useState, useEffect } from "react";
import { getHeadNameUserCreated } from "../features/Tdsat/API/TdsatApi";

const useHeadNameChoices = () => {
  const [headNameChoices, setHeadNameChoices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCauseListHeaders = async () => {
      try {
        const response = await getHeadNameUserCreated();
        setHeadNameChoices(response?.data);
      } catch (error) {
        console.error("Error fetching causeList", error);
        setError(error);
      }
    };

    fetchCauseListHeaders();
  }, []);

  return { headNameChoices, error };
};

export default useHeadNameChoices;