import { Box, Button,  Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { PrintOutlined, Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import validateDateAndFormat from "utils/validateDateAndFormat";
import fileDownload from "js-file-download";
import { getPaymentReport, getReport, printPaymentReport, printReport } from "features/Tdsat/API/TdsatApi";
import DateRangePickerTDSAT from "features/Tdsat/DateRangePickerTDSAT";

const useStyles = makeStyles((theme)=>({
  filtersContainer: {
    padding: '1rem', 
    borderBottom: '1px solid black', 
    display: 'flex', 
    gap: '15px', 
    justifyContent:'center',
    // width: '80%',
    margin: 'auto',
  },
  buttonPrint: {
    borderRadius: '0.5rem',
    border: '1px solid #AAAFB9',
    background: '#fff',
    padding: '0.5rem 1rem',
  },
  tableHeadItem:{
		color: 'grey',
		fontFamily: 'Satoshi-Bold',
    wordBreak: 'break-word',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '0.9375rem',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
	},
	valuePaper: {
		padding: "1.5rem 8px",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}))

export default function PaymentReport() {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
	const [searchText, setSearchText] = useState('');

  const spacing = [2,2,2,2,2,2];
  const tableHeadings = ['Sr. No.','Case No.','Account Sr. No.','Recieved From','Amount (INR)','Date'];

  const processResponse = (data,index) => {
    const item = data;
    const arr = [
      index+1, 
      item?.case_number,  
			item?.receipt_number,
      item?.created_by,
      item?.amount || '-',
      validateDateAndFormat(item?.created_at,'dd/MM/yy'),
    ];
    return arr;
  }

  const reload = () => {
		setLoading(true);
		getPaymentReport(searchText,validateDateAndFormat(startDate,'dd-MM-yyyy'),validateDateAndFormat(endDate,'dd-MM-yyyy'))
    .then((res)=>{
      const response = res?.data?.error ? [] : res?.data || [];
      setCases(response);
      // const processedResponse = processResponse(response);
      // let count = res?.data?.count;
      // let t = parseInt(count / PAGE_SIZE);
      // let r = count % PAGE_SIZE;
      // let total = t + (r > 0 ? 1 : 0);
      // setTotalPages(total);
      setLoading(false);
    })
    .catch((error)=>{
      console.log('Error: ',error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    reload();
  },[])

  const handlePrintReport = () => {
    printPaymentReport(searchText,validateDateAndFormat(startDate, 'dd-MM-yyyy'),validateDateAndFormat(endDate, 'dd-MM-yyyy')).then((res)=>{
      // console.log(res);
      fileDownload(
        res.data,
        `Payment Report.pdf`
      );
    }).catch((error)=>{
      console.log(error);
    })
  }

  const handleClickSubmit = () => {
    if((startDate && endDate) || searchText){
      reload();
    } else {
      alert('Either enter case number or select From and To date.');
    }
  }

  return (
    <Box>
      <Typography style={{fontSize:'1.5rem', padding:'2rem'}}>
        PAYMENT REPORT
      </Typography>
      <Box style={{backgroundColor:'white'}}>
        <Box style={{height:'1rem'}}></Box>
        <Box className={classes.filtersContainer} style={{justifyContent:'space-between'}}>
          <TextField
            fullWidth
            variant="outlined" 
            size="small"
            style={{maxWidth:'28%'}}
            label='Search Case Number'
            placeholder="Search Case Number"
            value={searchText}
						onChange={(e)=> setSearchText(e.target.value)}
            InputProps={{
              endAdornment: <Search style={{color:'#858D98',cursor:'pointer'}}/>
            }}
          />
          <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
          <Button
            className={classes.buttonPrint}
            size="small"
            onClick={handleClickSubmit}
          >
            Submit
          </Button>
          <Button
            className={classes.buttonPrint}
            size="small"
            endIcon={<PrintOutlined/>}
            onClick={handlePrintReport}
          >
            Print Report
          </Button>
        </Box>
        <Box>
          <Box style={{height: '2rem'}}/>
          <Grid container style={{ rowGap: "8px" }}>
            <Box style={{padding:'1.5rem 8px', width: '100%'}}>
              <Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            {loading ? (
              <Box style={{margin:'auto',width:'100%',padding:'2rem 0'}}>
                <CircularCenteredLoader/>
              </Box>
            ) : (
              cases.length !== 0 ? (
                cases.map((dispute,index) => {
                  const processedDispute = processResponse(dispute,index);
                  return (
                    <Box key={index} className={classes.valuePaper}>
                      <Grid container spacing={2} justifyContent="flex-start">
                        {processedDispute.map((item,index)=>{
                          return (
                            <Grid key={index} item xs sm={spacing[index]}>
                              <Typography className={classes.tableRowValue}>
                                {item}
                              </Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Grid container justify="center">
                  <Grid item>
                    <Box textAlign="center">
                      <Typography
                        style={{
                          padding: '2rem 8px',
                          textAlign: 'center',
                          fontSize: '1.5rem'
                        }}
                      >
                        No payment report is found for this criteria.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
            {/* {cases.length !== 0 && (
              <Box style={{padding:'16px'}}>
                <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
              </Box>
            )} */}
          </Grid>
          <Box style={{height: '2rem'}}/>
          {(cases.length !== 0) && (
            <Box style={{display:'flex',width:'15rem',margin:'auto',gap:'1rem',paddingBottom:'1.5rem'}}>
              <Typography style={{width:'50%'}}>Total Amount</Typography>
              <Typography style={{width:'50%'}}>{cases.reduce((total, item) => total + item?.amount, 0) || '--'}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}