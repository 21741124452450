import React, {useState, useEffect} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';

const AdvocateSelection = ({classes, formData, handleInputChange, searchResponse, advocates}) => {
    const [errors, setErrors] = useState({
        petitionerAdvocate: '',
        respondentAdvocate: '',
    });
    
    
    // Filter advocates based on selections
    const filteredPetitionerAdvocates = advocates?.filter(advocate => advocate.id !== formData?.respondentAdvocate) || [];
    const filteredRespondentAdvocates = advocates?.filter(advocate => advocate.id !== formData?.petitionerAdvocate) || [];

    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
            <Typography className={classes.titleHeader}>{searchResponse?.main_case?.name || " "}</Typography>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="none" variant="outlined">
                                <Typography className={classes.label}>Petitioner’s Advocate:</Typography>
                                <Select
                                    name="petitionerAdvocate"
                                    value={formData.petitionerAdvocate}
                                    onChange={handleInputChange}
                                >
                                     {filteredPetitionerAdvocates?.map((advocate) => (
                                        <MenuItem key={advocate.id} value={advocate.id}>
                                            {advocate.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.petitionerAdvocate && <FormHelperText error>{errors?.petitionerAdvocate}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="none" variant="outlined">
                                <Typography className={classes.label}>Respondent’s Advocate:</Typography>
                                <Select
                                    name="respondentAdvocate"
                                    value={formData.respondentAdvocate}
                                    onChange={handleInputChange}
                                >
                                    {filteredRespondentAdvocates?.map((advocate) => (
                                        <MenuItem key={advocate.id} value={advocate.id}>
                                            {advocate.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.respondentAdvocate && <FormHelperText error>{errors.respondentAdvocate}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default AdvocateSelection;