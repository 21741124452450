// App.js (Main Component)
import React, { useState } from 'react';
import OrderTable from './OrderTable';
import OrderForm from './OrderForm';

function OrderCis() {
  const [isFilingNotice, setIsFilingNotice] = useState(false);

  const handleFileNewNotice = () => {
    setIsFilingNotice(true); 
  };

  const handleBackToTable = () => {
    setIsFilingNotice(false); 
  };

  const handleFormSubmit = () => {
    setIsFilingNotice(false);
  };

  return (
    <>
      {!isFilingNotice ? (
        <OrderTable onFileNewNotice={handleFileNewNotice} />
      ) : (
        <OrderForm onBack={handleBackToTable} onSubmit={handleFormSubmit} />
      )}
    </>
  );
}

export default OrderCis;
