import React, { useState } from 'react';
import { Box, OutlinedInput, Grid,  Button, Typography, Link, InputAdornment, makeStyles, IconButton } from '@material-ui/core';
import tdsatIcon from "images/tdsat_icon.svg";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {sendOtpAsync, setLogOut,
  setTokenAsync
} from "slices/loginSlice";
import instance from 'api/globalaxios';
import { CloudUpload, Delete, Visibility, VisibilityOff } from '@material-ui/icons';

const RequiredLabel = ({ label }) => (
  <Typography component="label" variant="body1">
    {label} <span style={{ color: 'red' }}>*</span>
  </Typography>
);

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    borderRadius: '12px',
    padding: '8px 18px 8px 18px'
  },
  unselectedButton: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '8px 18px 8px 18px',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  buttonPay: {
    padding: theme.spacing(1),
    borderRadius: 12,
    width: 220,
    display: 'flex',
  },
  labelTitle: {
    display: 'flex',
    marginBottom: '8px',
    fontSize: '12px'
  },
  labelSubtitle: {
    display: 'flex',
    marginTop: '8px',
    fontSize: '11px',
    color: '#858D98',
    marginBottom: '32px'
  },
  uploadFileWrap: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const SignupSchemaAdvocate = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  fullname: Yup.string().trim().required("Full Name Required"),
  mobileNumber: Yup.string().trim().required("Mobile Number Required"),
  barEnrollNumber: Yup.string().trim().required("Bar Enrollment Required"),
  password: Yup.string().trim().required("Password is Required"),
  file: Yup.mixed().required("File is Required").test(
    "fileSize",
    "File too large",
    value => value && value.size <= 10000000 // 10 MB
  ),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Confirm Password Required"),
});

const SignupSchemaPartyInPerson = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  fullname: Yup.string().trim().required("Full Name Required"),
  mobileNumber: Yup.string().trim().required("Mobile Number Required"),
  password: Yup.string().trim().required("Password is Required"),
  aadhar_number: Yup.string().trim().required("Aadhar Number is Required"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Confirm Password Required"),
});

const SignUp = ({ setCurrentStep, setOtpVerificationValues }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  const [userType, setUserType] = useState('advocate');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      fullname: "",
      mobileNumber: "",
      barEnrollNumber: "",
      aadhar_number: "",
      password: "",
      group: 'tdsat',
      profile_type: userType === 'advocate' ? "lawyer_rv" : "general_rv",
      file: null,
      userType: userType,
    },
    validationSchema: userType === 'advocate' ? SignupSchemaAdvocate: SignupSchemaPartyInPerson,
    onSubmit: async (values) => {
      let { email, fullname, mobileNumber} = values;
      try {
        const otpResponse = await dispatch(sendOtpAsync(email, fullname, mobileNumber));
        if (otpResponse.success) {
          setOtpVerificationValues(values);  
          setCurrentStep('signUpVerification');
        } else {
          // Handle OTP API failure
        }
      } catch (error) {
        // Handle error
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    formik.setFieldValue('file', file);
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    formik.setFieldValue('file', null);
    document.getElementById('file-upload').value = null; // Clear the input field
  };

  const handleUserTypeChange = (type) => {
    setUserType(type);
    formik.setFieldValue('userType', type);
    formik.setFieldValue('profile_type', type === 'advocate' ? 'lawyer_rv' : 'general_rv');
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Box mb={2}>
        <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} />
      </Box>
      <Typography variant="h5" component="h1">
        Sign Up
      </Typography>
      <Box mt={2} width="100%">
        <Typography variant="body1">
          You want to sign up as (an/a):
        </Typography>
        <Box display="flex" mt={3} mb={2} gap="1rem">
          <Button
             onClick={() => handleUserTypeChange('advocate')}
            className={formik.values.userType === 'advocate' ? classes.selectedButton : classes.unselectedButton}
            style={{marginRight: '1rem'}}
          >
            Advocate
          </Button>
          <Button
            onClick={() => handleUserTypeChange('partyInPerson')}
            className={formik.values.userType === 'partyInPerson' ? classes.selectedButton : classes.unselectedButton}
            >
            Party-in-Person
          </Button>
        </Box>
        {userType === 'advocate' && 
        <>
          <RequiredLabel id="barEnrollNumber" label={'Bar Enrollment Number'}/>
          <OutlinedInput
            margin="normal"
            fullWidth
            value={formik.barEnrollNumber}
            required
            id="barEnrollNumber"
            name="barEnrollNumber"
            placeholder="Type Here"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.barEnrollNumber && formik.touched.barEnrollNumber && (
            <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
              {formik.errors.barEnrollNumber}
            </Box>
          )}
        </>
        }
        <Box mt={2}/>
        <RequiredLabel id="fullname" label={'Full Name'}/>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.fullname}
          required
          id="fullname"
          name="fullname"
          placeholder="Full Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.fullname && formik.touched.fullname && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.fullname}
          </Box>
        )}
        <Box mt={2}/>
        <RequiredLabel id="email" label={'Email address'}/>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.email}
          required
          id="email"
          name="email"
          autoComplete="email"
          placeholder="Type your email address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startAdornment={<InputAdornment position="start">@</InputAdornment>}
        />
        {formik.errors.email && formik.touched.email && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.email}
          </Box>
        )}
        {hasErrors && apiErrors.email && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {apiErrors.email[0]}
          </Box>
        )}
        <Box mt={2}/>
        <RequiredLabel id="mobileNumber" label={'Mobile Number'}/>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.mobileNumber}
          required
          id="mobileNumber"
          name="mobileNumber"
          placeholder="Type your mobile number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.mobileNumber && formik.touched.mobileNumber && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.mobileNumber}
          </Box>
        )}
        <Box mt={2}/>
        <RequiredLabel id="password" label={'Password'}/>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.password}
          required
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          placeholder="Type your Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endAdornment= {
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formik.errors.password && formik.touched.password && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.password}
          </Box>
        )}
        <Box mt={2}/>
        <RequiredLabel id="confirmPassword" label={'Confirm Password'}/>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.confirmPassword}
          required
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Type your Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.confirmPassword}
          </Box>
        )}
        <Box mt={2}/>
         { userType === 'partyInPerson' && 
          <>
            <Grid>Your Adhaar Details</Grid>
            <Box mt={2} />
            <RequiredLabel id="aadhar_number" label={'Aadhaar Number'}/>
            <OutlinedInput
              margin="normal"
              fullWidth
              value={formik.aadhar_number}
              required
              id="aadhar_number"
              name="aadhar_number"
              placeholder="Type your Aadhaar number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.aadhar_number && formik.touched.aadhar_number && (
            <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
              {formik.errors.aadhar_number}
            </Box>
          )}
          </>
        }
        <Box mt={4} />
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start',flexDirection: 'column', marginTop: 18 }}>
          <Typography className={classes.labelTitle}>Upload your {userType === 'advocate' ? 'Bar Enrollment' : 'Aadhar Card'} ID:</Typography>
              <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-upload"
              />
          <label htmlFor="file-upload" style={{width: '220px'}}>
              <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.buttonPay}
                  startIcon={<CloudUpload/>}
                  disabled={!!selectedFile}
              >
                Upload File
              </Button>
          </label>
          {selectedFile && (
              <div className={classes.uploadFileWrap}>
                  <Typography variant="body2">
                      {selectedFile.name}
                  </Typography>
                  <IconButton color="primary" onClick={handleFileDelete}>
                      <Delete />
                  </IconButton>
              </div>
          )}
          <Typography className={classes.labelSubtitle}>(Please upload .pdf, .png, .jpeg files only. Max. size allowed 10 MB.)</Typography>
          {formik.errors.file && formik.touched.file && (
            <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
              {formik.errors.file}
            </Box>
          )}
        </Grid>
        <Box mt={2}/>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={formik.handleSubmit}
        >
          SIGN UP
        </Button>
        <Box mt={2} textAlign="center">
          <Link variant="body2" onClick={() => setCurrentStep('login')} style={{cursor: 'pointer'}}>
            {"Already have an account? Log in."}
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
