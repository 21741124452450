import React, {useEffect, useState} from 'react';
import { Typography, Divider, Box, makeStyles, Grid, Button } from '@material-ui/core';
import SearchCaseForm from '../shared/SearchCaseForm';
import SuccessResult from '../shared/SuccessResult';
import { getSearchFormDetailsMainCase, getSearchFormDetailsMainCaseByDiaryNumber, connectTwoCases, getConnectedCase, deleteConnectedCase, getSearchFormDetailsMainCaseConnected, getSearchFormDetailsMainCaseByDiaryNumberConnectedCase } from 'features/Tdsat/API/TdsatApi';
import { format } from 'date-fns';
import { useSnackbar } from 'contexts/SnackbarContext';
import Swal from 'sweetalert2';
import instance from 'api/globalaxios';
import ConnectedCasesList from './ConnectedCasesList';
import ConnectTwoCase from './ConnectTwoCase';

const useStyles = makeStyles((theme)=>({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
    borderRadius: '10px',
    marginTop:  theme.spacing(4),
  },
  titleHeader: {
    fontSize: '22px',
    color: '#4A3BFF',
    fontWeight: 700
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  rowItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  advocateInfo: {
    fontWeight: 500,
    marginBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  advocateInfoTitle: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '12px',
    padding: theme.spacing(1),
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  uploadButton: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersUploaded: {
    border: 'none',
    fontSize: '15px',
    // background: '#E8FFE2'
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const ConnectedCase = () => {
  const classes= useStyles();
  const [formData, setFormData] = useState({
    selectType: 'caseNumber',
  });

  const [formDataConnect, setFormConnectData] = useState({
    selectType: 'caseNumber',
  });
  const { showSnackbar } = useSnackbar();
  
  const [searchResponse, setSearchResponse] = useState();
  const [searchConnectResponse, setSearchConnectResponse] = useState();
  const [connectedCases, setConnectedCases] = useState([])
  const [connectedCaseResponse, setConnectedCaseResponse] = useState();

  const handleSearch = async (searchParams) => {
    try {
      const caseNumber = searchParams?.case_number;
      const diaryNumber = searchParams?.diary_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      let response;

      if (caseNumber) {
        response = await getSearchFormDetailsMainCaseConnected(caseNumber, year, caseType);
        if(response?.data){
          showSnackbar('Searched Successfully', 'success');
        }
      } else if (diaryNumber) {
        response = await getSearchFormDetailsMainCaseByDiaryNumberConnectedCase(diaryNumber, year);
        if(response?.data){
          showSnackbar('Searched Successfully', 'success');
        }
      } else {
        throw new Error("Either caseNumber or diaryNumber must be provided.");
      }
  
      setSearchResponse(response?.data)
    } catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
      console.error("Error searching case:", error);
    }
  };

  useEffect(() => {
    const fetchConnectedCases = async () => {
      try {
        const response = await getConnectedCase(searchResponse?.id);
        console.log(response,'response')
        setConnectedCases(response?.data);  
      } catch (error) {
        console.error("Error fetching connectedCases", error);
      }
    };

    if(searchResponse?.id){
      fetchConnectedCases();
    }
  }, [searchResponse?.id]);

  const handleSearchToConnectCase = async (searchParams) => {
    try {
      const caseNumber = searchParams?.case_number;
      const diaryNumber = searchParams?.diary_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      let response;

      if (caseNumber) {
        response = await getSearchFormDetailsMainCaseConnected(caseNumber, year, caseType);
        if(response?.data){
          showSnackbar('Searched Successfully', 'success');
        }
      } else if (diaryNumber) {
        response = await getSearchFormDetailsMainCaseByDiaryNumberConnectedCase(diaryNumber, year, caseType);
        if(response?.data){
          showSnackbar('Searched Successfully', 'success');
        }
      } else {
        throw new Error("Either caseNumber or diaryNumber must be provided.");
      }
  
      setSearchConnectResponse(response?.data)
    } catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
      console.error("Error searching case:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleInputChangeConnect = (e) => {
    const { name, value } = e.target;
    setFormConnectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  // Function to handle submit
  const handleConnectCase = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Construct the data to send to the API
    const data = {
      main_case_1: searchResponse?.id,
      main_case_2: searchConnectResponse?.id
    };

    try {
      // Call the API
      const response = await connectTwoCases(data);
      setConnectedCaseResponse(response?.data);
      if(response?.data){
        showSnackbar('Connected Successfully', 'success');
      }
    } catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
      console.error('Error connecting cases:', error);
    }
  };

  const handleDeleteConnectedCases = async (connectedCaseId) => {
    try {
      const response = await deleteConnectedCase(connectedCaseId);
      showSnackbar('Deleted successfully!', 'success');
    } catch (error) {
      showSnackbar('Error while deleting!', 'error');
    }
  }

  return (
    <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
       <Grid container alignItems="center" style={{justifyContent: 'center'}}>
          <Grid item>
            <Typography className={classes.titleTop}>CONNECT/DISCONNECT CASE</Typography>
          </Grid>
        </Grid>
        <SearchCaseForm classes={classes} formData={formData} handleInputChange={handleInputChange} handleSearch={handleSearch} />

        {searchResponse && <SuccessResult message={searchResponse?.name}/>}

        <SearchCaseForm classes={classes} formData={formDataConnect} handleInputChange={handleInputChangeConnect} handleSearch={handleSearchToConnectCase} isConnectedCase={true}/>

        {searchResponse && searchConnectResponse && <ConnectTwoCase classes={classes} formData={formData} handleInputChange={handleInputChange} searchConnectResponse={searchConnectResponse} searchResponse={searchResponse} handleConnectCase={handleConnectCase}/>}
        
        {connectedCaseResponse && <SuccessResult message={'Case connected successfully.'}/>}
 
        <ConnectedCasesList classes={classes} formData={formData} handleInputChange={handleInputChange} connectedCases={connectedCases} handleDeleteConnectedCases={handleDeleteConnectedCases}/>
    </Box>
  );
};

export default ConnectedCase;
