import React from "react";
import {
    Grid,
    Paper,
    TableContainer,
    TableCell,
    Table,
    TableRow,
    TableHead,
    TableBody,
} from '@material-ui/core';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const ConnectedCasesList = ({classes, connectedCases, handleDeleteConnectedCases}) => {
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.noBordersTitle}>Main Case No.</TableCell>
                                    <TableCell className={classes.noBordersTitle}>Connected Case No.</TableCell>
                                    <TableCell className={classes.noBordersTitle}>Status</TableCell>
                                    <TableCell className={classes.noBordersTitle}>Disconnect</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {connectedCases?.length > 0 ? (
                                    connectedCases?.map((row, index) => (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell className={classes.noBorders}>
                                            {row?.main_case_1?.name} - {row?.main_case_1?.id}
                                        </TableCell>
                                        <TableCell className={classes.noBorders}>
                                            {row?.main_case_2?.name} - {row?.main_case_2?.id}
                                        </TableCell>
                                        <TableCell className={classes.noBorders}>
                                          {row.status ? "Yes": "No"}
                                        </TableCell>
                                        <TableCell className={classes.noBorders}>
                                           <DeleteOutlineIcon style={{cursor: 'pointer'}} onClick={() =>  handleDeleteConnectedCases(row?.id)}/>
                                        </TableCell>
                                    </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                    <TableCell colSpan={4} className={classes.noBorders} align="center">
                                        No Connected Case
                                    </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ConnectedCasesList;