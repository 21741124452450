import React from 'react';
import { TextField } from '@material-ui/core';

const TimeInput = ({formData, handleInputChange}) => {

  return (
      <>
        <TextField
          label="From Time (Display In Cause List)"
          name="fromTime"
          type="time"
          value={formData?.fromTime}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 300 }} // 5 minutes step
          variant='outlined'
        />
      </>
  );
};

export default TimeInput;
