import { useState, useEffect } from "react";
import { getSubjectMatterChoices } from "../features/Tdsat/API/TdsatApi";

const useSubjectMatterChoices = () => {
  const [subjectMatterChoices, setSubjectMatterChoices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubjectMatterChoices = async () => {
      try {
        const response = await getSubjectMatterChoices();
        setSubjectMatterChoices(response?.data);
      } catch (error) {
        console.error("Error fetching subject matter", error);
        setError(error);
      }
    };

    fetchSubjectMatterChoices();
  }, []);

  return { subjectMatterChoices, error };
};

export default useSubjectMatterChoices;