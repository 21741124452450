import React from "react";
import Add from "@material-ui/icons/Add";
import { Delete, CloudUpload, AttachMoney } from "@material-ui/icons";
import { INDIAN_STATES } from "app/constants";
export const CONFIG_FIELD_INPUT_BASE = 'input';
export const CONFIG_FIELD_SELECT = 'select';
export const CONFIG_FIELD_RADIO = 'radio';
export const CONFIG_FIELD_CHECKBOX = 'checkbox';
export const CONFIG_FIELD_BUTTON = 'button';
export const CONFIG_FIELD_FILE = 'file';
export const CONFIG_FIELD_ROW = 'row';
export const CONFIG_FIELD_TEXT = 'text';
export const CONFIG_FIELD_TYPOGRAPHY = 'textField'

export const applicationFormConfig = {
  title: 'Application Filing',
  sections: [
    {
      fields: [
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Number', required: true },
            { type: CONFIG_FIELD_SELECT, label: 'Year', options: ['Select', '2021', '2022', '2023', '2024'] },
          ]
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Go' }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: 'Application Type', options: ['Select', 'Type 1', 'Type 2'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Petitioner/Appellant', options: ['Raghvendra Singh'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Respondent', options: ['Shantunu Singh'] }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Enter e-Court Fee Receipt No.' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Amount' },
        { type: CONFIG_FIELD_FILE, label: 'Pay Here', startIcon: <AttachMoney/>, subtitle: 'You will be redirected to the third-party payment application.' },
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_FILE, label: 'Upload Complete Application File' ,startIcon: <CloudUpload/>, subtitle: '(please upload only .pdf files. Max size allowed 10 MB)'},
        {
          type: CONFIG_FIELD_RADIO,
          label: 'It is certified that all the annexures are true copies of their respective original documents.',
          options: ['I agree']
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Submit'}
      ]
    }
  ]
};

export const personalInformationConfigs = {
  title: 'USER INFORMATION',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Mobile No.:', keyId: 'phone_no', required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'E-Mail Address', keyId: 'email', required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Alternate Phone No.:', keyId: 'alt_phone_no' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Address for Communication:', multiline: true, keyId: 'address' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Pin Code:', multiline: true, keyId: 'pin_code'},
        { type: CONFIG_FIELD_BUTTON, label: 'Save' }
      ]
    }
  ]
};

export const documentFormConfig = {
  title: 'Document Filing',
  sections: [
    {
      fields: [
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Number', required: true },
            { type: CONFIG_FIELD_SELECT, label: 'Year', options: ['Select', '2021', '2022', '2023'] },
          ]
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Go' }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: 'Application Type', options: ['Select', 'Type 1', 'Type 2'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Petitioner/Appellant', options: ['Raghvendra Singh'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Respondent', options: ['Shantunu Singh'] }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Enter e-Court Fee Receipt No.' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Amount' },
        { type: CONFIG_FIELD_FILE, label: 'Pay Here', startIcon: <AttachMoney/>, subtitle: 'You will be redirected to the third-party payment application.' },
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_FILE, label: 'Upload Complete Application File' ,startIcon: <CloudUpload/>, subtitle: '(please upload only .pdf files. Max size allowed 10 MB)'},
        {
          type: CONFIG_FIELD_RADIO,
          label: 'It is certified that all the annexures are true copies of their respective original documents.',
          options: ['I agree']
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Submit'}
      ]
    }
  ]
};

export const caveatConfig = {
  title: 'Caveat',
  sections: [
    {
      fields: [
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Number', required: true },
            { type: CONFIG_FIELD_SELECT, label: 'Year', options: ['Select', '2021', '2022', '2023'] },
          ]
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Go' }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: 'Application Type', options: ['Select', 'Type 1', 'Type 2'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Petitioner/Appellant', options: ['Raghvendra Singh'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Respondent', options: ['Shantunu Singh'] }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Enter e-Court Fee Receipt No.' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Amount' },
        { type: CONFIG_FIELD_FILE, label: 'Pay Here', startIcon: <AttachMoney/>, subtitle: 'You will be redirected to the third-party payment application.' },
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_FILE, label: 'Upload Complete Application File' ,startIcon: <CloudUpload/>, subtitle: '(please upload only .pdf files. Max size allowed 10 MB)'},
        {
          type: CONFIG_FIELD_RADIO,
          label: 'It is certified that all the annexures are true copies of their respective original documents.',
          options: ['I agree']
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Submit'}
      ]
    }
  ]
};

export const processFeesConfig = {
  title: 'Process Fees',
  sections: [
    {
      fields: [
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Number', required: true },
            { type: CONFIG_FIELD_SELECT, label: 'Year', options: ['Select', '2021', '2022', '2023'] },
          ]
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Go' }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: 'Application Type', options: ['Select', 'Type 1', 'Type 2'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Petitioner/Appellant', options: ['Raghvendra Singh'] },
        { type: CONFIG_FIELD_CHECKBOX, label: 'Respondent', options: ['Shantunu Singh'] }
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Enter e-Court Fee Receipt No.' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Amount' },
        { type: CONFIG_FIELD_FILE, label: 'Pay Here', startIcon: <AttachMoney/>, subtitle: 'You will be redirected to the third-party payment application.' },
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_FILE, label: 'Upload Complete Application File' ,startIcon: <CloudUpload/>, subtitle: '(please upload only .pdf files. Max size allowed 10 MB)'},
        {
          type: CONFIG_FIELD_RADIO,
          label: 'It is certified that all the annexures are true copies of their respective original documents.',
          options: ['I agree']
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Submit'}
      ]
    }
  ]
};

export const fileUploadScreenConfig = {
  title: 'File Upload',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Enter e-Court Fee Receipt No.' },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Amount' },
        { type: CONFIG_FIELD_FILE, label: 'Pay Here', startIcon: <AttachMoney/>, subtitle: 'You will be redirected to the third-party payment application.' },
      ]
    },
    {
      fields: [
        { type: CONFIG_FIELD_FILE, label: 'Upload Complete Application File' ,startIcon: <CloudUpload/>, subtitle: '(please upload only .pdf files. Max size allowed 10 MB)'},
        {
          type: CONFIG_FIELD_RADIO,
          label: 'It is certified that all the annexures are true copies of their respective original documents.',
          options: ['I agree']
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Submit'}
      ]
    }
  ]
};

export const memoOfPartiesConfig = {
  title: 'Memo of Parties',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: "Petitioner(s)/Appellant(s)", keyId: 'type', options: ["Select","Petitioner(s)","Appellant(s)"], required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Full Name', keyId: 'full_name', required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Father/Husband Name',keyId: 'father_or_husband_name', required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Mobile Number', multiline: true, keyId: 'phone_number', required: true},
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Alternate Mobile Number', multiline: true, keyId: 'alt_phone_number'},
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Correspondence Address', keyId: 'correspondence_address', required: true },
        { type: CONFIG_FIELD_SELECT, label: "State", keyId: 'state', options: INDIAN_STATES, required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'E-mail', multiline: true, keyId: 'email', required: true },
        { type: CONFIG_FIELD_BUTTON, label: 'Save'}
      ]
    }
  ]
};

export const memoOfPartiesConfigRight = {
  title: 'Memo of Parties',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_SELECT, label: "Respondent(s)",keyId: 'type', options: ["Respondent(s)"],disabled: true, required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Full Name', keyId: 'full_name',required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Father/Husband Name',keyId: 'father_or_husband_name',required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Mobile Number', multiline: true, keyId: 'phone_number',required: true},
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Alternate Mobile Number', multiline: true, keyId: 'alt_phone_number'},
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Correspondence Address', keyId: 'correspondence_address',required: true },
        { type: CONFIG_FIELD_SELECT, label: "State", keyId: 'state', options: INDIAN_STATES, required: true },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'E-mail', multiline: true, keyId: 'email',required: true },
        { type: CONFIG_FIELD_BUTTON, label: 'Save'}
      ]
    }   
  ]
};

export const filingDetailsConfig = {
  title: 'Filing Details',
  sections: [
    {
      fields: [
        {
          type: CONFIG_FIELD_RADIO,
          label: 'Is Government a Party?',
          options: ['Yes', 'No'],
          keyId: 'is_government_party',
          require: true,
        },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, keyId: 'case_year_connected', label: 'Connected Case Year', options: ['Select', '2021', '2022', '2023', '2024'] },
            { type: CONFIG_FIELD_INPUT_BASE, keyId: 'case_no_connected', label: 'Case No.' },
          ]
        },
        {
          type: CONFIG_FIELD_TYPOGRAPHY,
          label: 'Impugned Order Details', // The label for Typography field
          keyId: 'impugenedOrderDetails'
        },
        { type: CONFIG_FIELD_SELECT, keyId: 'impunged_order_direction_notice_connected', label: 'Impugned Order/Direction/Notice', options: ['Impugned Order', 'Direction', 'Notice'] },
        { type: CONFIG_FIELD_INPUT_BASE,keyId: 'decision_date', label: 'Date of Impugned Order, Direction or Notice', required: true },
        { type: CONFIG_FIELD_SELECT, keyId: 'court_org_name_connected', label: 'Court Name OR Name of the Organisation', options: ['TRAI', 'DoT', 'UIDAI', 'Cyber', 'Advocate', 'Firm'] },
        { type: CONFIG_FIELD_INPUT_BASE, keyId: 'case_no_impunged_order_direction', label: 'Case No. of the Impugned Order/Direction/Notice',  required: true },
      ]
    }
  ]
};

export const impugenedOrderConfig = {
  title: 'Impugened Details',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Court Name' },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Type'},
            { type: CONFIG_FIELD_SELECT, label: 'Case No of', options: ['TBC'] },
          ]
        },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Decision Date'},
            { type: CONFIG_FIELD_SELECT, label: 'Decison', options: ['TBC'] },
          ]
        },
      ]
    }
  ]
}

export const actsConfig = {
  title: 'Acts Details',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Act' },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Type'},
            { type: CONFIG_FIELD_SELECT, label: 'Name', options: ['TBC'] },
          ]
        },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Remarks' },
        { type: CONFIG_FIELD_BUTTON, label: 'Add', backgroundColor: '#EEF2F5', color: '#000000', icon: <Add/> },
      ]
    }
  ]
}

export const matterConfig = {
  title: 'Acts Details',
  sections: [
    {
      fields: [
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Connected Case Types' },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case no'},
            { type: CONFIG_FIELD_INPUT_BASE, label: 'Case Year' },
          ]
        },
        { type: CONFIG_FIELD_INPUT_BASE, label: 'Judge Name' },
        {
          type: CONFIG_FIELD_ROW,
          fields: [
            { type: CONFIG_FIELD_INPUT_BASE, label: 'NDOH'},
            { type: CONFIG_FIELD_SELECT, label: 'Pending/Disposed', options: ['TBC'] },
          ]
        },
        { type: CONFIG_FIELD_BUTTON, label: 'Add', backgroundColor: '#EEF2F5', color: '#000000', icon: <Add/> },
      ]
    }
  ]
}