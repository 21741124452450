import React, {useState} from 'react';
import { Box, TextField, Button, Typography, Link, Paper, makeStyles, Grid } from '@material-ui/core';
import tdsatIcon from "images/tdsat_icon.svg";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import * as Yup from "yup";
import instance from 'api/globalaxios';
import { setLogOut } from 'slices/loginSlice';
import { gethost } from 'slices/disputeSlice';

const useStyles = makeStyles((theme) => ({
    subTitleOtp: {
        fontSize: '14px',
        textAlign: 'center'
    },
    otpInput: {
        width: '50px',
        height: '50px',
        margin: '0 5px',
        textAlign: 'center',
        fontSize: '24px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
          textAlign: 'center',
          padding: '8px 0',
        },
    },
}));

const OtpVerificationSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required'),
});

const signupTdsat = async (email, fullname, mobileNumber, barEnrollNumber, password, group, profile_type, combinedOtp, file, aadhar_number, userType) => {
    try{
        const formData = new FormData();
        formData.append('user', JSON.stringify({
            email: email,
            password: password,
            first_name: fullname,
        }));
        formData.append('phone_number', mobileNumber);
        formData.append('barEnrollNumber', barEnrollNumber);
        formData.append('password', password);
        formData.append('group', group);
        formData.append('profile_type', profile_type);
        formData.append('otp', combinedOtp);
        formData.append('aadhar_number', aadhar_number);
        if (file) {
        formData.append('file', file);
        }
        const response = await instance.post(`/signup_tdsat?host=${gethost()}`, formData);
        console.log(response,'response')
        return response.data;
    }
    catch(error){
        console.log(error);
        if (error.request.status === 400) {
          throw error.request.response;
        }
    }
  };


const SignupVerification = ({ setCurrentStep, otpVerificationValues }) => {
    const classes = useStyles();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const { state: routeState } = useLocation();

    const history = useHistory();
    const [error, setError] = React.useState();
    const formik = useFormik({
      initialValues: {
        otp: ""
      },
    //   validationSchema: OtpVerificationSchema,
      onSubmit: async (values) => {
        try {
            let combinedOtp = otp.join('');
            const { email, fullname, mobileNumber, barEnrollNumber, password, group, profile_type, file, aadhar_number, userType } = otpVerificationValues;
            const response = await signupTdsat(email, fullname, mobileNumber, barEnrollNumber, password, group, profile_type, combinedOtp, file, aadhar_number, userType);
            if (response) {
                dispatch(setLogOut());
                if (routeState) {
                    history.push(`${routeState.from.pathname}`);
                } else {
                    history.push("/");
                }
            } else {
                // Handle signup failure
            }
            } catch (error) {
            // Handle error
            }
        },
    });
  
    const dispatch = useDispatch();

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
          if (value !== "" && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
          }
        }
    };

    return (
        <>
            <Box mb={2}>
            <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} />
            </Box>
            <Typography variant="h5" component="h1">
                Verify OTP
            </Typography>
            <Box mt={4} />
            <Typography className={classes.subTitleOtp}>
                We have sent an OTP to your registered email address and  mobile number. (OTP valid for 10 minutes only.)
            </Typography>
            <Box mt={2} width="100%" component="form" onSubmit={formik.handleSubmit}>
            <Grid style={{display: 'flex', justifyContent: 'center'}}>
                {otp.map((value, index) => (
                    <TextField
                        key={index}
                        id={`otp-${index}`}
                        variant="outlined"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        inputProps={{ maxLength: 1 }}
                        className={classes.otpInput}
                    />
                ))}
            </Grid>
            <Box mt={4} />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                style={{borderRadius: '12px'}}
            >
                Submit OTP
            </Button>
            <Box mt={2} textAlign="center">
                <Link href="#" variant="body2" onClick={() => setCurrentStep('signUp')}>
                {"Back"}
                </Link>
            </Box>
            </Box>
        </>
    );
};

export default SignupVerification;
