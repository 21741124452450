import React, { useState } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Typography, Box, makeStyles } from '@material-ui/core';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import { useSnackbar } from 'contexts/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: '2rem 5.5rem',
    // maxWidth: '35rem',
    width: '100%',
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontFamily: 'Satoshi-Bold',
    // marginBottom: '16px',
    color: 'Black'
  },
  textField: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  sendTo: {
    marginTop: '16px',
    marginBottom: '16px',
    borderBottom: '1px solid black',
    paddingBottom: '8px',
    textAlign: 'left',
  },
  button: {
    marginTop: '24px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  headingCourtFee: {
    fontSize: '1.125rem',
    width: '50%',
  },
  detailsRow: {
    display: 'flex',
    gap: '1rem',
    textAlign: 'left',
  }
}));

export async function checkFinancialStatus(url, data) {
  return await instance({
    method: "post",
    url: url,
    data: data,
  });
}

function getType(type) {
  switch(type) {
    case 'main_cases':
      return 'main_case';
    case 'applications':
      return 'application';
    case 'documents':
      return 'document';
    case 'caveats':
      return 'caveat';
    default:
      return 'Unknown Type';
  }
}

const CourtFeeDetailsForm = ({activeCase, setActiveCase, feeReceipts}) => {
  const classes = useStyles(); // Use custom styles
  const [paymentVerification, setPaymentVerification] = useState('Complete');
  const [deficitAmount, setDeficitAmount] = useState('');
  const {id, type, name, diary_number, current_step, case_creation_date_time} = activeCase;
  const { showSnackbar } = useSnackbar();
  const handlePaymentChange = (event) => {
    setPaymentVerification(event.target.value);
  };

  const handleDeficitChange = (event) => {
    setDeficitAmount(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let url;
    let data = {};
  
    if (paymentVerification === 'Complete') {
      url = '/tdsat/financial-check/dealing-asistant/';
      data = {
        // paymentVerification,
        [type]: id,
      };
    } else {
      url = '/tdsat/financial-check/deficit-court-fees/';
      data = {
        // paymentVerification,
        amount: deficitAmount, 
        [getType(type)]: id
      };
    }
    try {
      const response = await checkFinancialStatus(url, data);
      console.log('Form submitted successfully:', response);
      if(response.data?.status === 'success'){
        console.log(response.data?.status);
        setActiveCase({
          ...activeCase,
          current_step: 'dealing_assistant',
          is_payment_complete: true,
        })
        showSnackbar('Payment verified successfully.','success');
      }
      if(response.data?.id){
        setActiveCase({
          ...activeCase,
          current_step: 'party_as_defective',
        })
        showSnackbar('Defecit in Court fee sent to party.','info');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  

  return (
    <Box component="form" onSubmit={handleSubmit} className={classes.formContainer}>
      <Typography variant="h6" className={classes.header}>
        Court Fee Details
      </Typography>
      <Box style={{height:'2rem'}}/>
      <Box style={{display:'flex',gap:'1rem',flexDirection:'column'}}>
        <Box className={classes.detailsRow}>
          <Typography className={classes.headingCourtFee}>
            Parties' Name: 
          </Typography>
          <Typography className={classes.headingCourtFee}>
            {name} 
          </Typography>
        </Box>
        <Box className={classes.detailsRow}>
          <Typography className={classes.headingCourtFee}>
            Diary Number: 
          </Typography>
          <Typography className={classes.headingCourtFee}>
            {diary_number}
          </Typography>
        </Box>
        {feeReceipts?.length > 0 && (
          feeReceipts.map((item,index)=>{
            return (
              <Box key={index}>
                <Box className={classes.detailsRow}>
                  <Typography className={classes.headingCourtFee}>
                    Payment Id {index+1}: 
                  </Typography>
                  <Typography className={classes.headingCourtFee}>
                    {item?.receipt_number || '--'}
                  </Typography>
                </Box>
                <Box className={classes.detailsRow}>
                  <Typography className={classes.headingCourtFee}>
                    Date of Payment: 
                  </Typography>
                  <Typography className={classes.headingCourtFee}>
                    {item?.created_at ? format(new Date(item?.created_at), "dd/MM/yyyy"):''}
                  </Typography>
                </Box>
              </Box>
            )
          })
        )}
        <Box className={classes.detailsRow}>
          <Typography className={classes.headingCourtFee}>
            Date of Filing: 
          </Typography>
          <Typography className={classes.headingCourtFee}>
            {case_creation_date_time ? format(new Date(case_creation_date_time), "dd/MM/yyyy"):''}
          </Typography>
        </Box>
      </Box>
      <Box style={{height:'3rem'}}/>
      <Typography style={{textAlign:'left',fontWeight:'bold'}}>Payment verification *</Typography>
      <RadioGroup value={paymentVerification} onChange={handlePaymentChange} className={classes.radioGroup}>
        <FormControlLabel value="Complete" control={<Radio />} label="Complete" />
        <FormControlLabel value="Incomplete" control={<Radio />} label="Incomplete" />
      </RadioGroup>
      {paymentVerification === 'Incomplete' && (
        <TextField
          variant='outlined'
          label="Add Deficit Amount (If it is incomplete)"
          value={deficitAmount}
          onChange={handleDeficitChange}
          fullWidth
          margin="normal"
          required
          className={classes.textField}
        />
      )}
      <Typography variant="body1" className={classes.sendTo}>
        <span style={{width:'8rem',fontWeight:'bold',display:'inline-block'}}>Send To:</span> {paymentVerification === 'Complete' ? 'Dealing Assistant' : 'Party as Defective'}
      </Typography>

      <Button type="submit" variant="contained" fullWidth className={classes.button} disabled={current_step === 'party_as_defective'}>
        Send
      </Button>
    </Box>
  );
};

export default CourtFeeDetailsForm;
