import { Box, Breadcrumbs, Button, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { KeyboardBackspace, NavigateNext, PrintOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DateRangePickerTDSAT from "../DateRangePickerTDSAT";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getWeededOutList, printWeedingOutList } from "../API/TdsatApi";
import validateDateAndFormat from "utils/validateDateAndFormat";
import fileDownload from "js-file-download";

const useStyles = makeStyles((theme)=>({
  filtersContainer: {
    padding: '1rem', 
    borderBottom: '1px solid black', 
    display: 'flex', 
    gap: '15px', 
    justifyContent:'center',
    // width: '80%',
    margin: 'auto',
  },
  buttonPrint: {
    borderRadius: '0.5rem',
    border: '1px solid #AAAFB9',
    background: '#fff',
    padding: '0.5rem 1rem',
  },
  tableHeadItem:{
		color: 'grey',
		fontFamily: 'Satoshi-Bold',
    wordBreak: 'break-word',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '0.9375rem',
    wordBreak: 'break-word',
	},
	valuePaper: {
		padding: "1.5rem 8px",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}))

export default function WeedingOutReport({handleGoBack}) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [weedingOutList, setWeedingOutList] = useState([]);

  const spacing = [1,2,3,3,3];
  const tableHeadings = ['Sr. No.','Case No.','Date of Weeding','Disposal Date','Reason for Weeding'];

  const processResponse = (data,index) => {
    const item = data;
    const arr = [
      index+1, 
      item?.main_case?.case_number,  
      validateDateAndFormat(item?.date_of_weeding,'dd/MM/yy'), 
      validateDateAndFormat(item?.disposal_date,'dd/MM/yy'), 
      item?.weeding_reason, 
    ];
    return arr;
  }

  const reload = () => {
		setLoading(true);
		getWeededOutList(validateDateAndFormat(startDate,'dd-MM-yyyy'),validateDateAndFormat(endDate,'dd-MM-yyyy'))
    .then((res)=>{
      const response = res?.data?.results;
      setWeedingOutList(response);
      // const processedResponse = processResponse(response);
      // let count = res?.data?.count;
      // let t = parseInt(count / PAGE_SIZE);
      // let r = count % PAGE_SIZE;
      // let total = t + (r > 0 ? 1 : 0);
      // setTotalPages(total);
      setLoading(false);
    })
    .catch((error)=>{
      console.log('Error: ',error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    if((startDate && endDate) || (!startDate && !endDate)){
      reload();
    }
  },[startDate,endDate])

  const handlePrintReport = () => {
    printWeedingOutList(validateDateAndFormat(startDate, 'dd-MM-yyyy'),validateDateAndFormat(endDate, 'dd-MM-yyyy')).then((res)=>{
      // console.log(res);
      fileDownload(
        res.data,
        `WeedingOutReport_${validateDateAndFormat(startDate, 'dd-MM-yyyy')}_to_${validateDateAndFormat(endDate, 'dd-MM-yyyy')}.pdf`
      );
    }).catch((error)=>{
      console.log(error);
    })
  }

  return (
    <Box>
      <Box style={{display:'flex',paddingBottom:'1.5rem',gap:'10px'}}>
        <Typography style={{cursor:'pointer'}} onClick={()=>{handleGoBack('weeding_out')}}>
          <KeyboardBackspace/>
        </Typography>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" style={{cursor:'pointer'}} onClick={()=>{handleGoBack('weeding_out')}}>
            Weeding Out
          </Link>
          <Typography color="textPrimary">{'Generate Report'}</Typography>
        </Breadcrumbs>
      </Box>
      <Typography style={{fontSize:'1.5rem', padding:'2rem'}}>
        WEEDING OUT
      </Typography>
      <Box style={{backgroundColor:'white'}}>
        <Box style={{height:'1rem'}}></Box>
        <Box className={classes.filtersContainer} style={{justifyContent:'space-between'}}>
          <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
          <Button
            className={classes.buttonPrint}
            size="small"
            endIcon={<PrintOutlined/>}
            onClick={handlePrintReport}
          >
            Print Report
          </Button>
        </Box>
        <Box>
          <Box style={{height: '2rem'}}/>
          <Grid container style={{ rowGap: "8px" }}>
            <Box style={{padding:'1.5rem 8px', width: '100%'}}>
              <Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            {loading ? (
              <Box style={{margin:'auto',width:'100%',padding:'2rem 0'}}>
                <CircularCenteredLoader/>
              </Box>
            ) : (
              weedingOutList.length !== 0 ? (
                weedingOutList.map((dispute,index) => {
                  const processedDispute = processResponse(dispute,index);
                  return (
                    <Box key={index} className={classes.valuePaper}>
                      <Grid container spacing={2} justifyContent="flex-start">
                        {processedDispute.map((item,index)=>{
                          return (
                            <Grid key={index} item xs sm={spacing[index]}>
                              <Typography className={classes.tableRowValue}>
                                {item}
                              </Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Grid container justify="center">
                  <Grid item>
                    <Box textAlign="center">
                      <Typography
                        style={{
                          padding: '2rem 8px',
                          textAlign: 'center',
                          fontSize: '1.5rem'
                        }}
                      >
                        No case weeded out.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
            {/* {weedingOutList.length !== 0 && (
              <Box style={{padding:'16px'}}>
                <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
              </Box>
            )} */}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}