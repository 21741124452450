import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getHeadNameChoices, getHeadNameUserCreated } from '../API/TdsatApi';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  
}));

const HeadOfCauseListInput = ({
  headOfListing, 
  onChange, 
  isRequired=false, 
  isLabel = true, 
  label = 'Head Of Listing', 
  name = "headOfListing", 
  size = 'medium',
  error = null,
}) => {
  const classes = useStyles();

  const [headNameChoices, setHeadNameChoices] = useState([]);

  useEffect(() => {
    const fetchCauseListHeaders = async () => {
      try {
        const response = await getHeadNameUserCreated();
        setHeadNameChoices(response?.data);  
      } catch (error) {
        console.error("Error fetching causeList", error);
      }
    };

    fetchCauseListHeaders();
  }, []);
  
  return (
    <>
      <FormControl required={isRequired} fullWidth margin="none" variant="outlined" size={size}>
        {isLabel && <Typography className={classes.label}>{label}</Typography>}
        <Select
          name={name}
          value={headOfListing}
          onChange={onChange}
        >
          <MenuItem>Select</MenuItem>
          {headNameChoices?.map((item, index)=>{
            return (
              <MenuItem key={index} value={item?.id}>{item?.name}</MenuItem>
            )
          })}
        </Select>
        {error && (
          <FormHelperText style={{color:'red', margin:0}}>{error}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default HeadOfCauseListInput;
