import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  Grid,
  FormHelperText
} from '@material-ui/core';
import {
    CONFIG_FIELD_INPUT_BASE,
    CONFIG_FIELD_SELECT,
    CONFIG_FIELD_RADIO,
    CONFIG_FIELD_CHECKBOX,
    CONFIG_FIELD_BUTTON,
    CONFIG_FIELD_FILE,
    CONFIG_FIELD_ROW,
    CONFIG_FIELD_TEXT,
    CONFIG_FIELD_TYPOGRAPHY
  } from './formConfigs';

const FormField = ({ field, value, onChange, onClick, disabled=false, error }) => {
  const handleChange = (event) => {
    onChange(field.keyId, event.target.value);
  };
  // console.log('err',error)
    switch (field.type) {
      case CONFIG_FIELD_INPUT_BASE:
        return (
          <TextField
            label={field.label}
            fullWidth
            required={field.required}
            multiline={field.multiline || false}
            rows={field.rows || 1}
            margin="none"
            variant="outlined"
            value={value}
            onChange={handleChange}
            error={error}
            helperText={error ? error : ""}
            disabled={disabled}
          />
        );
      case CONFIG_FIELD_SELECT:
        return (
          <FormControl required={field.required} fullWidth margin="none" variant='outlined' error={error}>
            <InputLabel>{field.label}</InputLabel>
            <Select label={field.label} onChange={handleChange} value={value} disabled={disabled}>
              {field.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText>{error}</FormHelperText>
            )}
          </FormControl>
        );
      case CONFIG_FIELD_RADIO:
        return (
          <FormControl component="fieldset" margin="normal" required={field.required} error={error}>
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup row onChange={handleChange} value={value}>
              {field.options.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
            {error && (
              <FormHelperText>{error}</FormHelperText>
            )}
          </FormControl>
        );
      case CONFIG_FIELD_CHECKBOX:
        return (
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{field.label}</FormLabel>
            {/* {field.subTitle && <Typography>{field.subTitle}</Typography>} */}
            {field.options.map((option, index) => (
              <FormControlLabel key={index} control={<Checkbox />} label={option} />
            ))}
          </FormControl>
        );
      case CONFIG_FIELD_BUTTON:
        return (
          <Button onClick={onClick} variant="contained" startIcon={field.startIcon} endIcon={field.icon} color="primary" fullWidth sx={{ my: 2 }} style={{background: field.backgroundColor, color: field.color}} disabled={disabled}>
            {field.label}
          </Button>
        );
      case CONFIG_FIELD_FILE:
        return (
          <Box margin="normal">
            <Button startIcon={field.startIcon} variant="contained" component="label" style={{background: "#4A3BFF", color: '#ffffff', display: 'flex', justifyContent: 'flex-start'}}>
              {field.label}
              <input type="file" hidden />
            </Button>
            <Typography variant="caption" display="flex">{field.subtitle}</Typography>
          </Box>
        );
      case CONFIG_FIELD_ROW:
      return (
        <Grid container spacing={2} style={{borderBottom: '1px solid #858D98', paddingBottom: '30px', marginBottom: '22px'}}>
          <Typography style={{fontWeight: 'bold'}}>Please enter details of the connected matter (if any).</Typography>
          {field.fields.map((subField, index) => (
            <Grid item xs={6} key={index}>
              <FormField field={subField}  value={value[subField.keyId]} onChange={onChange} error={error[subField.keyId]}/>
            </Grid>
          ))}
        </Grid>
      );
      case CONFIG_FIELD_TEXT:
        return (
          <Grid container spacing={2}>
            <Typography style={{marginRight:'60px'}}>{field.label}</Typography>
            <Typography>{field.value}</Typography>
          </Grid>
        );
      case CONFIG_FIELD_TYPOGRAPHY:
        return (
          <Grid container spacing={2} style={{marginBottom:"10px"}}>
            <Typography style={{fontWeight: 'bold'}}>Impugned Order Details: </Typography>
          </Grid>
        );
      default:
        return null;
    }
  };


export default FormField;