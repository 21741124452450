import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import CustomDialogTdsat from "./CustomDialogTdsat";
import { getStatus } from "./TdsatContainer";
import PayBharatKosh from "./components/PayBharatKosh";
import UploadFileTdsat from "./components/UploadFileTdsat";
import instance from "api/globalaxios";
import { createReciepts } from "./ApplicationFillingForm";
import Swal from "sweetalert2";

function getUploadType(type) {
  switch(type) {
    case 'main_cases':
      return 'MainCase';
    case 'applications':
      return 'Application';
    case 'documents':
      return 'Document';
    case 'caveats':
      return 'Caveat';
    default:
      return 'Unknown Type';
  }
}

function getRecieptContentType(type) {
  switch(type) {
    case 'main_cases':
      return 'main_case';
    case 'applications':
      return 'application';
    case 'documents':
      return 'document';
    case 'caveats':
      return 'caveat';
    default:
      return 'Unknown Type';
  }
}

export async function getDeficitCourtFee(type, defectId) {
  return await instance({
    method: "get",
    url: `/tdsat/financial-check/deficit-court-fees/?${type}=${defectId}`,
  });
}

function MainCaseRow({
  classes,
  dispute,
  index,
  spacing,
  IS_ADMIN,
  handleClickOnRow,
  activeTabMain,
  activeTab,
}) {
  const [open, setOpen] = useState(false);
  const [eRecieptData, setERecieptData] = useState({content_type: getUploadType(dispute?.type)});
  const [errors,setErrors] = useState({})
  const [deficitFeeData, setDeficitFeeData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && dispute?.is_defective && !dispute?.is_payment_complete ) {
      async function fetchDeficitCourtFee() {
        try {
          const response = await getDeficitCourtFee(getRecieptContentType(dispute?.type), dispute?.id);
          setDeficitFeeData(response.data);
        } catch (error) {
          console.error("Error fetching deficit court fee:", error);
        }
      }
      fetchDeficitCourtFee();
    }
  }, [open, dispute?.id]);

  const data = [
    index + 1,
    dispute.diary_number,
    dispute.case_type_new,
    dispute.name,
    format(new Date(dispute.case_creation_date_time), "dd/MM/y"),
    IS_ADMIN ? (
      <div style={{backgroundColor:'aliceblue'}}>{getStatus(dispute?.current_step)}</div>
    ) : activeTab === "scrutiny" ? (
      "Pending"
    ) : (
      "Under Objection"
    ),
    activeTab === "defective" && (
      <IconButton
        color="primary"
        size="small"
        style={{ padding: "5px 10px" }}
        onClick={handleOpen}
      >
        {dispute?.defects?.length}
      </IconButton>
    ),
  ];

  function handleModalDefect(){
    {dispute?.is_defective && !dispute?.is_payment_complete && 
      createReciepts(eRecieptData)
    }
    Swal.fire({
      title: "Defect",
      text: "Rectified Successfully",
      icon: "success"
    });
    setOpen(false);
  }

  return (
    <>
      <Box key={index} className={classes.valuePaper} onClick={IS_ADMIN ? () => handleClickOnRow(dispute.id, activeTabMain) : ''}>
        <Grid key={index} container spacing={2} justifyContent="flex-start">
          {data.map((item, index) => {
            return (
              <Grid key={index} item xs sm={spacing[index]}>
                <Typography className={classes.tableRowValue}>
                  {item}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <CustomDialogTdsat open={open} onClose={handleClose} title="View Defects">
        <Typography className={classes.defectheading}>Defects</Typography>
        <Box className={classes.defectsContainer}>
          {dispute.defects.map((defect, index) => (
            <>
              <Typography className={classes.defectitemheading}>
                Defect {index + 1}: {defect.heading}
              </Typography>
              <Typography className={classes.defectitemdescription}>
                {defect.description}
              </Typography>
              {index < dispute.defects.length - 1 && (
                <Box style={{ margin: "10px 0" }}>
                  <Divider />
                </Box>
              )}
            </>
          ))}
        </Box>
        <Box mt={1}>
          {dispute?.is_defective && !dispute?.is_payment_complete &&
            <PayBharatKosh
              classes={classes}
              amount={deficitFeeData?.[deficitFeeData.length - 1]?.amount}
              errors={errors}
              setERecieptData ={setERecieptData} 
              eReceiptData={eRecieptData}
              applicationResponse={dispute}
            />
          }
          {dispute?.is_defective && dispute?.is_payment_complete &&
            <UploadFileTdsat
              classes={classes} 
              // formData={formData}
              // handleInputChange={handleInputChange}
              // setFormData={setFormData}
              applicationResponse={dispute}
              uploadType={getUploadType(dispute?.type)}
              errors={errors}
              isAgreeNeeded={false}
            />
          }
        </Box>
        <Box mt={1}>
          <Button variant="contained" color="primary" onClick={handleModalDefect}>Submit</Button>
        </Box>
      </CustomDialogTdsat>
    </>
  );
}

export default MainCaseRow;
