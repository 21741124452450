import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper
} from '@material-ui/core';
import FormField from './FormField';
import {
  CONFIG_FIELD_BUTTON,
    personalInformationConfigs,
} from './formConfigs';
import { updateProfile } from 'slices/profileSlice';
import { useDispatch } from 'react-redux';

const KeyValueDisplay = ({ data }) => {
    return (
      <Paper elevation={3} sx={{ maxWidth: 600, mx: 'auto' }}  style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '500px', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
        <Grid container spacing={2}>
          {Object.entries(data).map(([key, value], index) => (
            <React.Fragment key={index}>
              <Grid item xs={7}>
                <Typography variant="h6" align="left" sx={{ fontWeight: 'bold' }} style={{color: '#000000'}}>
                  {key}:
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6" align="left">
                  {value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
    );
  };

const PersonalInformation = ({profile}) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    email: profile?.user?.email,
    phone_no: profile?.phone_number,
    alt_phone_no: profile?.alternate_phone_number,
    address: profile?.address,
    pin_code: profile?.postal_code,
  })
  
  const handleFormValuesChange = (keyId, value) => {
    setFormValues(prevState => ({
      ...prevState,
      [keyId]: value
    }));
  };

  const DATA = {
    'Name': `${profile?.user?.first_name} ${profile?.user?.last_name}`,
    'Bar Council Enrollment No.': profile.bar_enrollment,
    'Login ID': profile.id,
  }

  const saveUserInformation = () => {
    const data = {
      id: profile.id,
      phone_number: formValues['phone_no'],
      address: formValues['address'],
      alternate_phone_number: formValues['alt_phone_no'],
      postal_code: formValues['pin_code'],
      user: {
        email: formValues['email'],
      },
    };
    dispatch(updateProfile(data)).then((res) => {
      console.log(res);
    });
  }

  const isButtonDisabled = () => {
    if(formValues.email === '' || formValues.phone_no === ''){
      return true;
    }
    else{
      return false;
    }
  }

    return (
      <Box component="form" sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
        <Box style={{height:'1.5rem'}}/>
        <Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
          {personalInformationConfigs.title}
        </Typography>
        <KeyValueDisplay data={DATA}/>
        {personalInformationConfigs.sections.map((section, sectionIndex) => (
          <Paper key={sectionIndex} sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '500px', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
            <Grid container spacing={2}>
              {section.fields.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <FormField 
                    field={field} 
                    onChange={handleFormValuesChange} 
                    value={formValues[field.keyId]} 
                    onClick={field.type === CONFIG_FIELD_BUTTON ? saveUserInformation : undefined}
                    disabled={field.type === CONFIG_FIELD_BUTTON ? isButtonDisabled() : undefined}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
      </Box>
    );
  };
  
  export default PersonalInformation;