import React from "react";
import TdsatSuccessIcon from '../../images/tdsatSuccess.svg';
import { Typography, Grid, Box } from "@material-ui/core";
import { formatDate } from "utils";

const SuccessScreenTdsat = ({diaryNumber, date, isApplicationRedirection = false, setSelected}) => {
    const handleApplicationPage =() => {
        setSelected(6);
    }
    return(
        <Box sx={{ flexGrow: 1 }}>
           <img src={TdsatSuccessIcon}/>
           <Grid justify="center" container style={{marginTop: '60px', marginBottom: '10px'}}>
                <Typography variant="h1">Diary Number :&nbsp;</Typography>
                <Typography variant="h1">{diaryNumber}</Typography>
           </Grid>
           <Grid justify="center" container>
                <Typography variant="h1">Date & Time :&nbsp;</Typography>
                <Typography variant="h1">{formatDate(date)}</Typography>
           </Grid>
           <Typography style={{marginTop:'30px', fontSize: '16px', color: '#4B4E56'}}>Please note this diary number for future reference.</Typography>
           {isApplicationRedirection ? <Typography style={{marginTop:'30px', fontSize: '16px', color: '#4B4E56'}}>Whether any Application is to be submitted with the Main Case ? &nbsp;<span style={{color: '#4A3BFF', cursor: 'pointer'}} onClick={handleApplicationPage}>Click Here </span></Typography> : null}
        </Box>
    )
}

export default SuccessScreenTdsat