import { Box, Button, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import instance from "api/globalaxios";
import { format } from "date-fns";
import Tabs from "features/Tabs/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import Axios from "axios";
import { useDispatch } from "react-redux";
import DateRangePickerTDSAT from "./DateRangePickerTDSAT";
import { useHistory } from "react-router-dom";
import MainCaseRow from "./MainCaseRow";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { CASE_MANAGER } from "app/constants";
import { getTdsatCases, getTdsatCasesBySearch, getTdsatCounts } from "./API/TdsatApi";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
		padding: '5px 10px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	searchbar: {
		width: "-webkit-fill-available",
		color: "#828282",
		fontSize: "14px",
		outline: "none",
		padding: "0rem 1.5rem",
		height: "42px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			height: "34px",
			padding: "0rem 0.7rem",
		},
	},
	tabsContainer: {
    border: '1px solid #858D98',
    width: 'fit-content',
    padding: '0.125rem',
    borderRadius: '0.5rem',
    // [theme.breakpoints.down("sm")]: {
    //   width: '100%',
    //   flexWrap: 'wrap',
    // },
  },
  selected: {
    padding: "0.5rem 1.25rem",
    cursor: 'pointer',
    fontFamily: 'Satoshi-Medium',
    minWidth: 'fit-content',
    textAlign: 'center',
    borderRadius: '0.5rem',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  unselected: {
    padding: "0.5rem 1.25rem",
    cursor: "pointer",
    fontFamily: 'Satoshi-Medium',
    minWidth: 'fit-content',
    textAlign: 'center',
    borderRadius: '0.5rem',
  },
	tabsContainerMain: {
		width:'100%',
		flexWrap: 'nowrap',
		[theme.breakpoints.down("sm")]: {
			flexWrap: 'wrap',
		},
	},
	selectedMainTab: {
		padding: "0.8rem 1.5rem",
		cursor: 'pointer',
		fontSize: '1.2rem',
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		color: theme.palette.primary.main,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
	unselectedMainTab: {
		fontSize: '1.2rem',
		padding: "0.8rem 1.5rem",
		cursor: "pointer",
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		borderBottom: '2px solid black',
	},
  filtersContainer: {
    padding: '8px',
    borderBottom: '1px solid black',
    display: 'flex',
    gap: '15px',
    justifyContent:'center',
    width: '90%',
    margin: 'auto',
  },
	tableHeadItem:{
		color: 'grey',
		fontSize: '15px',
		fontFamily: 'Satoshi-Bold',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '14px',
	},
	valuePaper: {
		padding: "1.5rem 0",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
    backgroundColor: 'white',
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
  defectsContainer:{
    maxHeight: "300px",
    overflowY: "scroll",
    padding : "20px 30px",
    '&::-webkit-scrollbar': {
      width: '7px',
      background: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#858D98',
      borderRadius : "20px"
    },
  },
  defectheading:{
    color: '#242529',
		fontSize: '18px',
    fontWeight: 700,
    marginTop: '20px'
  },
  defectitemheading:{
    color: '#242529',
		fontSize: '16px',
    fontWeight: 700,
    lineHeight : "25px"
  },
  defectitemdescription:{
    color: '#7C7C7C',
		fontSize: '12px',
    fontWeight: 500,
    lineHeight : "18px"
  },

}));

export default function MainCaseInbox({profile,handleClickOnRow}){
	const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
	const [cases, setCases] = useState([]);
  const [counts, setCounts] = useState({ main_cases: '--', applications: '--', documents: '--', caveats: '--', court_fees: '--'});
	const [activeTabMain, setActiveTabMain] = useState('main_cases');
  const [activeTab, setActiveTab] = useState('scrutiny');
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [casesBySearch, setCasesBySearch] = useState([]);
  const [cancelToken, setCancelToken] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(sessionStorage.getItem("page")) || 1);

  const IS_DEFECTIVE = activeTab === 'defective';
  const IS_ADMIN = profile?.profile_type === 'sub_admin' || profile?.profile_type === 'secretariat' || profile?.profile_type === CASE_MANAGER || profile?.profile_type === 'sub_admin_tiac_rv';
  const DASHBOARD = 'filing_done_up_to_date';
  const PAGE_SIZE = 10;

  const debouceSearch = useCallback(debounce(search, 500), [cases]);

  const spacing = IS_DEFECTIVE ? [1,2,2,2,2,2,1] : [1,2,2,3,2,2];
  const tableHeadings = ['Sr. No.','Diary No.','Case Type','Parties Name','Date of Filing','Status',IS_DEFECTIVE?'View Defects':null];

  // async function getCounts() {
  //   return await instance({
  //     method: "get",
  //     url: "tdsat/user-dashboard-count/",
  //   });
  // }

  // async function getFilingDoneUptoDate() {
  //   return await instance({
  //     method: "get",
  //     url: `tdsat/user-dashboard/?type=${activeTabMain}` + (!IS_ADMIN ? `&state=${activeTab}` : ''),
  //   });
  // }

  const reload = () => {
    setLoading(true);
    getTdsatCases(PAGE_SIZE,page,DASHBOARD,activeTabMain, (!IS_ADMIN? activeTab : null))
    .then((res)=>{
      setCases(res?.data?.results);
      setCasesBySearch(res?.data?.results);
      let count = res?.data?.count;
      let t = parseInt(count / PAGE_SIZE);
      let r = count % PAGE_SIZE;
      let total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setLoading(false);
    })
  }

  useEffect(()=>{
    getTdsatCounts(DASHBOARD).then((res)=>{
      const count = res.data;
      console.log(count);
      setCounts(count);
    })

  },[])

  useEffect(()=>{
    reload();
  },[activeTabMain,activeTab,page])

  useEffect(()=>{
    search(searchText);
  },[startDate,endDate])

  function search(value) {
    setLoading(true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    let x = Axios.CancelToken.source();
    setCancelToken(x);
    if (value.trim() != "" || (startDate && endDate)) {
      const start = startDate ? format(startDate,'dd-MM-yyyy') : startDate;
      const end = endDate ? format(endDate,'dd-MM-yyyy') : endDate;
      getTdsatCasesBySearch(DASHBOARD, activeTabMain, 1, PAGE_SIZE, value.trim(), x, start, end, (!IS_ADMIN? activeTab : null))
      .then((res)=>{
        setCasesBySearch(res?.data?.results);
        setLoading(false);
      })
      .catch((error)=>{
        console.log('error',error);
      })
    } else {
      setCasesBySearch(cases);
      setLoading(false);
    }
  }

	const tabs = [
		{
			id: "scrutiny",
			label: "Scrutiny",
		},
		{
			id: "defective",
			label: "Defective",
		},
	];

	const mainTabs = [
		{
			id: "main_cases",
			label: "Main Case " + counts?.main_cases,
		},
		{
			id: "applications",
			label: "Application " + counts?.applications,
		},
		{
			id: "documents",
			label: "Document " + counts?.documents,
		},
		{
			id: "caveats",
			label: "Caveat " + counts?.caveats,
		},
    {
			id: "court_fees",
			label: "Court Fees " + counts?.court_fees,
		},
	];

	const handleTabChange = (newValue) => {
		setActiveTab(newValue);
	};

  const handleMainTabChange = (newValue) => {
		setActiveTabMain(newValue);
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    sessionStorage.setItem("page", 1);
	};

  const handlePageChange = (event,value) => {
    if(page === value){
      return;
    }
    setPage(value);
    sessionStorage.setItem("page", value);
  }

    return (
			<Box className={classes.root}>
				<Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
					{IS_ADMIN ? 'SCRUTINY' : 'FILING DONE UP TO DATE'}
				</Typography>
				<Tabs
					tabs={mainTabs}
					handleClickTab={handleMainTabChange}
					choice={activeTabMain}
					tabsClasses={{
						selected: classes.selectedMainTab,
						unselected: classes.unselectedMainTab,
					}}
					tabsContainerClass={classes.tabsContainerMain}
				/>
        <Box style={{height:'2rem'}}/>
				<Box className={classes.filtersContainer}>
          <TextField
            size="small"
            style={{flex: 1}}
            variant="outlined"
            placeholder="Search by Party Name, Diary No."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                debouceSearch(e.target.value);
              }}
              onBlur={(e) => {
                e.target.placeholder =
                  "Search by Party Name, Diary No."
              }}
            InputProps={{
              endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
            }}
          />
          <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
          {!IS_ADMIN && (
            <Tabs
              tabs={tabs}
              handleClickTab={handleTabChange}
              choice={activeTab}
              tabsClasses={{
                selected: classes.selected,
                unselected: classes.unselected,
              }}
              tabsContainerClass={classes.tabsContainer}
            />
          )}
				</Box>
				<Box style={{height:'1rem'}}/>
				<Box>
					<Box style={{height: '2rem'}}/>
					<Grid container style={{ rowGap: "8px" }}>
						<Box style={{padding:'1.5rem 0', width:'100%'}}>
							<Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
							</Grid>
						</Box>
						{loading ? (
              <Box style={{margin:'auto', width:'100%'}}>
                <CircularCenteredLoader/>
              </Box>
            ) : (
              (casesBySearch.length !== 0) ? (
                casesBySearch.map((dispute,index) => {
                  return (
                    <MainCaseRow
                      classes={classes}
                      dispute={dispute}
                      index={index}
                      spacing={spacing}
                      IS_ADMIN={IS_ADMIN}
                      handleClickOnRow={handleClickOnRow}
                      activeTabMain={activeTabMain}
                      activeTab={activeTab}
                    />
                  );
                })
              ) : (
                <Grid container justifyContent="center">
                  <Grid item>
                    <Box textAlign="center">
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "initial",
                        }}
                      >
                        No Application/Document filed previously
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
            {casesBySearch.length !== 0 && (
              <Box style={{padding:'16px'}}>
                <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
              </Box>
            )}
					</Grid>
				</Box>
			</Box>
    )
}
