import React from 'react';
import { Box, InputAdornment, Button, Typography, Link, OutlinedInput } from '@material-ui/core';
import tdsatIcon from "images/tdsat_icon.svg";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import instance from 'api/globalaxios';

export function forgotPasswordTdsat(email) {
    return async (dispatch) => {
      try {
        const response = await instance.post(
            "/api/password_reset_by_otp/generate/",
            {email},
          );
        console.log(response,'response')
        return response.data;
      } catch (error) {
        console.log(error);
        if (error.request.status === 400) {
          throw error.request.response;
        }
      }
    };
}

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email Required"),
});

const ForgotPassword = ({ setCurrentStep }) => {

    const history = useHistory();
    const [error, setError] = React.useState();
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: ForgotPasswordSchema,
      onSubmit: async (values) => {
        let result = await dispatch(forgotPasswordTdsat(values.email));
        if (result.status === "OK") {
            localStorage.setItem('email', values.email);
            setCurrentStep("otpVerification")
        } else {
            setError(result.reason);
        }   
      },
    });
  
    const dispatch = useDispatch();
  return (
    <>
        <Box mb={2}>
            <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} />
        </Box>
        <Typography variant="h5" component="h1">
            Forgot Password?
        </Typography>
        <Box mt={2} width="100%">
            <Typography id="email">Email address</Typography>
            <OutlinedInput
                margin="normal"
                fullWidth
                value={formik.email}
                required
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Type your email address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={<InputAdornment position="start">@</InputAdornment>}
            />
            {formik.errors.email && formik.touched.email && (
                <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
                {formik.errors.email}
                </Box>                
            )}
            {error && 
                <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
                    {error}
                </Box>   
            }
            <Box mt={4} />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={formik.handleSubmit}
                style={{borderRadius: '12px'}}
            >
                Generate OTP
            </Button>
            <Box mt={2} textAlign="center">
                <Link href="#" variant="body2" onClick={() => setCurrentStep('login')}>
                {"Back"}
                </Link>
            </Box>
        </Box>
    </>
  );
};

export default ForgotPassword;
