import React from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paperSuccess: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 600,
        marginBottom: theme.spacing(4),
        borderRadius: '10px',
        borderLeft: '14px solid #4A3BFF'
    },
}));

const SuccessResult = ({ message, subMessage }) => {
    const classes = useStyles();
  return (
    <Paper className={classes.paperSuccess}>
      <Typography
        variant="h6"
        style={{
          color: '#3f3dff',
          fontWeight: '600',
        }}
      >
        {message}
      </Typography>
      <Typography
        variant="h6"
        style={{
          color: '#3f3dff',
          fontWeight: '600',
        }}
      >
      {subMessage}
      </Typography>
    </Paper>
  );
};

export default SuccessResult;
