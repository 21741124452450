// App.js (Main Component)
import React, { useState } from 'react';
import NoticeForm from './NoticeForm'; 
import NoticeTable from './NoticeTable';

function NoticeCis() {
  const [isFilingNotice, setIsFilingNotice] = useState(false);

  const handleFileNewNotice = () => {
    setIsFilingNotice(true); 
  };

  const handleBackToTable = () => {
    setIsFilingNotice(false); 
  };

  const handleFormSubmit = () => {
    setIsFilingNotice(false);
  };

  return (
    <>
      {!isFilingNotice ? (
        <NoticeTable onFileNewNotice={handleFileNewNotice} />
      ) : (
        <NoticeForm onBack={handleBackToTable} onSubmit={handleFormSubmit} />
      )}
    </>
  );
}

export default NoticeCis;
