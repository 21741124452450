import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Paper,
  makeStyles,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox, 
  FormHelperText
} from '@material-ui/core';
import instance from 'api/globalaxios';
import PayBharatKosh from './components/PayBharatKosh';
import UploadFileTdsat from './components/UploadFileTdsat';
import MainCaseDetails from './components/MainCaseDetails';
import PetitionerRespondentUI from './components/PetitionerRespondentUI';
import { getPartyDetails, getCaseTypes } from './ApplicationFillingForm';
import SuccessScreenTdsat from './SuccessScreenTdsat';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: 12,
    fontSize: 22,
  },
  buttonPay: {
    padding: theme.spacing(1),
    borderRadius: 12,
    width: '50%',
    display: 'flex',
  },
  labelTitle: {
    display: 'flex',
    marginBottom: '8px',
    fontSize: '12px'
  },
  labelSubtitle: {
    display: 'flex',
    marginTop: '8px',
    fontSize: '11px',
    color: '#858D98',
    marginBottom: '32px'
  },
  amount: {
    fontSize: '18px',
  },
  titleCase: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 700,
    display: 'flex'
  },
  nameTitle: {
    fontSize: '18px',
    lineHeight: '31.32px',
    fontWeight: 400,
    display: 'flex'
  },
  containerCaseInfo: {
    marginBottom: '18px'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerUser: {
    marginTop: '18px'
  },
  titleUserInfo: {
    display: 'flex',
    color: '#000',
    fontSize: '16px'
  },
  partyName: {
    fontSize: '18px',
    fontWeight: '400'
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const DocumentFilingForm = ({draftCase}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    case_number: draftCase ? draftCase?.case_number : '',
    year: draftCase ? draftCase?.year : '',
    document_type: '',
    claimant_parties: [],
    respondent_parties: [],
    any_other_document_type: '',
    // e_court_fee_receipt_number: '',
    agree: false,
    is_draft: true,
    main_case: draftCase ? draftCase?.main_case : '', 
    selectType: 'caseNumber',
    diary_number: ''
  });

  const [errors, setErrors] = useState({});

  const [partyDetails, setPartyDetails] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentResponse, setDocumentResponse] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [diaryNumber, setDiaryNumber] = useState(null);
  const [draftDate, setDraftDate] = useState(null);
  const [amount, setAmount] = useState(null);

  useEffect(()=>{
    if(draftCase){
      setDocumentResponse(draftCase);
      handleClaimantRespondentData(formData);
    }
  },[])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleOtherDocumentChange = (event) => {
    setFormData({
      ...formData,
      any_other_document_type: event.target.value
    });
  };

  // console.log(formData,'formData')
  const validateForm = () => {
    let tempErrors = {};
    if (!formData.document_type) tempErrors.document_type = 'This field is required';
    if (formData.claimant_parties.length === 0 && formData.respondent_parties.length === 0) {
      tempErrors.claimant_parties = 'At least one field is required';
      tempErrors.respondent_parties = 'At least one field is required';
    } else if (formData.claimant_parties.length > 0 && formData.respondent_parties.length > 0) {
        tempErrors.claimant_parties = 'Only one field can be filled';
        tempErrors.respondent_parties = 'Only one field can be filled';
    }
    if (!formData.agree) tempErrors.agree = 'You must agree before submitting';
    return tempErrors;
  };

  const handleDocumentChange = (e) => {
    handleInputChange(e);

    const { value } = e.target;
    const selectedApplication = documentTypes?.find(app => app.id === value);

    setAmount(selectedApplication?.amount || 0);
  };

  const createApplication = async () => {
    const identifier = formData.case_number 
    ? { case_number: formData.case_number, year: formData.year }
    : { diary_number: formData.diary_number, year: formData.year };
    try{
      const response = await instance.post(`/tdsat/documents/`, identifier)
      setDocumentResponse(response?.data)
      setFormData((prevData) => ({
        ...prevData,
        main_case: response?.data?.main_case,
      }));
    }catch(error){
      console.error("There was an error fetching the case data!", error);
    }
  }

  const handleClaimantRespondentData = async (formData) => {
    let url = '';
    if (formData?.case_number) {
      url = `/tdsat/api/maincaseretrieve/?case_number=${formData.case_number}&year=${formData.year}`;
    } else if (formData?.diary_number) {
      url = `/tdsat/api/maincaseretrieve/?diary_number=${formData.diary_number}&year=${formData.year}`;
    } else {
      throw new Error('Please provide either a case number or a diary number.');
    }
    try {
      const response = await instance.get(url);
      const { claimant_parties, respondent_parties } = response.data;
      const allParties = [...claimant_parties, ...respondent_parties];
      if (allParties.length > 0) {
        const partyDetailsResponse = await getPartyDetails(allParties);
        setPartyDetails(partyDetailsResponse);  
        const caseTypes = await getCaseTypes('Document');   
        setDocumentTypes(caseTypes);
        if(!draftCase){
          createApplication();
        }
      }
    } catch (error) {
      alert('No Main Case found')
      console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmitApplication = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const updatedFormData = { ...formData, is_draft: false };
      try{
        const response = await instance.patch(`/tdsat/documents/${documentResponse?.id}`, updatedFormData)
        setDiaryNumber(response?.data?.diary_number);
        setDraftDate(response?.data?.draft_creation_date_time)
        setIsSuccess(true);
      }catch(error){
        console.error("There was an error fetching the case data!", error);
      }
    }else {
      setErrors(formErrors);
    }
  }
  
  return (
    <>
      {isSuccess ? 
        <SuccessScreenTdsat diaryNumber={diaryNumber} date={draftDate}/>
        :
        <Box component="form" className={classes.form}>
          <Typography variant="h4" align="center" className={classes.title}>
            Document Filing
          </Typography>
          <MainCaseDetails
            classes={classes}
            formData={formData}
            handleInputChange={handleInputChange}
            handleClaimantRespondentData={handleClaimantRespondentData}
            partyDetails={partyDetails}
          />
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="none" variant="outlined">
                  <InputLabel>{'Document Type'}</InputLabel>
                  <Select
                    label={'Document Type'}
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleDocumentChange}
                  >
                    {documentTypes?.map((application, index) => (
                      <MenuItem key={index} value={application?.id}>
                        {application?.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.document_type && <FormHelperText style={{color: 'red'}}>{errors.document_type}</FormHelperText>}
                </FormControl>
              </Grid>
              {formData.document_type == '55' && (
                <TextField
                  fullWidth
                  label="Please specify other document type"
                  name="any_other_document_type"
                  value={formData.any_other_document_type}
                  onChange={handleOtherDocumentChange}
                  margin="normal"
                  variant="outlined"
                />
              )}
            {partyDetails?.length > 0 && 
                <PetitionerRespondentUI 
                  data={partyDetails}
                  handleInputChange={handleInputChange}
                  formData={formData}
                  setFormData={setFormData}
                  classes={classes}
                  errors={errors}
                />
              }
            </Grid>
          </Paper>
          {/* <PayBharatKosh
            classes={classes}
            formData={formData}
            handleInputChange={handleInputChange}
            amount={amount}
            errors={errors}
          /> */}
          <UploadFileTdsat
            classes={classes} 
            formData={formData}
            handleInputChange={handleInputChange}
            setFormData={setFormData}
            applicationResponse={documentResponse}
            uploadType={'Document'}
            errors={errors}
          />
          <Grid className={classes.submitButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonPay}
              onClick={()=>handleSubmitApplication(formData)}
            >
              Submit 
            </Button>
          </Grid>
        </Box>
      }
    </>
  );
};

export default DocumentFilingForm;
