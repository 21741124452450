import React, { useState } from 'react';
import 'react-nice-dates/build/style.css';
import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateRangeOutlined } from '@material-ui/icons';

export default function DateRangePickerTDSAT({startDate, setStartDate, endDate, setEndDate}) {

  return (
    // <DateRangePicker
    //   startDate={startDate}
    //   endDate={endDate}
    //   onStartDateChange={(setStartDate)}
    //   onEndDateChange={setEndDate}
    //   // minimumDate={new Date()}
    //   maximumDate={new Date()}
    //   minimumLength={1}
    //   format='dd-MM-yyyy'
    //   locale={enGB}
    // >
    //   {({ startDateInputProps, endDateInputProps, focus }) => (
    //     <div className='date-range' style={{padding:0, display: 'flex', gap: '15px'}}>
    //       <TextField
    //         className={'input' + (focus === START_DATE ? ' -focused' : '')}
    //         {...startDateInputProps}
    //         variant='outlined'
    //         size='small'
    //         placeholder='Start date'
    //         style={{maxWidth: '10rem'}}
    //         inputProps={{
    //           style: {width:'auto'}
    //         }}
    //       />
    //       <TextField
    //         className={'input' + (focus === START_DATE ? ' -focused' : '')}
    //         {...endDateInputProps}
    //         variant='outlined'
    //         size='small'
    //         placeholder='End date'
    //         style={{maxWidth: '10rem'}}
    //         inputProps={{
    //           style: {width:'auto'}
    //         }}
    //       />
    //     </div>
    //   )}
    // </DateRangePicker>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box style={{display:'flex',gap:'15px',flex:1}}>
        <DatePicker
          views={['year','month','date']}
          inputVariant='outlined'
          size={'small'}
          fullWidth
          label='From'
          placeholder='DD/MM/YYYY'
          format="dd/MM/yyyy"
          value={startDate}
          onChange={setStartDate}
          InputProps={{
            endAdornment: <DateRangeOutlined style={{color:'#858D98',cursor:'pointer'}}/>
          }}
          clearable={true}
          clearLabel={'Clear'}
        />
        <DatePicker
          views={['year','month','date']}
          inputVariant='outlined'
          size={'small'}
          fullWidth
          label='To'
          placeholder='DD/MM/YYYY'
          format="dd/MM/yyyy"
          value={endDate}
          onChange={setEndDate}
          InputProps={{
            endAdornment: <DateRangeOutlined style={{color:'#858D98',cursor:'pointer'}}/>
          }}
          clearable={true}
          clearLabel={'Clear'}
        />    
      </Box>
    </MuiPickersUtilsProvider>
  )
}