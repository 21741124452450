import React, { useState } from 'react';
import BenchTable from './BenchTable';
import BenchForm from './BenchForm';
import BenchWiseHeadPriority from 'features/Tdsat/components/BenchWiseHeadPriority';

function BenchCis() {
  const [isFilingNotice, setIsFilingNotice] = useState(false);
  const [isBenchPriority, setIsBenchPriority] = useState(false); // New state for BenchWiseHeadPriority

  const handleFileNewNotice = () => {
    setIsFilingNotice(true); 
    setIsBenchPriority(false); 
  };

  const handleBackToTable = () => {
    setIsFilingNotice(false); 
    setIsBenchPriority(false); 
  };

  const handleFormSubmit = () => {
    setIsFilingNotice(false);
    setIsBenchPriority(false); 
  };

  const handleShowBenchPriority = () => {
    setIsBenchPriority(true);
    setIsFilingNotice(false); 
  };

  const handleBackFromBenchPriority = () => {
    setIsBenchPriority(false);
  };

  return (
    <>
      {!isFilingNotice && !isBenchPriority && (
        <BenchTable 
          onFileNewNotice={handleFileNewNotice} 
          onShowBenchPriority={handleShowBenchPriority} // Pass the handler to BenchTable
        />
      )}

      {isFilingNotice && (
        <BenchForm onBack={handleBackToTable} onSubmit={handleFormSubmit} />
      )}

      {isBenchPriority && (
        <BenchWiseHeadPriority onBack={handleBackFromBenchPriority} />
      )}
    </>
  );
}

export default BenchCis;
