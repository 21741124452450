import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import MailSentVectorSVG from "images/mail-sent/rafiki.svg";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "6rem",
    color: "#707C97",
  },
  hotstar: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: "500",
    marginBottom: "34px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  primaryText: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  container: {
    padding: "20px",
  },
  vectorImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      order: 1,
      marginBottom: "2rem",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function WaitingScreen() {
  const classes = useStyles();


  return (
    <Fragment>
      <Grid container justify="center">
        {/* <Box height="5rem"></Box> */}

        <Grid item className={classes.container} lg={9} md={10} sm={10}>
          <div className={classes.mainContainer}>
            <div className={classes.content}>
              <Typography
                className={classes.hotstar}
              >
                We acknowledge your case submission. Your diary number is 1274/26.
              </Typography>
              <Box height="0.1rem"></Box>
            </div>
            <Hidden smDown>
              <Box flexGrow={1}></Box>
            </Hidden>
            <img
              className={classes.vectorImage}
              src={MailSentVectorSVG}
              alt="mail sent vector"
            />
          </div>
          <Box height="1.5rem"></Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
