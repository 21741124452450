import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, MenuItem, FormControl, Select, InputLabel, TableHead, TableRow, Button, Paper, Typography, TextField, makeStyles } from '@material-ui/core';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import DateRangePickerTDSAT from 'features/Tdsat/DateRangePickerTDSAT';
import instance from 'api/globalaxios';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import DatePickerTDSAT from 'features/Tdsat/components/DatePickerTDSAT';
import HeadOfCauseListInput from 'features/Tdsat/components/HeadOfCauseListInput';
import BackBtnBreadcrumb from '../shared/BackBtnBreadcrumb';

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexGrow: 1
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const PendingCaseAllocation = ({onBack}) => {
  const classes= useStyles();
  const [formData, setFormData] = useState({listingDate: null});
  const [benchNature, setBenchNature] = useState('');
  const [courtNumber, setCourtNumber] = useState('');
  const [headOfCauseList, setHeadOfCauseList] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    // Check if all fields are empty
    if (formData?.listingDate && !benchNature && !courtNumber && !headOfCauseList) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill at least one search field!',
        icon: 'warning',
      });
      return;
    }

    const formattedListingDate = formData?.listingDate ? format(new Date(formData?.listingDate), 'dd-MM-yyyy') : '';

    // Set loading state and call the API
    setLoading(true);
    try {
      const response = await getPendingCaseAllocation(formattedListingDate, benchNature, courtNumber, headOfCauseList);
      setFilteredData(response?.data?.results || []);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error fetching the data!',
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'listingDate': value,
    }));
  }

  // Handle priority input change
  const handlePriorityChange = (id, value) => {
    const updatedData = filteredData.map((item) => {
      if (item.id === id) {
        return { ...item, priority: value };
      }
      return item;
    });
    setFilteredData(updatedData);
  };

   // Validate priorities for duplicates, missing values, and range
   const validatePriority = () => {
    const prioritySet = new Set();
    let isValid = true;
    let errorMessage = '';

    for (let i = 0; i < filteredData.length; i++) {
      const { priority } = filteredData[i];

      if (!priority || priority === '' || isNaN(priority)) {
        errorMessage = 'Please fill all priority fields.';
        isValid = false;
        break;
      }

      if (priority < 1 || priority > filteredData.length) {
        errorMessage = `Priority should be between 1 and ${filteredData.length}.`;
        isValid = false;
        break;
      }

      if (prioritySet.has(priority)) {
        errorMessage = 'Duplicate priorities are not allowed.';
        isValid = false;
        break;
      }

      prioritySet.add(priority);
    }

    if (!isValid) {
      Swal.fire('Error', errorMessage, 'error');
    }

    return isValid;
  };

  const preparePriorityPayload = () => {
    return filteredData
      .filter(item => item.priority) // Only include items with a priority value
      .map(item => ({ id: item.id, priority: parseInt(item.priority, 10) }));
  };

  const handleSubmit = async () => {
    if (!validatePriority()) {
      return;
    }

    const priorityData = preparePriorityPayload();
    
    if(priorityData.length < filteredData.length) {
      Swal.fire('Error', 'Please assign priority to all cases before submitting!', 'error');
      return;
    }

    try {
      const response = await instance.put(`/tdsat/pending_case_allocation/`, {priority: priorityData});
      console.log(response,'response');
      Swal.fire('Success', 'Priority submitted successfully!', 'success');
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'Error submitting priority data', 'error');
    }
  }
  
  return (
    <div className={classes.root}>
      <BackBtnBreadcrumb
        onBackClick={() => onBack()}
        breadcrumbs={[
          { label: 'Case Listing', onClick: () => onBack() },
        ]}
        currentPath='Case No. Priority Set'
      />
      <Box>
        <Typography variant="h5">Pending Case Allocation</Typography>

        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', marginTop: '40px' }}>
          <FormControl fullWidth margin="none" variant="outlined">
            <DatePickerTDSAT
                date={formData?.listingDate}
                setDate={handleDateInput}
                label={'Listing Date'}
            />
          </FormControl>
          <FormControl style={{ minWidth: 200 }}  variant='outlined'>
            <InputLabel>Bench Nature</InputLabel>
            <Select
              value={benchNature}
              onChange={(e) => setBenchNature(e.target.value)}
            >
              <MenuItem value="single_bench">Single Bench</MenuItem>
              <MenuItem value="divisonal_bench">Division Bench</MenuItem>
              <MenuItem value="full_bench">Full Bench</MenuItem>
              <MenuItem value="registrar">Registrar</MenuItem>
            </Select>
          </FormControl>

          {/* Court Number Select */}
          <FormControl style={{ minWidth: 150 }}  variant='outlined'>
            <InputLabel>Court Number</InputLabel>
            <Select
              value={courtNumber}
              onChange={(e) => setCourtNumber(e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>

          {/* Head of Cause List Select */}
          {/* <FormControl style={{ minWidth: 150 }}  variant='outlined'>
            <InputLabel>Head of Cause List</InputLabel>
            <Select
              value={headOfCauseList}
              onChange={(e) => setHeadOfCauseList(e.target.value)}
            >
              <MenuItem value="dkm">dkm</MenuItem>
            </Select>
          </FormControl> */}
          <HeadOfCauseListInput
              onChange={(e) => setHeadOfCauseList(e.target.value)}
              headOfListing={headOfCauseList}
              isLabel={false}
          />
            {/* {formErrorsCase.headOfListing && <FormHelperText error>{formErrorsCase.headOfListing}</FormHelperText>} */}

          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>

        {loading ? (
          <div className={classes.loader}>
            <CircularCenteredLoader />
          </div>
        ) : filteredData.length === 0 ? (
          <Typography variant="body1">
            Search to find and update Pending Case Allocation
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.noBordersTitle}>Sr. No.</TableCell>
                  <TableCell className={classes.noBordersTitle}>Case Number</TableCell>
                  <TableCell className={classes.noBordersTitle}>Party Details</TableCell>
                  <TableCell className={classes.noBordersTitle}>Priority</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.length > 0 ? (
                  filteredData.map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.noBorders}>{index + 1}</TableCell>
                    <TableCell className={classes.noBorders}>{row?.main_case?.case_number}</TableCell>
                    <TableCell className={classes.noBorders}>{row.main_case?.name}</TableCell>
                    <TableCell className={classes.noBorders}>
                      <TextField
                        value={row.priority || ''}
                        onChange={(e) => handlePriorityChange(row.id, e.target.value)}
                        type="number"
                        label="type"
                        inputProps={{ min: 1, max: filteredData.length }}
                        variant='outlined'
                      />
                    </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} className={classes.noBorders} align="center">
                      No Notice on this date range
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            </TableContainer>
          </>
        )}
       {filteredData?.length > 0 && 
       ( <div className={classes.submitButton}>
          <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      )}
      </Box>
    </div>
  );
};

// API Call
async function getPendingCaseAllocation(listingDate, benchNature, courtNumber, headOfCauseList) {
  return instance.get(
    `/tdsat/pending_case_allocation/?listing_date=${listingDate}&bench_nature=${benchNature}&court_number=${courtNumber}&head_of_causelist=${headOfCauseList}`
  );
}

export default PendingCaseAllocation;
