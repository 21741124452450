import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Paper,
  makeStyles,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  IconButton,
  Card,
  CardContent,
} from '@material-ui/core';
import instance from 'api/globalaxios';
import { CloudUpload, Delete, Add, DeleteOutline, AddOutlined } from '@material-ui/icons';
import PayBharatKosh from './components/PayBharatKosh';
import UploadFileTdsat from './components/UploadFileTdsat';
import MainCaseDetails from './components/MainCaseDetails';
import PetitionerRespondentUI from './components/PetitionerRespondentUI';
import SuccessScreenTdsat from './SuccessScreenTdsat';
import { deleteParty } from './MainCase';
import { useSnackbar } from 'contexts/SnackbarContext';

const IntervenorForm = ({intervenorParties, setIntervenorParties}) => {
  const [showForm, setShowForm] = useState(false);
  const [intervenors, setIntervenors] = useState([]);
  const [formIntervenorData, setFormIntervenorData] = useState({
    full_name: "",
    father_or_husband_name: "",
    phone_number: "",
    alt_phone_number: "",
    correspondence_address: "",
    email: "",
    type: 'intervenor'
  });
  const [intervenorErrors, setIntervenorErrors] = useState({});

  const handleAddIntervenorChange = (e) => {
    const { name, value } = e.target;
    setFormIntervenorData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFormIntervenor = () => {
    let tempErrors = {};
    if (!formIntervenorData.full_name) tempErrors.full_name = 'This field is required';
    if (!formIntervenorData.father_or_husband_name) tempErrors.father_or_husband_name = 'This field is required';
    if (!formIntervenorData.phone_number) tempErrors.phone_number = 'This field is required';
    if (!formIntervenorData.correspondence_address) tempErrors.correspondence_address = 'This field is required';
    if (!formIntervenorData.email) tempErrors.email = 'This field is required';
    return tempErrors;
  }

  const addIntervenor = async () => {
    const tempErrors = validateFormIntervenor();
    
    // If there are validation errors, set them in state
    if (Object.keys(tempErrors).length > 0) {
      setIntervenorErrors(tempErrors);
      return; // Stop execution if there are validation errors
    } 
  
    // If no validation errors, proceed with API call
    try {
      const response = await instance.post('tdsat/party/', formIntervenorData);
  
      // Check for successful response
      if (response.status === 200 || response.status === 201) {
        setIntervenors([...intervenors, response?.data]);
        const partyId = response.data.id; 
        if (partyId) {
          const newIntervenorParties = [...intervenorParties, partyId];
          setIntervenorParties(newIntervenorParties);
        }
        // Reset form and clear errors after successful API call
        setFormIntervenorData({
          full_name: "",
          father_or_husband_name: "",
          phone_number: "",
          alt_phone_number: "",
          correspondence_address: "",
          email: "",
          type: 'intervenor'
        });
  
        setIntervenorErrors({}); // Clear any previous errors
        setShowForm(false);
      } else {
        // Handle API error here (optional)
        console.log("API request failed.");
      }
    } catch (error) {
      console.log("Error adding intervenor:", error);
    }
  };

  const deleteIntervenor = async (index, partyId) => {
    try {
      // First, make the API call to delete the Intervenor using the partyId
      const response = await deleteParty(partyId);
      
      if (response.status === 200) {
        // If the API deletion is successful, update the local state
        const updatedIntervenors = intervenors?.filter((_, i) => i !== index);
        setIntervenors(updatedIntervenors);
        
        const updatedIntervenorParties = intervenorParties?.filter(id => id !== partyId);
        setIntervenorParties(updatedIntervenorParties);

        console.log('Intervenor deleted successfully.');
      } else {
        console.error('Failed to delete Intervenor from the server.');
      }
    } catch (error) {
      console.error('Error while deleting Intervenor:', error);
    }
  };

  return (
    <Box display="flex" justifyContent={"space-between"} width={'100%'} paddingTop={2} sx={{ padding: 2, maxWidth: 600, margin: "auto" }}>
      {!showForm && (
        <Box display="flex" justifyContent={"space-between"} alignItems={"center"} width={"50%"}>
          <Typography style={{fontSize: '18px'}}>Intervenor</Typography>
          {intervenors.length === 0 && 
            <Button
              style={{borderRadius: '12px'}}
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
            >
              Add Intervenor +
            </Button>
          }
        </Box>
      )} 
      {showForm && (
        <Box border={1} borderColor="#858D98" p={4} borderRadius={2} mt={4} >
          <TextField
            label="Full Name"
            name="full_name"
            value={formIntervenorData.full_name}
            onChange={handleAddIntervenorChange}
            fullWidth
            required
            margin="normal"
            variant='outlined'
            error={!!intervenorErrors.full_name}
            helperText={intervenorErrors.full_name}
          />
          <TextField
            label="Father's/Husband's Name"
            name="father_or_husband_name"
            value={formIntervenorData.father_or_husband_name}
            onChange={handleAddIntervenorChange}
            fullWidth
            required
            margin="normal"
            variant='outlined'
            error={!!intervenorErrors.father_or_husband_name}
            helperText={intervenorErrors.father_or_husband_name}
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={formIntervenorData.phone_number}
            onChange={handleAddIntervenorChange}
            fullWidth
            required
            margin="normal"
            variant='outlined'
            error={!!intervenorErrors.phone_number}
            helperText={intervenorErrors.phone_number}
          />
          <TextField
            label="Alternate Phone Number"
            name="alt_phone_number"
            value={formIntervenorData.alt_phone_number}
            onChange={handleAddIntervenorChange}
            fullWidth
            margin="normal"
            variant='outlined'
          />
          <TextField
            label="Correspondence Address"
            name="correspondence_address"
            value={formIntervenorData.correspondence_address}
            onChange={handleAddIntervenorChange}
            fullWidth
            required
            margin="normal"
            variant='outlined'
            error={!!intervenorErrors.correspondence_address}
            helperText={intervenorErrors.correspondence_address}
          />
          <TextField
            label="Email"
            name="email"
            value={formIntervenorData.email}
            onChange={handleAddIntervenorChange}
            fullWidth
            required
            margin="normal"
            variant='outlined'
            error={!!intervenorErrors.email}
            helperText={intervenorErrors.email}
          />
          <Box display={"flex"} justifyContent={"center"} gridGap={'10px'}>
            <Button variant="outlined" onClick={() => setShowForm(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={addIntervenor}>
              Add Intervenor +
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{ marginTop: 3 }}>
        {intervenors.map((intervenor, index) => (
          <Card sx={{ marginBottom: 2 }} key={index}>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3} style={{marginRight: '20px'}}>
                  <Typography variant="body1">{intervenor.full_name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton color="error" onClick={() => deleteIntervenor(index, intervenor?.id)}>
                    <DeleteOutline />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <IconButton color="primary" onClick={() => setShowForm(true)}>
                    <AddOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export const getPartyDetails = async (ids) => {
  const params = new URLSearchParams();
  ids.forEach(id => params.append('ids', id));
  try {
    const response = await instance.get(`/tdsat/api/party/retrieve/?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the party details!", error);
  }
};

export const getCaseTypes = async (type) => {
  try{
    const response = await instance.get(`/tdsat/case-types/?type=${type}`)
    return response.data;
  }catch(error){
    console.error("There was an error fetching the case data!", error);
  }
}

export async function createReciepts(data) {
  return await instance({
    method: "post",
    url: "/tdsat/court-fee-receipts/create-receipt/",
    data: data
  });
}

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: 12,
    fontSize: 22,
  },
  buttonPay: {
    padding: theme.spacing(1),
    borderRadius: 12,
    width: '50%',
    display: 'flex',
  },
  labelTitle: {
    display: 'flex',
    marginBottom: '8px',
    fontSize: '12px'
  },
  labelSubtitle: {
    display: 'flex',
    marginTop: '8px',
    fontSize: '11px',
    color: '#858D98',
    marginBottom: '32px'
  },
  amount: {
    fontSize: '18px',
  },
  titleCase: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 700,
    display: 'flex'
  },
  nameTitle: {
    fontSize: '18px',
    lineHeight: '31.32px',
    fontWeight: 400,
    display: 'flex'
  },
  containerCaseInfo: {
    marginBottom: '18px'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerUser: {
    marginTop: '18px'
  },
  titleUserInfo: {
    display: 'flex',
    color: '#000',
    fontSize: '16px'
  },
  partyName: {
    fontSize: '18px',
    fontWeight: '400'
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ApplicationFilingForm = ({draftCase}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    case_number: draftCase ? draftCase?.case_number : '',
    year: draftCase ? draftCase?.year : '',
    document_type: '',
    claimant_parties: [],
    respondent_parties: [],
    e_court_fee_receipt_number: '',
    agree: false,
    is_draft: true,
    main_case: draftCase ? draftCase?.main_case : '', 
    selectType: 'caseNumber',
    diary_number: ''
  });
  const [errors, setErrors] = useState({});
  const { showSnackbar } = useSnackbar();

  const [partyDetails, setPartyDetails] = useState([]);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [applicationResponse, setApplicationResponse] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [diaryNumber, setDiaryNumber] = useState(null);
  const [draftDate, setDraftDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [intervenorParties, setIntervenorParties] = useState([]);

  const [eRecieptData, setERecieptData] = useState({content_type: 'Application'});
  const NumberOfParties = formData?.claimant_parties?.length > 0 
    ? formData?.claimant_parties?.length 
    : formData?.respondent_parties?.length

  useEffect(()=>{
    if(draftCase){
      setApplicationResponse(draftCase);
      handleClaimantRespondentData(formData);
    }
  },[])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.document_type) tempErrors.document_type = 'This field is required';
    if (formData.claimant_parties.length === 0 && formData.respondent_parties.length === 0) {
      tempErrors.claimant_parties = 'At least one field is required';
      tempErrors.respondent_parties = 'At least one field is required';
    } else if (formData.claimant_parties.length > 0 && formData.respondent_parties.length > 0) {
        tempErrors.claimant_parties = 'Only one field can be filled';
        tempErrors.respondent_parties = 'Only one field can be filled';
    }
    if (!eRecieptData.receipt_number) tempErrors.receipt_number = 'This field is required';
    if (!formData.agree) tempErrors.agree = 'You must agree before submitting';
    return tempErrors;
  };

  // const handleApplicationChange = (e) => {
  //   handleInputChange(e);

  //   const { value } = e.target;
  //   const selectedApplication = applicationTypes?.find(app => app.id === value);

  //   setAmount(selectedApplication?.amount || 0);
  // };


  const createApplication = async () => {
    const identifier = formData.case_number 
    ? { case_number: formData.case_number, year: formData.year }
    : { diary_number: formData.diary_number, year: formData.year };
    try{
      const response = await instance.post(`/tdsat/applications/`, identifier)
      setApplicationResponse(response?.data)
      setFormData((prevData) => ({
        ...prevData,
        main_case: response?.data?.main_case,
      }));
    }catch(error){
      console.error("There was an error fetching the case data!", error);
    }
  }

  const handleClaimantRespondentData = async (formData) => {
    let url = '';
    if (formData?.case_number) {
      url = `/tdsat/api/maincaseretrieve/?case_number=${formData.case_number}&year=${formData.year}`;
    } else if (formData?.diary_number) {
      url = `/tdsat/api/maincaseretrieve/?diary_number=${formData.diary_number}&year=${formData.year}`;
    } else {
      throw new Error('Please provide either a case number or a diary number.');
    }
    try {
      const response = await instance.get(url);
      const { claimant_parties, respondent_parties } = response.data;
      const allParties = [...claimant_parties, ...respondent_parties];
      if (allParties.length > 0) {
        const partyDetailsResponse = await getPartyDetails(allParties);
        setPartyDetails(partyDetailsResponse);  
        // const caseTypes = await getCaseTypes('Application');   
        // setApplicationTypes(caseTypes);
        if(!draftCase){
          createApplication();
        }
      }
    } catch (error) {
      alert('No Main Case found')
      console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmitApplication = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const updatedFormData = { ...formData, is_draft: false, intervenor_parties: intervenorParties };
      try{
          createReciepts(eRecieptData).then(async (response)=> {
            if(response?.status === 200){
              const response = await instance.patch(`/tdsat/applications/${applicationResponse?.id}`, updatedFormData);
              setDiaryNumber(response?.data?.diary_number);
              setDraftDate(response?.data?.draft_creation_date_time)
              setIsSuccess(true)
            }
          }).catch((error) => {
            showSnackbar("Court fee receipt with this receipt number already exists.", "error");
            console.log(error, 'recieptErorr');
          })
      }catch(error){
        console.error("There was an error fetching the case data!", error);
      }
    }else {
      setErrors(formErrors);
    }
  }
  
  return (
    <>
    {isSuccess ? 
      <SuccessScreenTdsat diaryNumber={diaryNumber} date={draftDate}/>
      :
      <Box component="form" className={classes.form}>
        <Typography variant="h4" align="center" className={classes.title}>
          Application Filing
        </Typography>
        <MainCaseDetails
          classes={classes}
          formData={formData}
          handleInputChange={handleInputChange}
          handleClaimantRespondentData={handleClaimantRespondentData}
          partyDetails={partyDetails}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <FormControl fullWidth margin="none" variant="outlined">
                <InputLabel>{'Application Type'}</InputLabel>
                <Select
                  label={'Application Type'}
                  name="document_type"
                  value={formData.document_type}
                  onChange={handleApplicationChange}
                >
                  {applicationTypes?.map((application, index) => (
                    <MenuItem key={index} value={application?.id}>
                      {application?.description}
                    </MenuItem>
                  ))}
                </Select>
                {errors.document_type && <FormHelperText style={{color: 'red'}}>{errors.document_type}</FormHelperText>}
              </FormControl> */}
              <FormControl fullWidth margin="none" variant="outlined">
                <TextField
                  label="Application Type"
                  name="document_type"
                  variant="outlined"
                  value={formData.document_type}
                  onChange={handleInputChange}
                  fullWidth
                />
                {errors.document_type && <FormHelperText style={{ color: 'red' }}>{errors.document_type}</FormHelperText>}
              </FormControl>
            </Grid>
          {partyDetails?.length > 0 && 
              <PetitionerRespondentUI 
                data={partyDetails}
                handleInputChange={handleInputChange}
                formData={formData}
                setFormData={setFormData}
                classes={classes}
                errors={errors}
              />
            }
          </Grid>
          {/* <div>___________OR______________</div> */}
          <IntervenorForm intervenorParties={intervenorParties} setIntervenorParties={setIntervenorParties}/>
        </Paper>
        <PayBharatKosh
          classes={classes}
          formData={formData}
          handleInputChange={handleInputChange}
          amount={(NumberOfParties + intervenorParties.length) * 1000}
          errors={errors}
          setERecieptData={setERecieptData}
          eReceiptData={eRecieptData}
          applicationResponse={applicationResponse}
        />
        <UploadFileTdsat
          classes={classes} 
          formData={formData}
          handleInputChange={handleInputChange}
          setFormData={setFormData}
          applicationResponse={applicationResponse}
          uploadType={'Application'}
          errors={errors}
        />
        <Grid className={classes.submitButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonPay}
            onClick={()=>handleSubmitApplication(formData)}
          >
            Submit 
          </Button>
        </Grid>
      </Box>
    }
    </>
  );
};

export default ApplicationFilingForm;
