import { 
  Box, 
  Typography, 
  makeStyles, 
  Select, 
  Grid, 
  Button, 
  Paper, 
  TextField, 
  FormControl, 
  InputLabel, 
  MenuItem ,
  Divider,
  FormHelperText,
  ClickAwayListener,
  Popper
} from "@material-ui/core";
import instance from "api/globalaxios";
import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import SuccessResult from "../shared/SuccessResult";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import DatePickerTDSAT from "features/Tdsat/components/DatePickerTDSAT";
import MessageBox from "features/Tdsat/components/MessageBox";
import TimeInput from "../shared/TimeInput";
import BackBtnBreadcrumb from "../shared/BackBtnBreadcrumb";

const TIME = ['Select', 'AM', 'PM'];
const JUDGES = ['dipesh', 'vansh', 'ishita', 'ranita','anchit', 'nikhil']
const BENCH_NUMBER = ['1','2','3']
const COURT_ROOM_NUMBER = ['1','2','3']

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    borderRadius: '10px'
  },
  button: {
      padding: theme.spacing(1),
      borderRadius: 12,
      fontSize: 22,
  },
  buttonPay: {
      padding: theme.spacing(1),
      borderRadius: 12,
      width: '50%',
      display: 'flex',
  },
  labelTitle: {
      display: 'flex',
      marginBottom: '8px',
      fontSize: '12px'
  },
  labelSubtitle: {
      display: 'flex',
      marginTop: '8px',
      fontSize: '11px',
      color: '#858D98',
      marginBottom: '32px'
  },
    amount: {
      fontSize: '18px',
    },
    titleCase: {
      color: '#000',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 700,
      display: 'flex'
    },
    nameTitle: {
      fontSize: '18px',
      lineHeight: '31.32px',
      fontWeight: 400,
      display: 'flex'
    },
    containerCaseInfo: {
      marginBottom: '18px'
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column'
    },
    containerUser: {
      marginTop: '18px'
    },
    titleUserInfo: {
      display: 'flex',
      color: '#000',
      fontSize: '16px'
    },
    partyName: {
      fontSize: '18px',
      fontWeight: '400'
    },
    heading: {
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    infoBox: {
      textAlign: 'left',
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    label: {
      fontWeight: 'bold',
    },
    value: {
      marginBottom: '10px',
    },
    button: {
      marginTop: '20px',
      backgroundColor: '#4a58e9',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#3949ab',
      },
    },
    formControl: {
      margin: theme.spacing(2, 0),
      minWidth: 120,
      width: '100%',
    },
    judgeCompositionTitle: {
      width: '100%',
      color: '#4A3BFF',
      marginBottom: '8px',
      fontSize: '16px'
    },
    submitButton: {
      display: 'flex',
      justifyContent: 'center'
    }
}));

export async function getBenchByDate(date) {
  return await instance({
    method: "get",
    url: `tdsat/bench/?date=${date}`
  });
}

export async function getBenchNumberForNewBench(date, benchNature) {
  return await instance({
    method: "get",
    url: `tdsat/get_bench_number_for_new_bench/?listing_date=${date}&bench_nature=${benchNature}`
  });
}

export async function getjudgesBench(){
  return await instance({
    method: "get",
    url: `tdsat/judges/`
  });
}

export async function createBench(formData){
  return await instance({
    method: "post",
    url: `/tdsat/bench/`,
    data: formData
  })
}

const BenchForm = ({onBack}) => {
  const [judges, setJudges] = useState([]);  // State to store the judges data
  const [formData, setFormData] = useState({
    listing_date: null,
    fromTime: '',
    timeType: 'PM',
    benchNature: '',
    benchNumber: '',
    courtRoomNumber: '',
    judge1: '',
    judge2: '',
    judge3: '',
    presidingJudge: '',
  });
  const [errors, setErrors] = useState({
    listing_date: '',
    fromTime: '',
    timeType: 'PM',
    benchNature: '',
    benchNumber: '',
    courtRoomNumber: '',
    judge1: '',
    judge2: '',
    judge3: '',
    presidingJudge: '',
  });
  const [loadingBenchNumber, setLoadingBenchNumber] = useState(false); 
  const [benchNumber, setBenchNumber] = useState(''); 
  const [createBenchResponse, setCreateBenchResponse]= useState();
  const [errorResponse, setErrorResponse]=useState();

  const classes = useStyles();

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };

  useEffect(() => {
    const fetchJudges = async () => {
      try {
        const response = await getjudgesBench();
        setJudges(response?.data);  
      } catch (error) {
        console.error("Error fetching judges", error);
      }
    };

    fetchJudges();
  }, []);

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.listing_date) tempErrors.listing_date = 'This field is required';
    if (!formData.fromTime) tempErrors.fromTime = 'This field is required';
    if (!formData.benchNature) tempErrors.benchNature = 'This field is required';
    if (!formData.benchNumber) tempErrors.benchNumber = 'This field is required';
    if (!formData.courtRoomNumber) tempErrors.courtRoomNumber = 'This field is required';
    if (!formData.presidingJudge) tempErrors.presidingJudge = 'This field is required';
    if (!formData.judge1) tempErrors.judge1 = 'This field is required';
    return tempErrors;
  };

  const fetchBenchNumber = async () => {
    const { listing_date, benchNature } = formData;
    if (listing_date && benchNature) {
      setLoadingBenchNumber(true); // Start loading state
      try {
        const response = await getBenchNumberForNewBench(format(listing_date, "dd-MM-yyyy"), benchNature);
        setBenchNumber(response?.data?.bench_number); // Set bench number from API
        setFormData((prevData) => ({
          ...prevData,
          "benchNumber": response?.data?.bench_number,
        }));
      } catch (error) {
        console.error('Failed to fetch bench number:', error);
      } finally {
        setLoadingBenchNumber(false); // End loading state
      }
    }
  };

  useEffect(() => {
    fetchBenchNumber();
  }, [formData.listing_date, formData.benchNature]);

  const getAvailableJudges = (exclude) => {
    return judges?.filter(judge => !exclude.includes(judge.id));
  };

  const getPresidingJudgeOptions = [formData.judge1, formData.judge2, formData.judge3].filter(Boolean);

  const selectedJudges = getPresidingJudgeOptions?.map(id => {
      return judges.find(j => j.id === id);
  }).filter(Boolean);

  const handleSubmitBench= async(formData)=>{
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      let judgesArray = [];

      if (formData?.judge1 !== null && formData?.judge1 !== "") judgesArray.push(formData?.judge1);
      if (formData?.judge2 !== null && formData?.judge2 !== "") judgesArray.push(formData?.judge2);
      if (formData?.judge3 !== null && formData?.judge3 !== "") judgesArray.push(formData?.judge3);

      try {
        const response = await createBench({
          "listing_date": format(formData?.listing_date, 'yyyy-MM-dd'),
          "time": formData?.fromTime,
          "bench_nature": formData?.benchNature,
          "court_number": formData?.courtRoomNumber,
          "bench_number": formData?.benchNumber,
          "judges": judgesArray,
          "presiding_judge": formData?.presidingJudge
        })
        setCreateBenchResponse(response?.data);
        setFormData({});
      }catch(error){
        console.log(error,'error', error.response)
        setErrorResponse(error.response.data);
      }
    }else {
      setErrors(formErrors);
    }
  }

  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'listing_date': value,
    }));
  }

  return(
      <Box component="form" className={classes.form}>
          <BackBtnBreadcrumb
            onBackClick={() => onBack()}
            breadcrumbs={[
              { label: 'View Bench', onClick: () => onBack() },
            ]}
            currentPath='Create Bench'
          />
          <Typography variant="h4" align="center" className={classes.title}>
            CREATE BENCH
          </Typography>
          {errorResponse && <MessageBox title={errorResponse?.error}/>}
          {createBenchResponse && <MessageBox title="Bench composition done successfully." subTitle={`Bench composition is done for the date ${createBenchResponse?.listing_date} at ${createBenchResponse?.time} for a ${createBenchResponse?.bench_nature} in Court No. ${createBenchResponse?.court_number}`}/>}          
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item xs={6}>
                      <FormControl className={classes.formItem}  fullWidth margin="none" variant="outlined">
                        <DatePickerTDSAT
                          date={formData.listing_date}
                          setDate={handleDateInput}
                          label={"Listing Date"}
                        />
                        {errors.listing_date && <FormHelperText error>{errors.listing_date}</FormHelperText>}
                      </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                          <FormControl fullWidth margin="none" variant="outlined">
                            <TimeInput formData={formData} handleInputChange={handleInputChange}/>
                            {errors.fromTime && <FormHelperText error>{errors.fromTime}</FormHelperText>}
                          </FormControl>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                    <InputLabel>Bench Nature</InputLabel>
                    <Select
                      name="benchNature"
                      value={formData.benchNature}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="single_bench">Single Bench</MenuItem>
                      <MenuItem value="division_bench">Division Bench</MenuItem>
                      <MenuItem value="full_bench">Full Bench</MenuItem>
                      <MenuItem value="registrar">Registrar</MenuItem>
                    </Select>
                    {errors.benchNature && <FormHelperText error>{errors.benchNature}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                          <TextField
                            value={loadingBenchNumber ? 'Loading...' : formData?.benchNumber} 
                            disabled
                            variant="outlined"
                            label="Bench Number"
                            fullWidth
                          />
                          {errors.benchNumber && <FormHelperText error>{errors.benchNumber}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                          <FormControl fullWidth margin="none" variant="outlined">
                          <InputLabel>{'Court Room Number'}</InputLabel>
                          <Select
                              label={'Court Room Number'}
                              name="courtRoomNumber"
                              value={formData.courtRoomNumber}
                              onChange={handleInputChange}
                          >
                              {COURT_ROOM_NUMBER.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                      {option}
                                  </MenuItem>
                              ))}
                          </Select>
                          {errors.courtRoomNumber && <FormHelperText error>{errors.courtRoomNumber}</FormHelperText>}
                          </FormControl>
                      </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid className={classes.judgeCompositionTitle}>
                  <Typography style={{fontSize: '16px'}}>Hon’ble Justice Sitting Composition</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none" variant="outlined">
                    <InputLabel>{'Select Judge 1'}</InputLabel>
                    <Select
                        label={'Select Judge 1'}
                        name="judge1"
                        value={formData.judge1}
                        onChange={handleInputChange}
                    >
                        {getAvailableJudges([]).map((judge) => (
                            <MenuItem key={judge.judge_code} value={judge.id}>
                              {judge.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.judge1 && <FormHelperText error>{errors.judge1}</FormHelperText>}
                    </FormControl>
                </Grid>
                {(formData.benchNature === 'division_bench' || formData.benchNature === 'full_bench') && (
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="none" variant="outlined">
                      <InputLabel>Select Judge 2</InputLabel>
                      <Select
                        label="Select Judge 2"
                        name="judge2"
                        value={formData.judge2}
                        onChange={handleInputChange}
                        disabled={!formData.judge1}
                      >
                        {getAvailableJudges([formData.judge1]).map((judge) => (
                          <MenuItem key={judge.judge_code} value={judge.id}>
                            {judge.name} 
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.judge2 && <FormHelperText>{errors.judge2}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}

                {formData.benchNature === 'full_bench' && (
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="none" variant="outlined">
                      <InputLabel>Select Judge 3</InputLabel>
                      <Select
                        label="Select Judge 3"
                        name="judge3"
                        value={formData.judge3}
                        onChange={handleInputChange}
                        disabled={!formData.judge2} 
                      >
                       {getAvailableJudges([formData.judge1, formData.judge2]).map((judge) => (
                          <MenuItem key={judge.judge_code} value={judge.id}>
                            {judge.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.judge3 && <FormHelperText>{errors.judge3}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControl fullWidth margin="none" variant="outlined">
                    <InputLabel>Select Presiding Judge</InputLabel>
                    <Select
                      label="Select Presiding Judge"
                      name="presidingJudge"
                      value={formData.presidingJudge}
                      onChange={handleInputChange}
                    >
                      {selectedJudges?.map((judge) => (
                        <MenuItem key={judge.judge_code} value={judge.id}>
                          {judge.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.presidingJudge && <FormHelperText error>{errors.presidingJudge}</FormHelperText>}
                  </FormControl>
                </Grid>
            </Grid>
          </Paper>
          <Grid className={classes.submitButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonPay}
              onClick={()=>handleSubmitBench(formData)}
            >
              Submit 
            </Button>
          </Grid>
      </Box>
  )
}

export default BenchForm;