import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useState } from "react";

export default function Report({classes,REPORT_CHOICES,handleClickSearch}){
  const [search, setSearch] = useState('');

  const handleChangeSearchReport = (e) => {
    setSearch(e.target.value);
  }

  return (
    <Box>
      <Box className={classes.entryForm}>
        <Box style={{display:'flex', gap:'1rem', justifyContent:'space-between'}}>
          <Typography style={{fontSize:'1.5rem', width:'100%'}}>
            REPORT
          </Typography>
        </Box>
        <Box style={{height:'2.5rem'}}/>
        <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem',justifyContent: 'center'}}>
          <Grid item xs={12}>
            <FormControl required fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>Search Report*</Typography>
              <Select
                name="search_report"
                value={search}
                onChange={handleChangeSearchReport}
              >
                {REPORT_CHOICES.map((report,index)=>{
                  return (
                    <MenuItem key={index} value={report}>{report}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={()=>handleClickSearch(search)}
          >
            Search
          </Button>
        </Grid>
        <Box style={{height:'2rem'}}></Box>
      </Box>
    </Box>
  )
}