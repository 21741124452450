import { Box, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DatePickerTDSAT from "./components/DatePickerTDSAT";
import { format } from "date-fns";
import { getCauseListData, printCauseList } from "./API/TdsatApi";
import CourtNumberInput from "./components/CourtNumberInput";
import fileDownload from "js-file-download";
import { PrintOutlined } from "@material-ui/icons";
import MessageBox from "./components/MessageBox";
import theme from "NewTheme";
import validateDateAndFormat from "utils/validateDateAndFormat";
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  buttonPrint: {
    borderRadius: '0.5rem',
    border: '1px solid #AAAFB9',
    background: '#fff',
    padding: '0.5rem 1rem',
  },
  header: {
    padding: '2rem 0 0.5rem 0',
    borderBottom: '1px solid #4B4E56',
    marginBottom: '1.5rem',
    '& p': {
      fontSize: '1.37rem',
      fontFamily: 'Satoshi-Bold',
      color: '#4B4E56',
    },
  },
  head: {
    color: '#242529',
    fontSize: '1.5rem',
    fontFamily: 'Satoshi-Bold',
  },
  tableHead: {
    color: '#242529',
    fontFamily: 'Satoshi-Bold',
  },
  tableContent: {
    color: '#242529',
    fontSize: '0.93rem',
    fontFamily: 'Satoshi-Medium',
  }
}));

export default function CauseListReport() {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    date: null,
    benchNature: '',
    type: '',
    courtNumber: '',
  });

  const {date, benchNature, courtNumber} = formData;
  const [causeListData, setCauseListData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date': value,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if(date === null || benchNature === '' || courtNumber === ''){
      alert('Please provide Date, Bench Nature and Court Number.');
      return;
    }
    setCauseListData(null);
    const formatedDate = format(date, 'dd-MM-yyyy')
    getCauseListData(benchNature,formatedDate,courtNumber).then((res)=>{
      console.log(res.data);
      setCauseListData(res?.data);
    }).catch((error)=>{
      console.log('There is some glitch in getting Cause list data: ', error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    })
  }

  const handlePrintReport = () => {
    if(date === null || benchNature === '' || courtNumber === ''){
      alert('Please provide Date, Bench Nature and Court Number.');
      return;
    }
    const formatedDate = format(date, 'dd-MM-yyyy');
    printCauseList(benchNature,formatedDate,courtNumber).then((res)=>{
      // console.log(res);
      fileDownload(
        res.data,
        `CauseListReport_${formatedDate}.pdf`
      );
    }).catch((error)=>{
      console.log(error);
    })
  }

  return (
    <Box className={classes.root}>
      <Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
        CAUSE LIST REPORT
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', width:'75%', margin: 'auto'}}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Date'}</Typography>
              <DatePickerTDSAT
                date={formData.date}
                setDate={handleDateInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Bench Nature'}</Typography>
              <Select
                name="benchNature"
                value={formData.benchNature}
                onChange={handleInputChange}
                required
              >
                <MenuItem value='single_bench'>Single Bench</MenuItem>
                <MenuItem value='division_bench'>Division Bench</MenuItem>
                <MenuItem value='full_bench'>Full Bench</MenuItem>
                <MenuItem value= 'registrar'>Registrar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>Type</Typography>
              <Select
                name="type"
                value={formData.benchNature}
                onChange={handleInputChange}
                disabled
              >
                <MenuItem value=''>Select Type</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CourtNumberInput
              courtNumber={formData.courtNumber}
              onChange={handleInputChange}
              listingDate={formData.date}
              isRequired={true}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={(date === null || benchNature === '' || courtNumber === '')}
          // onClick={()=>console.log(formData)}
        >
          Submit
        </Button>
        <Box style={{height:'2rem'}}></Box>
      </form>
      {causeListData && (
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography style={{textAlign:'left', fontSize:'1.25rem', fontFamily:'Satoshi-Bold'}}>
                TELECOM DISPUTES SETTLEMENT & APPELLATE TRIBUNAL
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonPrint}
                size="small"
                endIcon={<PrintOutlined/>}
                onClick={handlePrintReport}
              >
                Print Report
              </Button>
            </Grid>
          </Grid>
          <Box style={{height:'1rem'}}></Box>
          <MessageBox
            title={`LIST OF BUSINESS AT ${causeListData?.bench?.time} THROUGH PHYSICAL MODE ON ${causeListData?.formatted_date}.`}
            subTitle={'[NOTE: IN CASE OF ASSISTANCE, PLEASE CONTACT, MR. D.B.CHETRI,PPS (MOBILE NO.9560411677)]'}
          />
          <Box style={{height:'1rem'}}></Box>
          <Box style={{backgroundColor:'white'}}>
            <Box className={classes.header}>
              <Typography>
                COURT NO : {causeListData?.bench?.court_number}
              </Typography>
              <Typography>
                BEFORE
              </Typography>
              {causeListData?.judge_names.map((judge,index)=>{
                return (
                  <Typography key={index} style={{color:`${theme?.palette.primary.main}`}}>
                    HON'BLE {judge}, MEMBER
                  </Typography>
                )
              })}
            </Box>
            {Object.keys(causeListData.hearing_data).map((head,index)=>{
              const hearings = causeListData.hearing_data[head];
              return (
                <Box key={index} style={{padding:'0 4px'}}>
                  <Box style={{padding: '1.5rem 0'}}>
                    <Typography className={classes.head}>
                      {head}
                    </Typography>
                  </Box>
                  <Grid container spacing={1} style={{padding: '1rem 0', border:'1px solid #E5E5E5'}}>
                    <Grid item xs={1} className={classes.tableHead}>
                      Sr. No.
                    </Grid>
                    <Grid item xs={2} className={classes.tableHead}>
                      Case
                    </Grid>
                    <Grid item xs={3} className={classes.tableHead} style={{textAlign:'left'}}>
                      Partie's Name
                    </Grid>
                    <Grid item xs={3} className={classes.tableHead} style={{textAlign:'left'}}>
                      Petitioner Advocate name + Respondent Advocate name
                    </Grid>
                    <Grid item xs={3} className={classes.tableHead} style={{textAlign:'left'}}>
                      Advocate Email ID + Phone number
                    </Grid>
                  </Grid>
                  {hearings.map((hearing,index)=>{
                    const claimant = hearing?.claimant;
                    const respondent = hearing?.respondent;
                    return(
                      <Grid container spacing={1} style={{padding: '1rem 0', border:'1px solid #E5E5E5'}} >
                        <Grid item xs={1} className={classes.tableContent} >
                          {index + 1}
                        </Grid>
                        <Grid item xs={2} className={classes.tableContent}>
                          {hearing?.case_number}
                        </Grid>
                        <Grid item xs={9} style={{textAlign:'left'}}>
                          <Grid container spacing={1}>
                            <Grid item xs={4} className={classes.tableContent}>
                              {claimant.case_name}
                            </Grid>
                            <Grid item xs={4} className={classes.tableContent}>
                              {claimant.name}
                            </Grid>
                            <Grid item xs={4} className={classes.tableContent}>
                              {claimant.email}<br/>{claimant.phone_number}
                            </Grid>
                          </Grid>
                          <Typography className={classes.tableContent}>VS</Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={4} className={classes.tableContent} >
                              {respondent.case_name}
                            </Grid>
                            <Grid item xs={4} className={classes.tableContent}>
                              {respondent.name}
                            </Grid>
                            <Grid item xs={4} className={classes.tableContent}>
                              {respondent.email}<br/>{respondent.phone_number}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Box>
              )
            })}

          </Box>
        </Box>
      )}
    </Box>
  )
}