import React from "react";
import TdsatSuccessIcon from '../../images/tdsatSuccess.svg';
import { Typography, Grid, Box } from "@material-ui/core";
import { formatDateWithTime } from "utils";

const CaseRegisterSuccessScreen = ({activeCase}) => {
   
    return(
        <Box sx={{ flexGrow: 1 }} style={{backgroundColor:'white',padding:'3rem',width:'100%'}}>
           <img src={TdsatSuccessIcon}/>
           <Typography variant="h1" style={{marginTop: '40px', marginBottom: '10px', fontSize:'2rem'}}>
            Case Registered Successfully.
          </Typography>
           <Grid justify="center" container style={{marginTop: '40px', marginBottom: '10px'}}>
                <Typography variant="h1">Case Number :&nbsp;</Typography>
                <Typography variant="h1" color="primary">{activeCase?.case_number}</Typography>
           </Grid>
           <Grid justify="center" container>
                <Typography variant="h1">Date & Time :&nbsp;</Typography>
                <Typography variant="h1" color="primary">{formatDateWithTime(activeCase?.registration_date_time || null)}</Typography>
           </Grid>
           <Typography style={{marginTop:'30px', fontSize: '16px', color: '#4B4E56'}}>Please note this case number for future reference.</Typography>
        </Box>
    )
}

export default CaseRegisterSuccessScreen