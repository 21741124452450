import React from 'react';
import {
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
  } from '@material-ui/core';  
import { getPaymentHelpDocument } from '../API/TdsatApi';
import fileDownload from 'js-file-download';

const PayBharatKosh = ({classes, formData, handleInputChange, amount, errors, setERecieptData, eReceiptData, applicationResponse}) => {
    const handlePayment = () => {
        window.open('https://bharatkosh.gov.in/', '_blank');
    };    

    const handleCreateReciept = (e) => {
        setERecieptData({
            ...eReceiptData,
            receipt_number: e.target.value ,
            object_id: applicationResponse?.id
        });
    };

    const downloadHelpDocument = () => {
        getPaymentHelpDocument().then((res)=>{
          fileDownload(
            res.data,
            `Help Document.pdf`
          );
        }).catch((error)=>{
          console.log(error);
        })
      }

    console.log(errors,'error')
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid className={classes.amount} item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
                    Court fees to be paid : {amount}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start',flexDirection: 'column', marginTop: 18 }}>
                    <Typography className={classes.labelTitle}>Pay Here<span style={{ color: 'red' }}>*</span></Typography>
                    <div style={{display:'flex',gap:'1rem',alignItems:'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonPay}
                            onClick={handlePayment}
                            startIcon={'₹'}
                        >
                            Pay Here
                        </Button>
                        <Button
                            size='small'
                            onClick={downloadHelpDocument}
                            style={{backgroundColor:'#F4F8FF',height:'fit-content'}}
                        >
                            Help
                        </Button>
                    </div>
                    <Typography style={{color: '#858D98'}} className={classes.labelSubtitle}>You will be redirected to the third-party payment application.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={'Enter e-Court Fee Receipt No. '}
                        name="receipt_number"
                        fullWidth
                        required
                        margin="none"
                        variant="outlined"
                        value={eReceiptData?.receipt_number}
                        onChange={handleCreateReciept}
                        placeholder='Type your BharatKosh PFMS receipt number'
                        error={!!errors.receipt_number}
                        helperText={errors.receipt_number}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default PayBharatKosh;