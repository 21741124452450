import React, {useState} from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button
} from '@material-ui/core';
import FormField from './FormField';
import { CONFIG_FIELD_BUTTON } from './formConfigs';
import {
  memoOfPartiesConfig,
  memoOfPartiesConfigRight
} from './formConfigs';
import { DeleteOutline } from '@material-ui/icons';

const MemoOfParties = ({handleFieldChangeClaimant, formValuesClaimant, createPartyIndividual, handleFieldChangeRespondent, formValuesRespondent, createRespondentIndividual, mainCaseUpdate, errorsClaimant, errorsRespondent, handleDeleteClaimant, handleDeleteRespondent}) => {

  return (
    <Box component="form" sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <div style={{display: 'flex'}}>
        <div>
        {memoOfPartiesConfig.sections.map((section, sectionIndex) => (
          <Paper key={sectionIndex} sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '400px', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
            <Grid container spacing={2}>
              {section.fields.map((field, index) => (
                <Grid item xs={12} key={index} style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <FormField 
                    field={field} 
                    value={formValuesClaimant[field.keyId] || ''} 
                    onChange={handleFieldChangeClaimant}  
                    onClick={field.type === CONFIG_FIELD_BUTTON ? createPartyIndividual : undefined}
                    error={errorsClaimant[field.keyId]}
                    disabled={field.keyId === 'type' &&  formValuesClaimant['type'] !== 'Select'}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
        <Paper sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '400px', height: '200px', overflow: 'scroll', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
          <Grid container spacing={2}>
            {mainCaseUpdate?.claimant_parties?.map((party, index) => {
              return(
                <Grid item xs={12} key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography style={{fontSize: '18px'}}>{party.type}&nbsp;{index+1}:</Typography>
                  <Grid item style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: '18px'}}>{party.full_name}</Typography>
                    <Button style={{marginLeft: '10px', fontSize: '18px', cursor: 'pointer'}} onClick={() =>handleDeleteClaimant(party.id)}><DeleteOutline/></Button>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
        </div>
        <div>
        {memoOfPartiesConfigRight.sections.map((section, sectionIndex) => (
          <Paper key={sectionIndex} sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '400px', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
            <Grid container spacing={2}>
              {section.fields.map((field, index) => (
                <Grid item xs={12} key={index} style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <FormField 
                    field={field} 
                    value={formValuesRespondent[field.keyId] || ''} 
                    onChange={handleFieldChangeRespondent}  
                    onClick={field.type === CONFIG_FIELD_BUTTON ? createRespondentIndividual : undefined}
                    error={errorsRespondent[field.keyId]}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
         <Paper sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '400px',height: '200px', overflow: 'scroll', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
          <Grid container spacing={2}>
            {mainCaseUpdate?.respondent_parties?.map((party, index) => {
              return(
                <Grid item xs={12} key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography style={{fontSize: '18px'}}>{party?.type}&nbsp;{index+1}:</Typography>
                  <Grid item style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: '18px'}}>{party?.full_name}</Typography>
                    <Button style={{marginLeft: '10px', fontSize: '18px', cursor: 'pointer'}} onClick={() =>handleDeleteRespondent(party.id)}><DeleteOutline/></Button>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
        </div>
      </div>
    </Box>
  );
};

export default MemoOfParties;
