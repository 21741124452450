import React, {useEffect, useState} from 'react';
import { Typography, Divider, Box, makeStyles, Grid, Button } from '@material-ui/core';
import SearchCaseForm from '../shared/SearchCaseForm';
import SuccessResult from '../shared/SuccessResult';
import { getSearchFormDetailsMainCase, generateTemplate, getSearchFormDetailsInCaseListing, fetchBenchNumber, getAdvocates } from 'features/Tdsat/API/TdsatApi';
import SearchByDateForm from './SearchByDateForm';
import BottomCaseListing from './BottomCaseListing';
import AdvocateSelection from './AdvocateSelection';
import ReferenceCase from './ReferenceCase';
import { format } from 'date-fns';
import { useSnackbar } from 'contexts/SnackbarContext';
import Swal from 'sweetalert2';
import instance from 'api/globalaxios';

export async function createCaseListing(data){
  return await instance({
    method: "post",
    url: `/tdsat/case_listing/`,
    data: data
  })
}

const useStyles = makeStyles((theme)=>({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
    borderRadius: '10px',
    marginTop:  theme.spacing(4),
  },
  titleHeader: {
    fontSize: '22px',
    color: '#4A3BFF',
    fontWeight: 700
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  rowItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  advocateInfo: {
    fontWeight: 500,
    marginBottom: '1.5rem',
  },
  advocateInfoTitle: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Satoshi'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '12px',
    padding: theme.spacing(1),
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  uploadButton: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersUploaded: {
    border: 'none',
    fontSize: '15px',
    // background: '#E8FFE2'
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const CaseListingForm = ({onFileNewNotice}) => {
  const classes= useStyles();
  const [formData, setFormData] = useState({
    selectType: 'caseNumber',
    date: null,
  });
  const { showSnackbar } = useSnackbar();
  
  const [searchResponse, setSearchResponse] = useState();
  const [benchIdResponse, setBenchIdResponse] = useState();
  const [caseListingResponse, setCaseListingResponse] = useState();
  const [formErrorsCase, setFormErrorsCase] = useState({});
  const [advocates, setAdvocates]= useState([]);

  useEffect(() => {
    const fetchAdvocates = async () => {
      try {
        const response = await getAdvocates();
        setAdvocates(response?.data);  
      } catch (error) {
        console.error("Error fetching connectedCases", error);
      }
    };
    fetchAdvocates();
  },[])

  const handleSearch = async (searchParams) => {
    try {
      const caseNumber = searchParams?.case_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      const response = await getSearchFormDetailsInCaseListing(caseNumber, year, caseType);
      setSearchResponse(response?.data)
      if(response?.data){
        showSnackbar('Searched Successfully', 'success');
      }
    } catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
      console.error("Error searching case:", error);
    }
  };

  const handleSearchByListingDate = async (searchParams) => {
    try {
      const judgeName = searchParams.judgeName;
      const courtNumber = searchParams.courtNumber;
      const benchNature = searchParams.benchNature;
      const listingDate = searchParams.date;
      const caseType = searchParams.case_type;
      const response = await fetchBenchNumber(format(listingDate, 'yyyy-MM-dd'), benchNature, courtNumber, judgeName);
      setBenchIdResponse(response?.data?.results);
      if(response?.data){
        showSnackbar('Bench added', 'success');
      }
    } catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.message, 'error');
      }
      console.error("Error searching case:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.headOfListing) tempErrors.headOfListing = 'This field is required';
    if (!formData.timeSlot) tempErrors.timeSlot = 'This field is required';
    if (!formData.prioritySerial) tempErrors.prioritySerial = 'This field is required';
    return tempErrors;
  };

  const handleCaseListingForm = async (formDetails) => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await createCaseListing({
          "bench_id": benchIdResponse?.[0]?.id,
          "bench_nature": formDetails?.benchNature,
          "case_number": formDetails?.case_number,
          "case_year": formDetails?.year,
          "petioner_advocate": formDetails?.petitionerAdvocate,
          "respondant_advocate": formDetails?.respondentAdvocate,
          "head_of_causelist": formData?.headOfListing,
          "priority_serial": formDetails?.prioritySerial,
          "remarks": formDetails?.remarks,
          "time_slot": formDetails?.timeSlot,
          "listing_date": format(formDetails?.date, 'yyyy-MM-dd'),
          "reference_cases": formData?.reference_cases
      })
        setCaseListingResponse(response?.data);
        setSearchResponse();
        Swal.fire({
          title: "Case Listing",
          text: "Case Listing Done Successfully",
          icon: "success"
        });
        setFormData({
          selectType: 'caseNumber',
        });
      }catch(error){
        console.log(error,'error', error.response)
        if(error?.response?.status === 400){
          showSnackbar(error?.response?.data?.error, 'error');
        }
      }
    }
    else {
      setFormErrorsCase(formErrors);
    }
  }

  return (
    <Box>
       <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
          <Grid item>
            <Typography className={classes.titleTop}>CASE LISTING</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onFileNewNotice}
              style={{ marginTop: '20px', borderRadius: '8px' }}
              endIcon={'+'}
            >
              Set Case No. Priority
            </Button>
          </Grid>
        </Grid>
        <SearchByDateForm classes={classes} formData={formData} handleInputChange={handleInputChange} handleSearch={handleSearchByListingDate} setFormData={setFormData}/>
        {/* Search Case Section */}
        <SearchCaseForm classes={classes} formData={formData} handleInputChange={handleInputChange} handleSearch={handleSearch} />

        {caseListingResponse && <SuccessResult message={caseListingResponse?.message}/>}

        {searchResponse && (
          <>
            <SuccessResult message={searchResponse?.message}/>

            <AdvocateSelection classes={classes} formData={formData} handleInputChange={handleInputChange} searchResponse={searchResponse}  advocates={advocates} />

            <SuccessResult message={searchResponse?.pending_message}/>

            <ReferenceCase classes={classes} formData={formData} handleInputChange={handleInputChange} searchResponse={searchResponse} setFormData={setFormData}/>

            <BottomCaseListing classes={classes} formData={formData} handleInputChange={handleInputChange} formErrorsCase={formErrorsCase}/>

            <div className={classes.submitButton}>
              <Button variant="contained" color="primary" size="large" onClick={()=>handleCaseListingForm(formData)}>
                Submit
              </Button>
            </div>
          </>
        )}
    </Box>
  );
};

export default CaseListingForm;
