import React, { useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getBenchByListingDate } from '../API/TdsatApi';
import { format } from 'date-fns';
import { isValid } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  
}));

const CourtNumberInput = ({courtNumber, onChange, listingDate, isRequired=false, size='medium'}) => {
  const classes = useStyles();

  const [courtNumberOptions, setCourtNumberOptions] = useState([]);
  const [noCourtNumberFound, setNoCourtNumberFound] = useState(false);

  useEffect(()=>{
    if(listingDate && isValid(new Date(listingDate))){
      getCourtNumberList(listingDate);
    }
  },[listingDate])

  const getCourtNumberList = (listingDate) => {
    setNoCourtNumberFound(false);
    const formatedDate = format(listingDate, 'dd-MM-yyyy')
    getBenchByListingDate(formatedDate).then((res)=>{
      // console.log('Court',res?.data);
      setCourtNumberOptions(res?.data);
      if(res?.data?.length === 0){
        setNoCourtNumberFound(true);
      }
    }).catch((error)=>{
      console.log('There is a glitch in getting court number: ',error);
    })
  }
  
  return (
    <>
      <FormControl required={isRequired} size={size} fullWidth margin="none" variant="outlined">
        <Typography className={classes.label}>Court Number</Typography>
        <Select
          name="courtNumber"
          value={courtNumber}
          onChange={onChange}
          disabled={!listingDate}
        >
          <MenuItem>Select</MenuItem>
          {courtNumberOptions.map((item)=>{
            return (
              <MenuItem key={item?.id} value={item?.court_number}>{item?.court_number}</MenuItem>
            )
          })}
        </Select>
        {noCourtNumberFound && <Typography align='left' color='error'>{'No bench is created on this listing date. Please select another date.'}</Typography>}
      </FormControl>
    </>
  );
};

export default CourtNumberInput;
