import React from 'react';
import { Box, TextField, Button, Typography, Link, OutlinedInput, InputAdornment } from '@material-ui/core';
import tdsatIcon from "images/tdsat_icon.svg";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import instance from 'api/globalaxios';
import Lock from 'images/Lock.svg';

export function updateNewPasswordTdsat(email, sessionId, password) {
    return async (dispatch) => {
      try {
        const response = await instance.post(
            "/api/password_reset_by_otp/confirm_password/",
            {email, session_id: sessionId, password},
          );
        console.log(response,'response')
        return response.data;
      } catch (error) {
        console.log(error);
        if (error.request.status === 400) {
          throw error.request.response;
        }
      }
    };
}

const SetNewPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const SetNewPassword = ({ setCurrentStep }) => {

    const history = useHistory();
    const [error, setError] = React.useState();
    const formik = useFormik({
      initialValues: {
        email: localStorage.getItem('email') || '',
        sessionId: sessionStorage.getItem('sessionTdsatId') || '',
        password: "",
        confirmPassword: "",
      },
      validationSchema: SetNewPasswordValidationSchema,
      onSubmit: async (values) => {
        let result = await dispatch(updateNewPasswordTdsat(values.email, values.sessionId, values.password));
        if (result.status === "success") {
            setCurrentStep("login");
        } else {
            setError(result.reason);
        }   
      },
    });
  
    const dispatch = useDispatch();
  return (
    <>
        <Box mb={2}>
        <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} />
        </Box>
        <Typography variant="h5" component="h1">
        Set New Password
        </Typography>
        <Box mt={2} width="100%" component="form" onSubmit={formik.handleSubmit}>
        <Typography id="password">New Password</Typography>
        <OutlinedInput
            margin="normal"
            fullWidth
            value={formik.password}
            required
            id="password"
            name="password"
            autoComplete="password"
            placeholder="Type your password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
        />
        {formik.touched.password && formik.errors.password && (
              <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
                {formik.errors.password}
              </Box>
            )}
        <Box mt={4} />
        <Typography id="confirmPassword">Confirm Password</Typography>
        <OutlinedInput
            margin="normal"
            fullWidth
            value={formik.confirmPassword}
            required
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="confirmPassword"
            placeholder="Type your password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        />
         {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
                {formik.errors.confirmPassword}
              </Box>
            )}
        <Box mt={4} />
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
        >
            Confirm
        </Button>
        <Box mt={2} textAlign="center">
            <Link href="#" variant="body2" onClick={() => setCurrentStep('login')}>
            {"Back to Login"}
            </Link>
        </Box>
        </Box>
    </>
  );
};

export default SetNewPassword;
