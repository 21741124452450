import { Box, Button, FormControl, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { updateFRD } from "../API/TdsatApi";
import { format } from "date-fns";
import { useSnackbar } from "contexts/SnackbarContext";
import DatePickerTDSAT from "../components/DatePickerTDSAT";

const useStyles = makeStyles((theme)=>({
  entryForm: {
    width: '75%',
    margin: 'auto',
  },
  paper: {
    padding: `3rem 6rem`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    borderRadius: '0.625rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
}))

export default function FDRUpdateForm({updatingFdr}){
	const classes = useStyles();
	const { showSnackbar } = useSnackbar();

	const [formData, setFormData] = useState({
    demand_draft_number: '',
    date_of_demand_draft: '',
    bank_name: '',
  })

	const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

	const handleInputDateOfDD = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date_of_demand_draft': value,
    }));
  }

	const handleSubmit = (e) => {
    e.preventDefault();
    const {demand_draft_number,date_of_demand_draft,bank_name} = formData
    if(demand_draft_number === ''){
      alert('Please Demand Draft Number.');
      return;
    }
    const data = {
      "dd_number": demand_draft_number,
      "dd_date": format(date_of_demand_draft, 'yyyy-MM-dd'),
      "bank_name": bank_name,
    }
    updateFRD(updatingFdr.id,data).then((res)=>{
      console.log(res?.data);
      if(res?.data?.id){
        showSnackbar("FDR updated successfully.", "success");
        setFormData({
          demand_draft_number: '',
          date_of_demand_draft: '',
          bank_name: '',
        })
      }
    }).catch((error)=>{
      console.log('There is some glitch in getting Cause list data: ', error);
    })
  }
  
	return (
		<Box>
			<Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
        FDR UPDATE FORM
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', width:'75%', margin: 'auto'}}>
					<Grid item xs={12}>
						<Typography className={classes.formHeading}>
							{updatingFdr?.case_number}
						</Typography>
					</Grid>
          <Grid item xs={12}>
						<Typography className={classes.label}>Demand Draft Number*</Typography>
						<TextField
							fullWidth
							variant="outlined"
							name="demand_draft_number"
							value={formData.demand_draft_number}
							placeholder='Enter DD No.'
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="none" variant="outlined">
							<Typography className={classes.label}>Date of Demand Draft</Typography>
							<DatePickerTDSAT
								date={formData.date_of_demand_draft}
								setDate={handleInputDateOfDD}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.label}>Bank Name</Typography>
						<TextField
							fullWidth
							variant="outlined"
							name="bank_name"
							value={formData.bank_name}
							placeholder='Type'
							onChange={handleInputChange}
						/>
					</Grid>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </Button>
      </form>
		</Box>
	)
}