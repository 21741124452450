import React from 'react';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';

const PetitionerRespondentUI = ({ classes, data, formData, handleFormChange, setFormData, errors}) => {
    const appellants = data.filter(item => item.type === "Appellant(s)" || item.type === "Petitioner(s)");
    const respondents = data.filter(item => item.type === "Respondent(s)" || item.type === "licensor(s)");
  
    const handleCheckboxChange = (id, type) => (event) => {
      const { checked } = event.target;
      setFormData((prevFormData) => {
        const updatedParties = checked
          ? [...prevFormData[type], id]
          : prevFormData[type].filter((partyId) => partyId !== id);
  
        return {
          ...prevFormData,
          [type]: updatedParties
        };
      });
    };
  
    return (
      <Grid container spacing={2} className={classes.containerUser}>
        <Grid item xs={5} className={classes.userInfo}>
          <Typography className={classes.titleUserInfo}>Petitioner(s)/Appellant(s):</Typography>
          {appellants.map((item) => (
            <FormControlLabel
              key={item.id}
              control={<Checkbox />}
              label={item.full_name}
              onChange={handleCheckboxChange(item.id, 'claimant_parties')}
            />
          ))}
          {errors.claimant_parties && <FormHelperText error>{errors.claimant_parties}</FormHelperText>}
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">v.</Typography>
        </Grid>
        <Grid item xs={5} className={classes.userInfo}>
          <Typography className={classes.titleUserInfo}>Respondent(s):</Typography>
          {respondents.map((item) => (
            <FormControlLabel
              key={item.id}
              control={<Checkbox />}
              label={item.full_name}
              onChange={handleCheckboxChange(item.id, 'respondent_parties')}
            />
          ))}
          {errors.respondent_parties && <FormHelperText error>{errors.respondent_parties}</FormHelperText>}
        </Grid>
      </Grid>
    );
  };

export default PetitionerRespondentUI;