import React, {useState} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';
import DatePickerTDSAT from "features/Tdsat/components/DatePickerTDSAT";
import TimeInput from "../shared/TimeInput";
import { getMainCaseByCaseNumber, retrieveApplicationEaMa } from "features/Tdsat/API/TdsatApi";
import { useSnackbar } from "contexts/SnackbarContext";
import HeadOfCauseListInput from "features/Tdsat/components/HeadOfCauseListInput";

const YEARS = ['Select', '2021', '2022', '2023', '2024'];
const CASE_TYPE = ["ADR", "AERA", "BA", "BP", "CA", "EA", "TA", "TP"]
export const CASE_TYPES_WITH_LABEL = [
    { label: "Aadhar Appeal", value: "ADR" },
    { label: "AERA Appeal", value: "AERA" },
    { label: "Broadcasting Appeal", value: "BA" },
    { label: "Cyber Appeal", value: "CA" },
    { label: "Telecom Appeal", value: "TA" },
    { label: "Execution Application", value: "EA" },
    { label: "Broadcasting Petition", value: "BP" },
    { label: "Telecom Petition", value: "TP" },
    { label: "Miscellaneous Application", value: "MA" }
];

const NoticeCaseForm = ({classes, formData, setFormData, handleInputChange, handleSearch,  searchMainCaseResponse, setSearchMainCaseResponse, errors, setErrors, noticeType}) => {
    const { showSnackbar } = useSnackbar();

    const handleGoClick = async () => {
        let hasError = false;
        let newErrors = { case_number: '', year: '', case_type: '' };

        if (!formData.case_number) {
            newErrors.case_number = 'Case Number is required';
            hasError = true;
        }

        if (!formData.year || formData.year === 'Select') {
            newErrors.year = 'Year is required';
            hasError = true;
        }

        if (!formData.case_type) {
            newErrors.case_type = 'Case Type is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (!hasError) {        
            try{
                const { case_number, year, case_type } = formData;
                let response;
                if (noticeType == 'ea/ma/other') {
                  response = await retrieveApplicationEaMa(case_number, year, case_type);
                } else {
                  response = await getMainCaseByCaseNumber(case_number, year, case_type);
                }
                console.log(response,'response');
                setSearchMainCaseResponse(response?.data)
                if(response?.data){
                    showSnackbar('Searched Successfully', 'success');
                }
            }catch(error){
                console.log(error,'error')
                if(error?.response?.status === 400){
                    showSnackbar(error?.response?.data?.error, 'error');
                }
            }
        }
    };

    const handleDateInput = (value) => {
        setFormData((prevData) => ({
          ...prevData,
          'date': value,
        }));
    }
    
    return(
        <>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label={'Case Number'}
                                name="case_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={formData?.case_number}
                                onChange={handleInputChange}
                                error={!!errors.case_number}
                                helperText={errors.case_number}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="none" variant="outlined">
                            <InputLabel>{'Year'}</InputLabel>
                            <Select
                                label={'Year'}
                                name="year"
                                value={formData.year}
                                onChange={handleInputChange}
                            >
                                {YEARS.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                                ))}
                            </Select>
                            {errors.year && <FormHelperText error>{errors.year}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} className={classes.titleForHeadings}>Case Type:</Grid>
                        <Grid item xs={10}>
                            <FormControl fullWidth margin="none" variant="outlined">
                                <InputLabel>{'Case Type'}</InputLabel>
                                <Select
                                    label={'Case Type'}
                                    name="case_type"
                                    value={formData.case_type}
                                    onChange={handleInputChange}
                                >
                                    {CASE_TYPES_WITH_LABEL.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </Select>
                                {errors.case_type && <FormHelperText error>{errors.case_type}</FormHelperText>}
                                </FormControl>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    onClick={()=>handleGoClick(formData)}
                >
                    Search
                </Button>
            </Grid>
            <Typography className={classes.titleHeader}>{searchMainCaseResponse?.name || " "}</Typography>
        </Paper>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                            <DatePickerTDSAT
                                date={formData.date}
                                setDate={handleDateInput}
                                label={'Listing Date'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                            <TimeInput formData={formData} handleInputChange={handleInputChange}/>
                            {errors.fromTime && <FormHelperText error>{errors.fromTime}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.advocateInfo}>
                            <p className={classes.advocateInfoTitle}>Petitioner's Advocate: {searchMainCaseResponse?.claimant_parties?.[0]?.full_name}</p>
                            <p className={classes.advocateInfoTitle}>Respondent's Advocate: {searchMainCaseResponse?.respondent_parties?.[0]?.full_name}</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <HeadOfCauseListInput
                            onChange={handleInputChange}
                            headOfListing={formData.headOfListing}
                            isLabel={false}
                        />
                        {/* {errors.headOfListing && <FormHelperText error>{errors.headOfListing}</FormHelperText>} */}
                        {/* <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                        <InputLabel>Head Of CauseList</InputLabel>
                        <Select
                            name="head_of_causelist"
                            value={formData.head_of_causelist}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="To Be Mentioned">To Be Mentioned</MenuItem>
                        </Select>
                        {errors.head_of_causelist && <FormHelperText error>{errors.head_of_causelist}</FormHelperText>}
                        </FormControl> */}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                        <InputLabel>Bench Nature</InputLabel>
                        <Select
                            name="benchNature"
                            value={formData.benchNature}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="single_bench">Single Bench</MenuItem>
                            <MenuItem value="division_bench">Division Bench</MenuItem>
                            <MenuItem value="full_bench">Full Bench</MenuItem>
                            <MenuItem value="registrar">Registrar</MenuItem>
                        </Select>
                        {errors.benchNature && <FormHelperText error>{errors.benchNature}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                        <InputLabel>Court Number</InputLabel>
                        <Select
                            name="courtNumber"
                            value={formData.courtNumber}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                        </Select>
                        {errors.courtNumber && <FormHelperText error>{errors.courtNumber}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Paper>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                        <Grid item xs={6} className={classes.titleForHeadings}>
                            Send Order for Applicant(s):
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                            <InputLabel>Applicants</InputLabel>
                            <Select
                                name="send_applicant"
                                value={formData.send_applicant}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                            </Select>
                            {errors.send_applicant && <FormHelperText error>{errors.send_applicant}</FormHelperText>}
                        </FormControl> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                    <Grid item xs={6} className={classes.titleForHeadings}>
                        Send Order for Respondent(s):
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                            <InputLabel>Main Respondents</InputLabel>
                            <Select
                                name="send_respondents"
                                value={formData.send_respondents}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                            </Select>
                            {errors.send_respondents && <FormHelperText error>{errors.send_respondents}</FormHelperText>}
                        </FormControl> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                    <Grid item xs={6} className={classes.titleForHeadings}>
                        Send Order for Applicant's Advocate:
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                            <InputLabel>Main Advocate</InputLabel>
                            <Select
                                name="send_applicant_advocate"
                                value={formData.send_applicant_advocate}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="true">YES</MenuItem>
                                <MenuItem value="false">NO</MenuItem>
                            </Select>
                            {errors.send_applicant_advocate && <FormHelperText error>{errors.send_applicant_advocate}</FormHelperText>}
                        </FormControl> 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        </>
    )
}

export default NoticeCaseForm;