import instance from "api/globalaxios";

export async function getTdsatCounts(dashboard) {
	return await instance({
		method: "get",
		url: `tdsat/user-dashboard-count/?dashboard_type=${dashboard}`,
	});
}

export async function getTdsatCases(pageSize,page,dashboard,caseType,state) {
	return await instance({
		method: "get",
		url: `tdsat/user-dashboard/?page_size=${pageSize}&page=${page}&dashboard_type=${dashboard}&type=${caseType}` + (state ? `&state=${state}` : ''),
	});
} 

export async function getTdsatCasesBySearch(dashboard, caseType, page, pageSize, searchText, cancelToken, start, end, state) {
    return instance.get(
		`/tdsat/dashboard-search/?dashboard_type=${dashboard}&type=${caseType}&page_size=${pageSize}&page=${page}&search_text=${searchText}` + (state ? `&defective_state=${state}` : '') + ((start && end) ? `&start_date=${start}&end_date=${end}` : ''),
		{ cancelToken: cancelToken.token }
	);
}

export async function printCauseList(benchNature,date,courtNumber) {
	return instance({
		method: 'get',
		url: `tdsat/cause_list/generate/?bench_nature=${benchNature}&listing_date=${date}&court_number=${courtNumber}`,
		responseType: 'blob'
	})
}

export async function getHeadWisePriority(benchNature,date,courtNumber) {
	return instance({
		method: 'get',
		url: `tdsat/cause_list/heads/?bench_nature=${benchNature}&listing_date=${date}&court_number=${courtNumber}`,
	})
}

export async function deleteHeadWisePriority(benchNature,date,data) {
	return instance({
		method: 'delete',
		url: `tdsat/cause_list/heads/?bench_nature=${benchNature}&listing_date=${date}`,
		data,
	})
}

export async function createHeadWisePriority(benchNature,date,courtNumber,data) {
	return instance({
		method: 'post',
		url: `tdsat/cause_list/heads/?bench_nature=${benchNature}&listing_date=${date}&court_number=${courtNumber}`,
		data,
	})
}

export async function getBenchByListingDate(date) {
	return instance({
		method: 'GET',
		url: `tdsat/get_bench_by_listing_date/?&listing_date=${date}`,
	})
}
export async function getCaseListingByListingDate(date, benchNature){
	return instance({
		method: 'GET',
		url: `tdsat/case_listing/?search_for=bench&listing_date=${date}&bench_nature=${benchNature}`,
	})
}

export async function getCauseListData(benchNature,date,courtNumber) {
	return instance({
		method: 'get',
		url: `tdsat/cause_list/get_cause_list_data/?bench_nature=${benchNature}&listing_date=${date}&court_number=${courtNumber}`,
	})
}

export async function getSearchFormDetailsMainCase(caseNumber,year, caseType, type) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?case_number=${caseNumber}&year=${year}&case_type=${caseType}&search_case_for=${type}`,
	})
}

export async function getSearchFormDetailsInCaseListing(caseNumber,year, caseType) {
	return instance({
		method: 'get',
		url: `tdsat/case_listing/?search_type=case_number&case_number=${caseNumber}&case_year=${year}&case_type=${caseType}`,
	})
}

export async function fetchBenchNumber(listingDate,benchNature, courtNumber, judgeName) {
	return instance({
		method: 'get',
		url: `tdsat/bench/?listing_date=${listingDate}&bench_nature=${benchNature}&court_number=${courtNumber}&judge_name=${judgeName}`,
	})
}

export async function getSearchFormDetailsMainCaseByDiaryNumber(caseNumber,year, caseType, type) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?diary_number=${caseNumber}&year=${year}&case_type=${caseType}&search_case_for=${type}`,
	})
}

export async function createFDR(data){
	return instance({
		method: 'post',
		url: 'tdsat/fdr/',
		data
	})
}

export async function getAllFDR(startDate,endDate){
	return instance({
		method: 'get',
		url: 'tdsat/fdr/?' + ((startDate && endDate) ? `start_date=${startDate}&end_date=${endDate}` : ''),
	})
}

export async function updateFRD(id,data){
	return instance({
		method: 'patch',
		url: `tdsat/fdr/${id}/`,
		data
	})
}

export async function generateTemplate(id,orderDate,benchNature, courtNumber, orderType) {
	return instance({
		method: 'get',
		url: `tdsat/generate-order-template/${id}/?order_date=${orderDate}&bench_nature=${benchNature}&court_number=${courtNumber}&order_type=${orderType}`,
		responseType: "blob",
	})
}

export async function createOrderUpload(data) {
	return instance({
		method: 'post',
		url: `tdsat/order/`,
		data: data
	})
}

export async function getPendingCaseAllocation(listingDate,benchNature, courtNumber, headOfCauseList) {
	return instance({
		method: 'get',
		url: `tdsat/pending_case_allocation/?listing_date=${listingDate}&bench_nature=${benchNature}&court_number=${courtNumber}&head_of_causelist=${headOfCauseList}`,
	})
}

export async function caseWeeding(data){
	return instance({
		method: 'post',
		url: 'tdsat/case-weeding/',
		data
	})
}

export async function getWeededOutList(startDate, endDate) {
	return instance({
		method: 'GET',
		url: `tdsat/case-weeding/?` + ((startDate && endDate) ? `start_date=${startDate}&end_date=${endDate}` : ''),
	})
}

export async function printWeedingOutList(startDate, endDate) {
	return instance({
		method: 'get',
		url: `tdsat/case_weeding_report/generate/?` + ((startDate && endDate) ? `start_date=${startDate}&end_date=${endDate}` : ''),
		responseType: 'blob'
	})
}

export async function caseRevoke(data){
	return instance({
		method: 'post',
		url: 'tdsat/case_revoke/',
		data
	})
}

export async function getMainCaseByCaseNumber(caseNumber,year, caseType) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?case_number=${caseNumber}&year=${year}&case_type=${caseType}`,
	})
}

export async function getHeadNameChoices(){
	return await instance({
	  method: "get",
	  url: `tdsat/head-name-choices/`
	});
}

export async function getHeadNameUserCreated(){
	return await instance({
	  method: "get",
	  url: `tdsat/master/head/`
	});
}

export async function getSubjectMatterChoices(){
	return await instance({
	  method: "get",
	  url: `tdsat/subjects/`
	});
}

export async function getCasesToBeTransfer(benchNature,date,courtNumber) {
	return instance({
		method: 'get',
		url: `tdsat/casetransfer/?bench_nature=${benchNature}&listing_date=${date}&court_number=${courtNumber}`,
	})
}

export async function caseTransfer(data) {
	return instance({
		method: 'post',
		url: `tdsat/casetransfer/`,
		data
	})
}

export async function updateCourt(data) {
	return instance({
		method: 'post',
		url: `tdsat/update_court/`,
		data
	})
}

export async function printFDRList(startDate, endDate) {
	return instance({
		method: 'get',
		url: `tdsat/fdr/report/generate/?` + ((startDate && endDate) ? `start_date=${startDate}&end_date=${endDate}` : ''),
		responseType: 'blob'
	})
}

export async function getPaymentHelpDocument() {
	return instance({
		method: 'get',
		url: `tdsat/payment/help/`,
		responseType: 'blob'
	})
}

export async function connectTwoCases(data) {
	return instance({
		method: 'post',
		url: `tdsat/connected_cases/`,
		data
	})
}

export async function getConnectedCase(mainCaseId) {
	return instance({
		method: 'get',
		url: `tdsat/connected_cases/?main_case_1=${mainCaseId}`,
	})
}

export async function getDetailsOfPresentApplicant(applicantId) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?applicant_id=${applicantId}&search_case_for=additional_details`,
	})
}

export async function getDetailsOfPresentRespondent(applicantId) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?respondent_id=${applicantId}&search_case_for=additional_details`,
	})
}


export async function updateDetailsApplicant(applicantId, data) {
	return instance({
		method: 'put',
		url: `tdsat/additional_details/${applicantId}/`,
		data
	})
}

export async function createNewApplicant(data) {
	return instance({
		method: 'post',
		url: `tdsat/additional_details/`,
		data
	})
}

export async function getAdvocates() {
	return instance({
		method: 'get',
		url: `tdsat/advocates/`,
	})
} 

///tdsat/advocates/
export async function createNewAdvocate(data) {
	return instance({
		method: 'post',
		url: `tdsat/advocates/`,
		data
	})
} 

export async function getAllPresentAdvocateInMainCase(mainCaseId, type) {
	return instance({
		method: 'get',
		url: `tdsat/advocates/?main_case_id=${mainCaseId}&type=${type}`,
	})
}

export async function deletePresentAdvocate(advocateId,mainCaseId) {
	return instance({
		method: 'delete',
		url: `tdsat/advocates/${advocateId}/${mainCaseId}`,
	})
}

export async function deleteConnectedCase(connectedCaseId) {
	return instance({
		method: 'delete',
		url: `tdsat/connected_cases/connected_cases/${connectedCaseId}`,
	})
}


export async function getSearchFormDetailsMainCaseByDiaryNumberConnectedCase(caseNumber,year, caseType, type) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?diary_number=${caseNumber}&year=${year}`,
	})
}

export async function getSearchFormDetailsMainCaseConnected(caseNumber,year, caseType) {
	return instance({
		method: 'get',
		url: `tdsat/main-cases/?case_number=${caseNumber}&year=${year}&case_type=${caseType}`,
	})
}



export async function getDisposalList(case_type, startDate, endDate) {
	return instance({
		method: 'get',
		url: `tdsat/report/disposal/?case_type=${case_type}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
	})
}

export async function printDisposalReport(case_type,startDate, endDate) {
	return instance({
		method: 'get',
		url: `tdsat/report/disposal/generate/?case_type=${case_type}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
		responseType: 'blob'
	})
}

export async function getReport(case_type, startDate, endDate, report_type) {
	return instance({
		method: 'get',
		url: `tdsat/report/${report_type}/?case_type=${case_type}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
	})
}

export async function printReport(case_type,startDate, endDate, report_type) {
	return instance({
		method: 'get',
		url: `tdsat/report/${report_type}/generate/?case_type=${case_type}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
		responseType: 'blob'
	})
}

export async function getPaymentReport(search_text, startDate, endDate) {
	return instance({
		method: 'get',
		url: `/tdsat/reports/payment/?case_number=${search_text}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
	})
}

export async function printPaymentReport(search_text,startDate, endDate) {
	return instance({
		method: 'get',
		url: `/tdsat/reports/payment/generate/?case_number=${search_text}` + ((startDate && endDate) ? `&start_date=${startDate}&end_date=${endDate}` : ''),
		responseType: 'blob'
	})
}

export async function viewNotice(noticeId) {
	return instance({
		method: 'get',
		url: `tdsat/notice_report/individual/generate/?notice_id=${noticeId}`,
	})
}

export async function retrieveApplicationEaMa(caseNumber,year, caseType) {
	return instance({
		method: 'get',
		url: `tdsat/applications/retrieve/?case_number=${caseNumber}&year=${year}&case_type=${caseType}`,
	})
}

export async function viewScrutinyReport(caseNumber) {
	return instance({
		method: 'get',
		url: `tdsat/report/case-status/generate/?case_number=${caseNumber}&view_type=view`,
	})
}