import { Box, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DatePickerTDSAT from "./DatePickerTDSAT";
import { format } from "date-fns";
import { createHeadWisePriority, deleteHeadWisePriority, getHeadNameChoices, getHeadWisePriority, printCauseList } from "../API/TdsatApi";
import { Add, Delete, DeleteOutline } from "@material-ui/icons";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import CourtNumberInput from "./CourtNumberInput";
import { useSnackbar } from "contexts/SnackbarContext";
import BackBtnBreadcrumb from "../CIS/shared/BackBtnBreadcrumb";
import useHeadNameChoices from "../../../hooks/useHeadNameChoices";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  button: {
    padding: '1.0625rem 3rem',
    borderRadius: '0.75rem',
    fontSize: '1.375rem',
    margin: '2rem',
  },
  tableHeadItem:{
		color: 'grey',
		fontSize: '15px',
		fontFamily: 'Satoshi-Bold',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '14px',
	},
	valuePaper: {
		padding: "1.5rem 1rem",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		// "&:hover": {
		// 	background: "#E5F6EF",
		// 	'& $downloadButton': {
		// 		visibility: 'visible',
		// 	}
		// },
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}));

export default function BenchWiseHeadPriority({onBack}) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    date: null,
    benchNature: '',
    courtNumber: '',
  });
  const [headPriorityList, setHeadPriorityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showHeadPriorityList, setShowHeadPriorityList] = useState(false);

  const {headNameChoices, error} = useHeadNameChoices();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date': value,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShowHeadPriorityList(true);
    setHeadPriorityList([]);
    const formatedDate = format(formData.date, 'dd-MM-yyyy');
    getHeadWisePriority(formData.benchNature, formatedDate, formData.courtNumber).then((res)=>{
      console.log(res.data);
      setHeadPriorityList(res?.data);
      setLoading(false);
    }).catch((error)=>{
      console.log(error);
      setLoading(false);
      if(error?.response?.status === 400){
        setShowHeadPriorityList(false);
        showSnackbar(error?.response?.data?.error, 'error');
      }
    })
  }

  // const headList = [
  //   'For Pronouncement of Judgment',
  //   'To be Mentioned',
  //   'For Preliminary Hearing',
  //   'For Directions',
  //   'For Orders',
  //   'For Issues',
  //   'For Evidence',
  //   'For Hearing',
  //   'EX-PARTE Hearing',
  //   'For Pronouncement of Order',
  //   'FOR PRONOUNCEMENT',
  //   'ORDER RESERVED',
  //   'TRANSFER TO MEDIATION',
  //   'SINE DIE',
  //   'TO BE MENTIONED (FOR WITHDRAWAL)',
  //   'FOR LISTING AS PER LISTING NO DATE',
  //   'FOR DIRECTIONS (LOCKDOWN MATTERS)',
  // ]

  const handleChangeHead = (priority) => (event) => {
    setHeadPriorityList(headPriorityList.map(item => item.priority === priority ? { ...item, name: event.target.value } : item));
  };

  const handleClickAdd = () => {
    const element = {
      priority: headPriorityList?.length + 1,
      name: 'Select',
    }
    console.log(element);
    setHeadPriorityList([...headPriorityList,element]);
  }

  const handleDeleteHeadPriority = (priority) => {
    const elementToBeDelete = headPriorityList.find(item => item.priority === priority);
    const indexOfElementToBeDelete = headPriorityList.indexOf(elementToBeDelete);
    const isLastElement = indexOfElementToBeDelete === headPriorityList?.length - 1;
    if(!elementToBeDelete){
      alert('Invalid delete!');
      return;
    }
    if(elementToBeDelete?.id){
      const formatedDate = format(formData.date, 'dd-MM-yyyy');
      const data = [elementToBeDelete];
      deleteHeadWisePriority(formData.benchNature, formatedDate, data).then((res)=>{
        // console.log(res.data);
        if(res?.data?.success){
          // setHeadPriorityList(headPriorityList.filter(item => item?.priority !== elementToBeDelete?.priority));
          updatePriority(elementToBeDelete,isLastElement);
          showSnackbar(res?.data?.message, 'success');
        }
      }).catch((error)=>{
        console.log(error)
      })
    }else{
      // setHeadPriorityList(headPriorityList.filter(item => item?.priority !== elementToBeDelete?.priority));
      updatePriority(elementToBeDelete,isLastElement);
    }
  }

  const handleSaveHeadPriority = () => {
    const formatedDate = format(formData.date, 'dd-MM-yyyy');
    createHeadWisePriority(formData?.benchNature, formatedDate, formData?.courtNumber, headPriorityList).then((res)=>{
      console.log(res?.data);
      if(res?.data?.success){
        showSnackbar('Changes saved successfully', 'success');
      }
    })
  }

  const updatePriority = async (elementToBeDelete,isLastElement) => {
    // console.log('check',isLastElement)
    // if(!isLastElement){
    //   await setHeadPriorityList(
    //     headPriorityList
    //     .filter(item => item?.priority !== elementToBeDelete?.priority)
    //     .map((item,index) => {
    //       item['priority'] = index + 1;
    //       return item;
    //     })
    //   );
    // }
    await setHeadPriorityList(
      headPriorityList
      .filter(item => item?.priority !== elementToBeDelete?.priority)
      .map((item,index) => {
        item['priority'] = index + 1;
        return item;
      })
    );
  }

  const availableHeads = (include) => {
    return headNameChoices?.filter(head => (head?.id === include) || (headPriorityList.find(item => head?.id === item?.name) === undefined));
  }

  return (
    <Box className={classes.root}>
      <BackBtnBreadcrumb
        onBackClick={() => onBack()}
        breadcrumbs={[
          { label: 'View Bench', onClick: () => onBack() },
        ]}
        currentPath='Purpose Priority'
      />
      <Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
        BENCH WISE HEAD PRIORITY
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', width:'75%', margin: 'auto'}}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Date'}</Typography>
              <DatePickerTDSAT
                date={formData.date}
                setDate={handleDateInput}
                isRequired={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Bench Nature'}</Typography>
              <Select
                name="benchNature"
                value={formData.benchNature}
                onChange={handleInputChange}
              >
                <MenuItem value='single_bench'>Single Bench</MenuItem>
                <MenuItem value='division_bench'>Division Bench</MenuItem>
                <MenuItem value='full_bench'>Full Bench</MenuItem>
                <MenuItem value= 'registrar'>Registrar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CourtNumberInput
              courtNumber={formData.courtNumber}
              onChange={handleInputChange}
              listingDate={formData.date}
              isRequired={true}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          // onClick={()=>console.log(formData)}
        >
          Submit
        </Button>
      </form>
      {showHeadPriorityList && ( 
        <Grid container style={{ width:'75%', margin:'auto', padding:'3rem', backgroundColor:'white' }}>
          <Box style={{padding:'1.5rem 1rem', width:'100%'}}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={2}>
                <Typography className={classes.tableHeadItem}>
                  Priority
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.tableHeadItem}>
                  Head
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableHeadItem}>
                  Delete
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {loading ? (
            <Box style={{margin:'auto', width:'100%'}}>
              <CircularCenteredLoader/>
            </Box>
          ) : (
            (headPriorityList.length !== 0) ? (
              headPriorityList.map((item,index) => {
                return (
                  <Box className={classes.valuePaper} key={index}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={2}>
                        <Typography className={classes.tableRowValue}>
                          {item?.priority}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl required fullWidth margin="none" variant="outlined">
                          <Select
                            name="head"
                            value={item?.name}
                            onChange={handleChangeHead(item?.priority)}
                            placeholder="Select"
                          >
                            <MenuItem >Select</MenuItem>
                            {availableHeads(item?.name)?.map((head,index)=>{
                              return(
                                <MenuItem value={head?.id} key={index}>{head?.name}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <Button style={{backgroundColor:'#EEF2F5', color:'#4B4E56'}} onClick={() => handleDeleteHeadPriority(item?.priority)}>
                          <DeleteOutline/>
                        </Button>   
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <Grid container justifyContent="center">
                <Grid item>
                  <Box textAlign="center">
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: "initial",
                      }}
                    >
                      No Bench Wise Head Priority created previously
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )
          )}
          <Box style={{margin:'2rem auto', width:'fit-content', display:'flex', gap:'1rem'}}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<Add/>}
              onClick={handleClickAdd}
            >
              Add
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveHeadPriority}
            >
              Save
            </Button>
          </Box>
        </Grid>
      )}
    </Box>
  )
}