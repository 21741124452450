import { format, isValid } from "date-fns";

export function isValidDate(dateObject) {
  return new Date(dateObject).toString() !== "Invalid Date" && dateObject === null;
}

export default (rawDate, changeInto) => {
	
  return ((rawDate !== null) && isValid(new Date(rawDate))) ? format(new Date(rawDate), changeInto) : '';
};
