import React, { useEffect, useState } from "react";
import { DyteMeeting, DyteSpinner } from "@dytesdk/react-ui-kit";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import DyteVideoBackgroundTransformer from "@dytesdk/video-background-transformer";
import { isDifc } from "Routes/Routes";

const DyteMeetingVC = ({
  dyteToken,
  vcContainerStyle,
  onEnd
}) => {
  const [meeting, initClient] = useDyteClient();

  const addVirtualBackground = false;

  useEffect(() => {
    if (dyteToken) {
      //initalising the dyte client (it is running again)
      initClient({
        authToken: dyteToken,
        // set default values for user media
        defaults: {
          audio: false,
          video: false,
        },
      }).then((meeting) => {
        console.log(meeting, 'meeting>>>>>>')
      }).catch((err) => {
        console.log(err,'err>>>>>>>')
      })
    }
    return () => {
      // window.location.reload(false)
    };
  }, [dyteToken]);

  useEffect(() => {
    if (meeting) {
      meeting.meta.on("disconnected", () => {
        onEnd()
      });
      meeting.self.on("roomLeft", () => {
      onEnd()
      });
    }
  }, [meeting]);
  useEffect(() => {
    if (meeting && addVirtualBackground) {
      (async () => {
        if (DyteVideoBackgroundTransformer.isSupported()) {
          const videoBackgroundTransformer =
            await DyteVideoBackgroundTransformer.init();
          meeting.self.addVideoMiddleware(
            await videoBackgroundTransformer.createStaticBackgroundVideoMiddleware(
              `https://pncraz.s3.ap-south-1.amazonaws.com/moot-dyte-bg-nlud.jpeg`
            )
          );
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }
  }, [meeting]);

  return (
    <div id="vc-container" style={vcContainerStyle}>
        <DyteProvider value={meeting}>
            <DyteMeeting
                meeting={meeting}
                mode="fill"
                showSetupScreen={false}
            />
        </DyteProvider>
    </div>
  );
};
export default DyteMeetingVC;
