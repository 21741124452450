// App.js (Main Component)
import React, { useState } from 'react';
import CaseListingForm from './CaseListingForm';
import PendingCaseAllocation from './PendingCaseAllocation';

function CaseListing() {
  const [isFilingNotice, setIsFilingNotice] = useState(false);

  const handleFileNewNotice = () => {
    setIsFilingNotice(true); 
  };

  const handleBackToTable = () => {
    setIsFilingNotice(false); 
  };

  const handleFormSubmit = () => {
    setIsFilingNotice(false);
  };

  return (
    <>
      {!isFilingNotice ? (
        <CaseListingForm onFileNewNotice={handleFileNewNotice} />
      ) : (
        <PendingCaseAllocation onBack={handleBackToTable} onSubmit={handleFormSubmit} />
      )}
    </>
  );
}

export default CaseListing;
