import React from 'react';
import { Box, Typography, Breadcrumbs, Link } from '@material-ui/core';
import { KeyboardBackspace, NavigateNext } from '@material-ui/icons';

const BackBtnBreadcrumb = ({ 
  onBackClick, 
  breadcrumbs = [], 
  currentPath = '' 
}) => {
  return (
    <Box style={{ display: 'flex', paddingBottom: '1.5rem', gap: '10px' }}>
      <Typography style={{ cursor: 'pointer' }} onClick={onBackClick}>
        <KeyboardBackspace />
      </Typography>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <Link
            key={index}
            color="inherit"
            style={{ cursor: 'pointer' }}
            onClick={() => breadcrumb.onClick && breadcrumb.onClick()}
          >
            {breadcrumb.label}
          </Link>
        ))}
        <Typography color="textPrimary">{currentPath}</Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default BackBtnBreadcrumb;
