import React, { createContext, useState, useContext } from "react";

const ResumeFillingContext = createContext();

export const ResumeFillingProvider = ({ children }) => {
  const [resumeFilling, setResumeFilling] = useState(false);

  const setResume = (status) => {
    setResumeFilling(status);
  };

  return (
    <ResumeFillingContext.Provider
      value={{ resumeFilling, setResume }}
    >
      {children}
    </ResumeFillingContext.Provider>
  );
};

export const useResumeFilling = () => {
  return useContext(ResumeFillingContext);
};
