import React, {useState, useEffect} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';
import HeadOfCauseListInput from "features/Tdsat/components/HeadOfCauseListInput";


const BottomCaseListing = ({classes, formData, handleInputChange, formErrorsCase}) => {
    
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <HeadOfCauseListInput
                            onChange={handleInputChange}
                            headOfListing={formData?.headOfListing}
                        />
                        {formErrorsCase.headOfListing && <FormHelperText error>{formErrorsCase.headOfListing}</FormHelperText>}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.label}>Priority Serial</Typography>
                        <TextField
                            name="prioritySerial"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.prioritySerial}
                            onChange={handleInputChange}
                            error={!!formErrorsCase.prioritySerial}
                            helperText={formErrorsCase.prioritySerial}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Remarks</Typography>
                        <TextField
                            name="remarks"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.remarks}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Typography className={classes.label}>Time Slot</Typography>
                        <TextField
                            name="timeSlot"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.timeSlot}
                            onChange={handleInputChange}
                            error={!!formErrorsCase.timeSlot}
                            helperText={formErrorsCase.timeSlot}
                        />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Paper>
    )
}

export default BottomCaseListing;