import React,{useState} from 'react';
import { Box, TextField, Grid, Button, Typography, Link, OutlinedInput, InputAdornment } from '@material-ui/core';
import { useFormik } from "formik";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Lock from 'images/Lock.svg';
import FormattedMessage from "features/common/TranslatedMessage";
import * as Yup from "yup";
import {setLogOut,
  setTokenAsync
} from "slices/loginSlice";
import { Alert } from "@material-ui/lab";
import tdsatIcon from "images/TDSAT_Logo_Golden.svg";

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required"),
});

const LoginTdsat = ({setCurrentStep}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { state: routeState } = useLocation();
  const [currentScreen, setCurrentScreen] = useState(1);
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(setLogOut());
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then(() => {
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          history.push("/");
        }
      });
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  React.useEffect(() => {
    dispatch(setLogOut());
  }, []);


  return (
    <>
      <Box mb={2}>
        <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} />
      </Box>
      <Typography variant="h5" component="h1">
        Log In
      </Typography>
      <Box mt={2} width="100%">
        <Typography id="email">Email address</Typography>
        <OutlinedInput
          margin="normal"
          fullWidth
          value={formik.email}
          required
          id="email"
          name="email"
          autoComplete="email"
          placeholder="Type your email address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startAdornment={<InputAdornment position="start">@</InputAdornment>}
        />
        {formik.errors.email && formik.touched.email && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.email}
          </Box>
        )}
        {hasErrors && apiErrors.email && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {apiErrors.email[0]}
          </Box>
        )}
        <Box mt={1}/>
        <Typography id="password" ><FormattedMessage id="auth.password" /></Typography>
        <OutlinedInput
          fullWidth
          margin="normal"
          required
          name="password"
          type={passwordType}
          id="password"
          value={formik.password}
          autoComplete="current-password"
          placeholder="At least 6 characters"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startAdornment={<InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={tooglePasswordType}
              style={{ cursor: "pointer", color: '#5a5a5a' }}
            >
              {passwordType === "password" ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
            </InputAdornment>
          }
        />
        {formik.errors.password && formik.touched.password && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {formik.errors.password}
          </Box>
        )}
        {hasErrors && apiErrors.password && (
          <Box display="flex" alignItems="center" color='#DC362E' mt={0.5} fontSize={'14px'}>
            {apiErrors.password[0]}
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" alignItems="center" color='#DC362E' mt={1.5} mb={4} fontSize={'14px'} fontWeight={700}>
          <Typography 
            onClick={() => setCurrentStep('forgotPassword')} 
            style={{ cursor: 'pointer' }}
          >
            Forgot password?
          </Typography>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={formik.handleSubmit}
        >
          LOG IN
        </Button>
        {hasErrors && apiErrors.non_field_errors && (
          <Alert severity="error">{apiErrors.non_field_errors[0]}</Alert>
        )}
        <div style={{height:'2rem'}} />
        <Box mt={2} textAlign="center">
          <Link variant="body2" style={{cursor: 'pointer'}} onClick={() => setCurrentStep('signUp')}>
            {"New user? Sign up now."}
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default LoginTdsat;
