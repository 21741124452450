import { Box, Button, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import theme from "theme";
import SearchCaseForm from "../components/SearchCaseForm";
import { getSearchFormDetailsMainCase } from "../API/TdsatApi";
import DatePickerTDSAT from "../components/DatePickerTDSAT";
import { createFDR } from "../API/TdsatApi";
import { format } from "date-fns";
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme)=>({
  entryForm: {
    width: '75%',
    margin: 'auto',
  },
  paper: {
    padding: `3rem 6rem`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    borderRadius: '0.625rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
}))

export default function FDR({handleClickFRDModify}){
  const classes = useStyles();

  const [searchFormData, setSearchFormData] = useState({
    selectType: 'caseNumber',
    case_number: '',
    case_type: '',
    year: '',
  });
  const [formData, setFormData] = useState({
    date_of_order: null,
    deposit_by: '',
    amount_to_be_deposite: '',
    initial_period: '',
    time_allowed: null,
    demand_draft_number: '',
    date_of_demand_draft: null,
    bank_name: '',
    installment: '',
    remarks: '',
  })
  const [searchResponse, setSearchResponse] = useState(null);

  const { showSnackbar } = useSnackbar();

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputDateOfOrder = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date_of_order': value,
    }));
  }

  const handleInputDateOfDD = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date_of_demand_draft': value,
    }));
  }

  const handleInputTimeAllowed = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'time_allowed': value,
    }));
  }


  const handleSearch = async (searchParams) => {
    try {
      const caseNumber = searchParams?.case_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      const response = await getSearchFormDetailsMainCase(caseNumber, year, caseType, "fdr");
      console.log(response?.data);
      setSearchResponse(response?.data?.main_case);
    } catch (error) {
      console.error("Error searching case:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    const {date_of_order,deposit_by,amount_to_be_deposite,initial_period,time_allowed,demand_draft_number,date_of_demand_draft,bank_name,installment,remarks} = formData
    if(deposit_by === '' || amount_to_be_deposite === '' || initial_period === '' || time_allowed === null || demand_draft_number === ''){
      alert('Please fill all required feilds.');
      return;
    }
    const data = {
      "main_case": searchResponse?.id,
      "order_date": format(date_of_order, 'yyyy-MM-dd'),
      "deposit_by": deposit_by,
      "amount": amount_to_be_deposite,
      "initial_period": initial_period,
      "allowed_time_duration": format(time_allowed, 'yyyy-MM-dd'),
      "dd_number": demand_draft_number,
      "dd_date": format(date_of_demand_draft, 'yyyy-MM-dd'),
      "bank_name": bank_name,
      "installment": installment,
      "remarks": remarks,
    }
    createFDR(data).then((res)=>{
      console.log(res?.data);
      if(res?.data?.id){
        showSnackbar("FDR created successfully.", "success");
        setFormData({
          date_of_order: null,
          deposit_by: '',
          amount_to_be_deposite: '',
          initial_period: '',
          time_allowed: null,
          demand_draft_number: '',
          date_of_demand_draft: null,
          bank_name: '',
          installment: '',
          remarks: '',
        })
      }
      // setCauseListData(res?.data);
    }).catch((error)=>{
      console.log('There is some glitch in getting Cause list data: ', error);
    })
    // handlePrintReport(formData.benchNature, formatedDate, formData.courtNumber)
  }
  
  return (
    <Box>
      <Box className={classes.entryForm}>
        <Box style={{display:'flex', gap:'1rem', justifyContent:'space-between'}}>
          <Typography style={{fontSize:'1.5rem'}}>
            FDR ENTRY FORM
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={()=> handleClickFRDModify('fdr_report')}
          >
            FDR Modify/Update
          </Button>
        </Box>
        <Box style={{height:'1.5rem'}}/>
        <SearchCaseForm
          handleInputChange={handleInputChangeSearch}
          classes={classes}
          handleSearch={handleSearch}
          formData={searchFormData}
        />
        {searchResponse && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{backgroundColor:'white', padding:'3rem 6rem', borderRadius: '0.625rem'}}>
              <Grid item xs={12}>
                <Typography className={classes.formHeading}>
                  {searchResponse?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>Date of Order</Typography>
                  <DatePickerTDSAT
                    date={formData.date_of_order}
                    setDate={handleInputDateOfOrder}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>Deposit by*</Typography>
                  <Select
                    name="deposit_by"
                    value={formData.deposit_by}
                    onChange={handleInputChange}
                    required
                  >
                    <MenuItem>Select</MenuItem>
                    <MenuItem value='Petitioner'>Petitioner</MenuItem>
                    <MenuItem value='Respondent'>Respondent</MenuItem>
                    <MenuItem value='Appellant'>Appellant</MenuItem>
                    <MenuItem value= 'Others'>Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Amount To Be Deposit*</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="amount_to_be_deposite"
                  value={formData.amount_to_be_deposite}
                  placeholder='Enter Amount'
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.label}>Initial Period of Fixed Deposit*</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="initial_period"
                  value={formData.initial_period}
                  placeholder='Enter Period'
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.label}>Time Allowed for Deposit*</Typography>
                <DatePickerTDSAT
                  date={formData.time_allowed}
                  setDate={handleInputTimeAllowed}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Demand Draft Number*</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="demand_draft_number"
                  value={formData.demand_draft_number}
                  placeholder='Enter DD No.'
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>Date of Demand Draft</Typography>
                  <DatePickerTDSAT
                    date={formData.date_of_demand_draft}
                    setDate={handleInputDateOfDD}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Bank Name</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="bank_name"
                  value={formData.bank_name}
                  placeholder='Type'
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>Select Installment</Typography>
                  <Select
                    name="installment"
                    value={formData.installment}
                    onChange={handleInputChange}
                    required
                  >
                    <MenuItem>Select</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Remarks</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="remarks"
                  value={formData.remarks}
                  placeholder='Type'
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              // disabled={(date === null || benchNature === '' || courtNumber === '')}
              // onClick={()=>console.log(formData)}
            >
              Submit
            </Button>
            <Box style={{height:'2rem'}}></Box>
          </form>
        )}
        
      </Box>
    </Box>
  )
}