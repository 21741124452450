import React, {useState} from 'react';
import {
    Button,
    FormControl,
    Typography,
    Grid,
    Paper,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    FormHelperText,
    IconButton,
    Tooltip,
    TextField,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';
import { CloudUpload, Delete, InfoOutlined } from '@material-ui/icons';
import instance from 'api/globalaxios';
import DatePickerTDSAT from 'features/Tdsat/components/DatePickerTDSAT';
import { format } from 'date-fns';
import { useSnackbar } from 'contexts/SnackbarContext';

export const INFO_UPLOAD = `
  1. Maximum size of the e-file is fixed at 50 MB.
  2. Formatting: Paper size: A-4 (29.7 cm x 21 cm) 
      - Top Margin: 2 cm, Bottom Margin: 2 cm
      - Left Margin: 4 cm, Right Margin: 4 cm
      - Alignment: Justified
      - Font: Times New Roman, Font size: 14
      - Line spacing: 1.5
  3. The document should be converted into Optical Character Recognition (OCR) searchable Portable Document Format (PDF) or PDF/A using any PDF converter or in-built PDF conversion plug-in provided in the software. PDF/A is the preferred format.
  4. Document to be bookmarked at the relevant sections of the file.
`;

const TooltipComponent = () => {
  return (
    <Tooltip
      title={
        <span>
          {INFO_UPLOAD.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      }
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

const OrderUploadForm = ({classes, formData, handleInputChange, handleDateInputUpload, searchResponse, apiUrl}) => {
    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState({
      orderUploadDate: '',
      benchNatureUpload: ''
    });
    const { showSnackbar } = useSnackbar();

    console.log(errors,'errors', formData)
    const validateFields = () => {
      let hasError = false;
      const newErrors = { ...errors };

      // File validation
      if (!file) {
        newErrors.file = 'Please upload a file.';
        hasError = true;
      } else {
        newErrors.file = '';
      }

      // Bench Nature validation
      if (!formData?.benchNatureUpload) {
        newErrors.benchNatureUpload = 'Please select the nature of the bench.';
        hasError = true;
      } else {
        newErrors.benchNatureUpload = '';
      }

      // Order Date validation
      if (!formData?.orderUploadDate) {
        newErrors.orderUploadDate = 'Please select the order date.';
        hasError = true;
      } else {
        newErrors.orderUploadDate = '';
      }

      // Case ID validation
      if (!searchResponse?.main_case?.id) {
        newErrors.case_id = 'Please have a id';
        hasError = true;
      } else {
        newErrors.case_id = '';
      }

      setErrors(newErrors);
      return !hasError;
    };

    const handleUploadOrderForm = async (file) => {
      if (!validateFields()) {
        return;
      }
      if (!file) {
        console.error("No file selected");
        return;
      }
      const formDataNew = new FormData();
      formDataNew.append('file',  file);
      formDataNew.append('bench_nature', formData?.benchNatureUpload);
      formDataNew.append('order_date', format(formData?.orderUploadDate, 'dd-MM-yyyy'));
      formDataNew.append('remarks', formData?.remarks);
      formDataNew.append('case_id', searchResponse?.main_case?.id);
  
      setIsUploading(false);

      try {
        const response = await instance.post(apiUrl, formDataNew, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response,'response')
        setUploadResponse(response.data);
        setFile(file);
        if(response?.data){
          showSnackbar('Order Created Successfully', 'success');
        }
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        if(error?.response?.status === 400){
          showSnackbar(error?.response?.data?.error, 'error');
        }
      }finally {
        setIsUploading(false);
      }
  };


  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      // await uploadDocument(file);
    }
  };

  const handleRemoveFile = async () => {
    setFile(null);
  };
  console.log(isUploading ,file !== null , !searchResponse?.id, searchResponse)
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Typography className={classes.titleHeader}>{searchResponse?.main_case?.name || " "}</Typography>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start',flexDirection: 'column', marginTop: 18, marginBottom: 18 }}>
                    <Typography className={classes.labelTitle}>Upload File:</Typography>
                    <Grid style={{display: 'flex'}}>
                      <input
                          type="file"
                          accept=".pdf .docx"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                          id="file-upload"
                          disabled={isUploading || file !== null || !searchResponse?.main_case?.id}
                      />
                      <label htmlFor="file-upload">
                          <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className={classes.buttonPay}
                              disabled={isUploading || file !== null || !searchResponse?.main_case?.id}
                              startIcon={isUploading ? <CircularProgress size={24} /> : <CloudUpload />}
                              style={{width: '100%', padding:'15px', borderRadius: '12px'}}
                          >
                            {'Upload File'}
                          </Button>
                      </label>
                      <TooltipComponent/>
                    </Grid>
                    {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
                </Grid>
                {file && (
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 18 }}>
                    <Typography variant="body2">File Name: {file.name}</Typography>
                    <Delete style={{cursor: 'pointer', marginLeft: '10px'}} onClick={handleRemoveFile}/>
                  </Grid>
                )}

              <FormControl className={classes.formItem}  fullWidth margin="none" variant="outlined">
                <DatePickerTDSAT
                  date={formData.orderUploadDate}
                  setDate={handleDateInputUpload}
                  label={"Order Date"}
                />
                {errors.orderUploadDate && <FormHelperText error>{errors.orderUploadDate}</FormHelperText>}
              </FormControl>

              <FormControl className={classes.formItem} style={{ marginRight: '10px' }} required variant="outlined">
                <InputLabel id="bench-nature-label">Bench Nature</InputLabel>
                <Select
                  labelId="bench-nature-label"
                  value={formData?.benchNatureUpload}
                  onChange={handleInputChange}
                  name="benchNatureUpload"
                >
                  <MenuItem value="single_bench">Single Bench</MenuItem>
                  <MenuItem value="division_bench">Division Bench</MenuItem>
                  <MenuItem value="full_bench">Full Bench</MenuItem>
                  <MenuItem value="registrar">Registrar</MenuItem>
                </Select>
                {errors.benchNatureUpload && <FormHelperText error>{errors.benchNatureUpload}</FormHelperText>}
            </FormControl>

              <TextField
                label="Remarks"
                name="remarks"
                className={classes.formItem}
                value={formData?.remarks}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
              <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={()=>handleUploadOrderForm(file)}
              >
                  Upload Order
              </Button>
            </Grid>
      </Paper>
    )
}

export default OrderUploadForm;