import { Box, Button, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import Tabs from "features/Tabs/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import ConnectedCase from "./ConnectedCase";
import AdditionalPartyDetails from "./AdditionalPartyDetails";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
		padding: '5px 10px',
  },
	tabsContainer: {
		width:'100%',
		flexWrap: 'nowrap',
		[theme.breakpoints.down("sm")]: {
			flexWrap: 'wrap',
		},
	},
	selectedTab: {
		padding: "0.8rem 1.5rem",
		cursor: 'pointer',
		fontSize: '1.2rem',
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		color: theme.palette.primary.main,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
	unselectedTab: {
		fontSize: '1.2rem',
		padding: "0.8rem 1.5rem",
		cursor: "pointer",
		fontFamily: 'Satoshi-Medium',
		whiteSpace: 'pre-line',
		borderBottom: '2px solid black',
	},
}));

export default function AdditionalCase({handleClickOnNavItem}){
	const classes = useStyles();
  const [activeTab, setActiveTab] = useState('additonal_party');
	const [subScreen, setSubScreen] = useState(null);

  useEffect(()=>{

  },[])

	const RenderTabs = ({selected}) => {
		switch(selected){
			case 'additonal_party':
				return <AdditionalPartyDetails handleGoBack={handleTabChange}/>
			case 'connected_case':
				return <ConnectedCase handleGoBack={handleTabChange}/>
			default:
				return <h1>Invalid Choice</h1>
		}
	};

	const tabs = [
		{
			id: "additonal_party",
			label: "Additional Party Details",
		},
		{
			id: "connected_case",
			label: "Connected Case",
		},
	];

	const handleTabChange = (newValue) => {
		setSubScreen(null);
		setActiveTab(newValue);
	};

	const handleChangeSubScreen = (newValue) => {
		setSubScreen(newValue);
	}

    return (
			<Box className={classes.root}>
				<Tabs
					tabs={tabs}
					handleClickTab={handleTabChange}
					choice={activeTab}
					tabsClasses={{
						selected: classes.selectedTab,
						unselected: classes.unselectedTab,
					}}
					tabsContainerClass={classes.tabsContainer}
				/>
        <Box style={{height:'1.5rem'}}/>
				<RenderTabs selected={subScreen || activeTab}/>
			</Box>
    )
}
