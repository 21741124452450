import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import FormField from './FormField';
import {
  CONFIG_FIELD_ROW,
    filingDetailsConfig,
} from './formConfigs';
import { getCaseTypes } from './ApplicationFillingForm';

const useStyles = makeStyles((theme) => ({
  titleCase: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 700,
    display: 'flex'
  },
  nameTitle: {
    fontSize: '18px',
    lineHeight: '31.32px',
    fontWeight: 400,
    display: 'flex'
  },
  containerCaseInfo: {
    marginBottom: '18px'
  }
}));

const CaseInfo = ({mainCaseUpdate, updateMainCase, setSelectedAmount}) => {
  const classes = useStyles();
  const [combinedNames, setCombinedNames] = useState('');

 useEffect(() => {
  if (mainCaseUpdate) {
    const petitioners = mainCaseUpdate.claimant_parties?.map(claimant => claimant?.full_name) || [];
    const petitionerOrAppellant = mainCaseUpdate.claimant_parties?.map(claimant => claimant?.type) || [];
    const defendants = mainCaseUpdate.respondent_parties?.map(respondent => respondent?.full_name) || [];

    // Update selected amount based on petitioners count
    setSelectedAmount((petitionerOrAppellant?.[0] === 'Petitioner(s)' ? 5000 : 10000) * petitionerOrAppellant?.length);

    // Handle petitioner text formatting
    let petitionerText = '';
    if (petitioners.length === 1) {
      petitionerText = petitioners[0];
    } else if (petitioners.length === 2) {
      petitionerText = `${petitioners[0]} & Another`;
    } else if (petitioners.length > 2) {
      petitionerText = `${petitioners[0]} and others`;
    }

    // Handle defendant text formatting
    let defendantText = '';
    if (defendants.length === 1) {
      defendantText = defendants[0];
    } else if (defendants.length === 2) {
      defendantText = `${defendants[0]} & Another`;
    } else if (defendants.length > 2) {
      defendantText = `${defendants[0]} and others`;
    }

    // Format the combined string
    const combined = `${petitionerText} vs ${defendantText}`;
    setCombinedNames(combined);
  }
}, [mainCaseUpdate]);


  // Function to handle name and update main case
  const handleName = () => {
    updateMainCase('name', combinedNames);
  };

  // Run handleName whenever combinedNames is updated
  useEffect(() => {
    handleName();
  }, [combinedNames]);

  return (
    <Grid container className={classes.containerCaseInfo}>
      <Grid item xs={5}>
        <Typography className={classes.titleCase} component="div">Petitioner(s)/Appellant(s):</Typography>
        {mainCaseUpdate?.claimant_parties?.map((claimant, index) => {
          return(
            <Typography className={classes.nameTitle}>{index+1}. {claimant?.full_name}</Typography>
          )
        })}
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" component="div" align="center">vs.</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography className={classes.titleCase} component="div">Respondent(s):</Typography>
        {mainCaseUpdate?.respondent_parties?.map((respondent, index) => {
          return(
            <Typography className={classes.nameTitle}>{index+1}. {respondent?.full_name}</Typography>
          )
        })}
      </Grid>
    </Grid>
  );
};

const FilingDetails = ({mainCaseUpdate, updateMainCase, formDetails, setSelectedAmount, caseTypes, errors, subjectMatterChoices}) => {

  const handleCaseType = (event) => {
    const selectedCaseTypeId = event.target.value;
    const selectedCaseType = caseTypes.find(caseType => caseType.id === selectedCaseTypeId);
    // if (selectedCaseType) {
    //   setSelectedAmount(selectedCaseType.amount);
    // }
    updateMainCase('case_type_new', selectedCaseTypeId);
  };

  const handleSubjectMatter = (event) => {
    const selectedSubjectMatterId = event.target.value;
    updateMainCase('subject_matter', selectedSubjectMatterId);
  };

  return (
    <Box component="form" sx={{ maxWidth: 600, mx: 'auto', p: 3 }}  style={{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center'}}>
      {filingDetailsConfig.sections.map((section, sectionIndex) => (
        <Paper key={sectionIndex} sx={{ p: 2, mb: 2 }} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '500px', marginBottom: '32px', padding: '32px 85px 32px 85px'}}>
          <CaseInfo mainCaseUpdate={mainCaseUpdate} updateMainCase={updateMainCase} setSelectedAmount={setSelectedAmount}/>
          <FormControl required fullWidth margin="none" variant='outlined' error={errors['case_type_new']}>
            <InputLabel>Case Type</InputLabel>
            <Select label={'Case Type'} onChange={handleCaseType} value={formDetails['case_type_new'] || ''}>
              {caseTypes?.map((caseType, index) => (
                <MenuItem key={index} value={caseType?.id}>
                  {caseType.description}
                </MenuItem>
              ))}
            </Select>
            {errors['case_type_new'] && (
              <FormHelperText>{errors['case_type_new']}</FormHelperText>
            )}
          </FormControl>

          <FormControl required fullWidth margin="none" variant='outlined' error={errors['subject_matter']} style={{marginTop: '30px'}}>
            <InputLabel>Subject Matter</InputLabel>
            <Select label={'Subject Matter'} onChange={handleSubjectMatter} value={formDetails['subject_matter'] || ''}>
              {subjectMatterChoices?.map((subjectMatterChoice, index) => (
                <MenuItem key={index} value={subjectMatterChoice?.id}>
                  {subjectMatterChoice?.name}
                </MenuItem>
              ))}
            </Select>
            {errors['subject_matter'] && (
              <FormHelperText>{errors['subject_matter']}</FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2}>
            {section.fields.map((field, index) => (
              <Grid item xs={12} key={index} style={{display: 'flex', justifyContent: 'flex-start'}}>
                <FormField 
                  field={field} 
                  value={formDetails[field.keyId] || ''} 
                  onChange={updateMainCase} 
                  error={field.type === CONFIG_FIELD_ROW ? errors : errors[field.keyId]}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default FilingDetails;
