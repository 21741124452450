import { Box, Button, FormControl, Grid, InputLabel, Link, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { PrintOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import validateDateAndFormat from "utils/validateDateAndFormat";
import fileDownload from "js-file-download";
import { getReport, printReport, viewScrutinyReport } from "features/Tdsat/API/TdsatApi";
import DateRangePickerTDSAT from "features/Tdsat/DateRangePickerTDSAT";
import { CASE_TYPES_WITH_LABEL } from "../Notice/NoticeCaseForm";
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme)=>({
  filtersContainer: {
    padding: '1rem', 
    borderBottom: '1px solid black', 
    display: 'flex', 
    gap: '15px', 
    justifyContent:'center',
    // width: '80%',
    margin: 'auto',
  },
  buttonPrint: {
    borderRadius: '0.5rem',
    border: '1px solid #AAAFB9',
    background: '#fff',
    padding: '0.5rem 1rem',
  },
  tableHeadItem:{
		color: 'grey',
		fontFamily: 'Satoshi-Bold',
    wordBreak: 'break-word',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '0.9375rem',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
	},
	valuePaper: {
		padding: "1.5rem 8px",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		"&:hover": {
			background: "#E5F6EF",
			'& $downloadButton': {
				visibility: 'visible',
			}
		},
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}))

export default function CaseProceedingReport() {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
	const [caseType, setCaseType] = useState('All Cases');

  const spacing = [2,2,2,3,3];
  const tableHeadings = ['Sr. No.','Diary No.','Case No.','Next Listing Date','Next Causelist Head'];
	const CASE_TYPE = ["ADR", "AERA", "BA", "BP", "CA", "EA", "TA", "TP", "All Cases"];

  const handleViewScrutinyReport = async (caseName) => {
		try {
			const response = await viewScrutinyReport(caseName);
      console.log(response,'response')
			const link = response.data?.pre_signed_url;
			if (link) {
				const newTab = window.open();
				newTab.location.href = link;
			} else {
        showSnackbar(response?.data?.error, 'error');
				console.error("Link not found in response");
			}
		} catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
    }
			console.error("Failed to fetch the notice link", error);
		}
	};


  const processResponse = (data,index) => {
    const item = data;
    const arr = [
      index+1, 
      item?.diary_number,
      <Link onClick={()=>handleViewScrutinyReport(item?.case_number)}>{item?.case_number}</Link>,  
			item?.next_listing_date,
      item?.next_causelist_head,
    ];
    return arr;
  }

  const reload = () => {
		setLoading(true);
		getReport(caseType,validateDateAndFormat(startDate,'dd-MM-yyyy'),validateDateAndFormat(endDate,'dd-MM-yyyy'),'case-proceeding')
    .then((res)=>{
      const response = res?.data?.results || [];
      setCases(response);
      // const processedResponse = processResponse(response);
      // let count = res?.data?.count;
      // let t = parseInt(count / PAGE_SIZE);
      // let r = count % PAGE_SIZE;
      // let total = t + (r > 0 ? 1 : 0);
      // setTotalPages(total);
      setLoading(false);
    })
    .catch((error)=>{
      console.log('Error: ',error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    if((startDate && endDate) || (!startDate && !endDate) || caseType){
      reload();
    }
  },[startDate,endDate,caseType])

  const handlePrintReport = () => {
    printReport(caseType,validateDateAndFormat(startDate, 'dd-MM-yyyy'),validateDateAndFormat(endDate, 'dd-MM-yyyy'),'case-proceeding').then((res)=>{
      // console.log(res);
      fileDownload(
        res.data,
        `Case Proceeding Report.pdf`
      );
    }).catch((error)=>{
      console.log(error);
    })
  }

  return (
    <Box>
      <Typography style={{fontSize:'1.5rem', padding:'2rem'}}>
        CASE PROCEEDING REPORT
      </Typography>
      <Box style={{backgroundColor:'white'}}>
        <Box style={{height:'1rem'}}></Box>
        <Box className={classes.filtersContainer} style={{justifyContent:'space-between'}}>
					<FormControl fullWidth size="small" margin="none" variant="outlined" style={{maxWidth:'28%'}}>
						<InputLabel>{'Case Type'}</InputLabel>
						<Select
							label={'Case Type'}
							name="case_type"
							value={caseType}
							onChange={(e)=> setCaseType(e.target.value)}
						>
							{CASE_TYPES_WITH_LABEL.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
              <MenuItem value={'All Cases'}>
                {'All Cases'}
              </MenuItem>
						</Select>
					</FormControl>
          <DateRangePickerTDSAT startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
          <Button
            className={classes.buttonPrint}
            size="small"
            endIcon={<PrintOutlined/>}
            onClick={handlePrintReport}
          >
            Print Report
          </Button>
        </Box>
        <Box>
          <Box style={{height: '2rem'}}/>
          <Grid container style={{ rowGap: "8px" }}>
            <Box style={{padding:'1.5rem 8px', width: '100%'}}>
              <Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            {loading ? (
              <Box style={{margin:'auto',width:'100%',padding:'2rem 0'}}>
                <CircularCenteredLoader/>
              </Box>
            ) : (
              cases.length !== 0 ? (
                cases.map((dispute,index) => {
                  const processedDispute = processResponse(dispute,index);
                  return (
                    <Box key={index} className={classes.valuePaper}>
                      <Grid container spacing={2} justifyContent="flex-start">
                        {processedDispute.map((item,index)=>{
                          return (
                            <Grid key={index} item xs sm={spacing[index]}>
                              <Typography className={classes.tableRowValue}>
                                {item}
                              </Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Grid container justify="center">
                  <Grid item>
                    <Box textAlign="center">
                      <Typography
                        style={{
                          padding: '2rem 8px',
                          textAlign: 'center',
                          fontSize: '1.5rem'
                        }}
                      >
                        No case proceeding is found for this criteria.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
            {/* {cases.length !== 0 && (
              <Box style={{padding:'16px'}}>
                <Pagination color="primary" count={totalPages} page={page} onChange={handlePageChange}/>
              </Box>
            )} */}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}