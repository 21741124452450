import React from 'react';
import { DialogTitle, DialogContent, DialogActions, Dialog, Button, makeStyles, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    titleBox: {
        color: '#242529',
        borderBottom: '1px solid black',
        fontWeight: 700,
        fontSize: '22px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title:{
        color: '#242529',
        fontWeight: 700,
        fontSize: '22px',
    },
    wrapButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogPaper: {
        minWidth: '500px',
        maxWidth: '60%',
        minHeight: '200px',
    }
}));

const CustomDialogTdsat = ({ open, onClose, title, children, onSend, toShowBtn = false }) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ className: classes.dialogPaper }}>
            <DialogTitle className={classes.titleBox}>
                <Typography className={classes.title}>
                {title}
                </Typography>
                {/* <IconButton
                    edge="end"
                    color="#000"
                    onClick={onClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton> */}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {toShowBtn &&
                <DialogActions className={classes.wrapButton}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onSend} color="primary" variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default CustomDialogTdsat;
